import { Component, Input, OnInit } from '@angular/core';
import { PaymentModel } from 'src/app/models/order/payment.model';

@Component({
  selector: 'app-order-payments-tab',
  templateUrl: './order-payments-tab.component.html',
  styleUrls: ['./order-payments-tab.component.scss'],
})
export class OrderPaymentsTabComponent implements OnInit {
  @Input() inputDetails : PaymentModel[] = [];

  constructor() { }

  ngOnInit() {}

}
