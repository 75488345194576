import { Deserializable } from "../deserialize.model";

export class ScheduleSaveModel implements Deserializable  {
    VendorScheduleId: string = '';
    LocationMenuId: string = null;
    LocationMenuItemId: string = null;
    LocationId: string = '';
    DateStart: Date;
    DateEnd: Date;
    Published: boolean = false;
    Dates: Date[] = [];
    MaxBookings: number = 0;
    MaxInstructors: number = 0;

    ScheduleTime: string = '';
    ScheduleBookings: number = 0;

    deserialize(input: any) {
        var self = this;

        return self;
    }
}