import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AddressDetailsPage } from 'src/app/components/customers/address-details/address-details.page';
import { BusinessDetailsPage } from 'src/app/components/customers/business-details/business-details.page';
import { ContactDetailsPage } from 'src/app/components/customers/contact-details/contact-details.page';
import { CustomerAddressModel } from 'src/app/models/customer/customer.address.model';
import { CustomerBusinessDetailModel } from 'src/app/models/customer/customer.business.detail.model';
import { CustomerContactDetailModel } from 'src/app/models/customer/customer.contact.detail.model';
import { PaymentTypeModel } from 'src/app/models/customer/customer.payment.type.model';
import { OrderItemModel } from 'src/app/models/order/order.item.model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentService } from 'src/app/services/payment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss'],
})
export class CheckoutPage implements OnInit {
  loading: boolean = false;
  
  showContacts: boolean = false;
  showAddresses: boolean = false;
  showPaymentTypes: boolean = false;
  requiresDelivery: boolean = false;

  constructor(public orderService: OrderService, public paymentService: PaymentService, public customerService: CustomerService, public router: Router,
    public formBuilder: FormBuilder, public notificationService: NotificationService, public authService: AuthService, public modalController: ModalController) {
    var self = this;
  }

  ngOnInit() {
    var self = this;

    self.validateOrder();

    self.requiresDelivery = false;
    self.orderService.orderItems.forEach((item: OrderItemModel) => {
      if(item.RequiresDelivery == true) self.requiresDelivery = true;
    });
    
    if(self.customerService.selectedCustomer.ContactDetails == null || self.customerService.selectedCustomer.ContactDetails.length == 0) {
      self.customerService.checkoutDisplayView = 'contacts';
      self.showContactModal(null);
    }
    else if(self.customerService.selectedCustomer.ContactDetails.length == 1) {
      self.selectContact(self.customerService.selectedCustomer.ContactDetails[0]);
    }

    self.populatePaymentTypes();
  }

  populatePaymentTypes() {
    var self = this;


    if(self.paymentService.paymentTypes == null || self.paymentService.paymentTypes.length == 0) {
      self.paymentService.getPaymentTypes().subscribe((resp: any) => {
        self.paymentService.paymentTypes = [];
        if(resp != null) {
          resp.forEach((paymentType: any) => {
            self.paymentService.paymentTypes.push(new PaymentTypeModel().deserialize(paymentType));
          });

          self.selectPayFast();
        }
      });
    }
    else {
      self.selectPayFast();
    }
  }

  selectPayFast() {
    var self = this;

    for(var count = 0; count < self.paymentService.paymentTypes.length; count++) {
      if(self.paymentService.paymentTypes[count].PaymentTypeId == 1) {
        self.selectPaymentType(self.paymentService.paymentTypes[count]);
      }
    }
  }

  selectPaymentType(paymentType: PaymentTypeModel) {
    var self = this;
    self.customerService.selectedPaymentType = paymentType;
    self.showPaymentTypes = false;

    self.validateOrder();
  }

  selectContact(contact: CustomerContactDetailModel) {
    var self = this;

    self.customerService.selectedContactDetails = contact;
    self.showContacts = false;

    self.validateOrder();
  }

  selectAddress(address: CustomerAddressModel) {
    var self = this;

    self.customerService.selectedAddress = address;
    self.showAddresses = false;

    self.validateOrder();
  }

  selectBusiness(business: CustomerBusinessDetailModel) {
    var self = this;

    self.customerService.selectedBusinessDetails = business;

    self.validateOrder();
  }

  contactPopVisible: boolean = false;

  async showContactModal(contact: any) {
    var self = this;

    self.customerService.selectedContactDetails = contact;

    const modal = await self.modalController.create({
      component: ContactDetailsPage
    });
    return await modal.present();
  }

  showContactPop() {
    var self = this;

    self.contactPopVisible = false;
    self.contactPopVisible = true;
  }

  showAddContactView() {
    var self = this;

    self.customerService.checkoutDisplayView = 'contacts';

    if(self.customerService.selectedCustomer.ContactDetails.length == 0) {
      self.showContactModal(null);
    }
  }

  showAddAddressView() {
    var self = this;

    self.customerService.checkoutDisplayView = 'addresses';

    // if(self.customerService.selectedCustomer.Addresses.length == 0) {
    //   self.showAddressModal(null);
    // }
  }

  showAddBusinessView() {
    var self = this;

    self.customerService.checkoutDisplayView = 'business';

    if(self.customerService.selectedCustomer.BusinessDetails.length == 0) {
      self.showBusinessModal(null);
    }
  }

  orderValid:boolean = true;
  validateOrder() {
    var self = this;

    self.customerService.checkoutDisplayView = '';

    self.orderValid = self.customerService.selectedContactDetails != null;
    if(!self.orderValid) return;
    self.orderValid = self.customerService.selectedPaymentType != null;
    if(!self.orderValid) return;
    if(self.requiresDelivery && !self.isPickup) self.orderValid = self.customerService.selectedAddress != null;
  }

  orderName: string = '';
  orderNotes: string = '';
  isPickup: boolean = false;

  saveOrder() {
    var self = this;

    var deliveryId = null;

    self.validateOrder();

    if(!self.orderValid) {
      self.notificationService.showToastrError('Oops', 'Please select contact details for this order.');
      return;
    }

    if(!self.isPickup && self.customerService.selectedAddress != null) {
      deliveryId = self.customerService.selectedAddress.CustomerAddressId;
    }

    var model = {
      CustomerBusinessDetailId: self.customerService.selectedBusinessDetails == null ? null : self.customerService.selectedBusinessDetails.CustomerBusinessDetailId,
      CustomerContactDetailId: self.customerService.selectedContactDetails.CustomerContactDetailId,
      CustomerAddressId: deliveryId,
      Name: self.orderName,
      Notes: self.orderNotes,
      PaymentTypeId: self.customerService.selectedPaymentType.PaymentTypeId,
      Email: self.authService.profile.EmailAddress,
      LocationId: environment.vendorId
    };

    self.orderService.placeOrder(model).subscribe((resp: any) => {
      if(resp == null) {
        self.notificationService.showPostError();
      }
      else {
        self.notificationService.showToastrSuccess('Done!', 'Order placed successfully!');
        self.orderService.populateBasket();
        if(resp.length > 0) {
          self.orderService.populateOrder(resp[0]);
          self.router.navigate(['/tabs','order', self.orderService.selectedOrder.OrderId]);
        }

        self.dismissModal();
      }
    });
  }

  addressPopVisible: boolean = false;

  makePickup() {
    var self = this;

    self.customerService.selectedAddress = null;
    self.isPickup = true;
    self.validateOrder();
    self.customerService.checkoutDisplayView = '';
  }

  async showAddressModal(address: any) {
    var self = this;

    self.customerService.selectedAddress = address;

    const modal = await self.modalController.create({
      component: AddressDetailsPage
    });
    return await modal.present();
  }

  async showBusinessModal(address: any) {
    var self = this;

    self.customerService.selectedAddress = address;

    const modal = await self.modalController.create({
      component: BusinessDetailsPage
    });
    return await modal.present();
  }

  hideAddressPop() {
    var self = this;

    self.customerService.selectedContactDetails = null;

    self.addressPopVisible = true;
    self.addressPopVisible = false;
  }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
