import { Deserializable } from '../deserialize.model';
import { LocationMenuItemExtraModel } from './location.menu.item.extra.model';
import { LocationMenuItemPhotoModel } from './location.menu.item.photo.model';
import { LocationMenuItemPriceModel } from './location.menu.item.price.model';

export class LocationMenuItemModel implements Deserializable  {
    LocationMenuId: string = '';
    LocationMenuName: string = '';
    
    LocationDirectoryId: string = '';
    LocationMenuItemId: string = '';
    Name: string = '';
    Description: string = '';
    JsonData: string = '';
    MenuItemData: string = '';
    Price: number = 0;
    UnitPrice: number = 0;
    MenuItemPhotos: LocationMenuItemPhotoModel[] = [];
    MenuItemPrices: LocationMenuItemPriceModel[] = [];
    CoverPicture: string = '';
    
    Hidden : boolean = false;
    Delete : boolean = false;
    IsSpecial : boolean = false;
    Order: number = 0;
    
    Quantity: number = 0;

    LocationDirectoryTypeId: number = 0;
    Longitude: number = 0;
    Latitude: number = 0;
    Priority: number = 0;

    Discount: number = 0;
    DiscountTypeId: number = 0;
    DiscountAmount: number = 0;
    DiscountValue: number = 0;

    FinalPrice: number = 0;
    SpecialType: number = 0;
    SpecialAmount: number = 0;

    MenuItemTypeId: number = 1;
    MenuItemType: string = 'Product';
    ScheduledItem : boolean = false;

    SessionId: string = '';
    Tags: any[] = [];

    ExtraData: LocationMenuItemExtraModel;

    Increment: number = 1;
    MaxBookings: number = 1;
    MaxItems: number = 1;
    PriceMaxItems: number = 1;
    FinalMaxItems: number = 1;
    MinItems: number = 1;
  
    deserialize(input: any) {
        var self = this;

        self.LocationMenuItemId = input.locationMenuItemId;
        self.Name = input.name;
        self.Description = input.description;
        self.JsonData = input.jsonData || '';
        // self.MenuItemData = input.menuItemData || '';
        self.LocationDirectoryId = input.locationDirectoryId || '';
        self.UnitPrice = input.unitPrice;
        self.Price = input.price;
        self.Hidden = input.hidden;
        self.IsSpecial = input.isSpecial;
        self.SessionId = input.sessionId;
        self.Longitude = input.longitude;
        self.Latitude = input.latitude;
        self.CoverPicture = input.coverPicture;
        self.Priority = input.priority;
        self.SpecialAmount = input.specialAmount;
        self.SpecialType = input.specialType;
        self.MenuItemTypeId = input.menuItemTypeId;
        self.MenuItemType = input.menuItemType;
        self.ScheduledItem = input.scheduledItem;

        self.LocationMenuId = input.locationMenuId || '';
        self.LocationMenuName = input.locationMenuName || '';

        self.DiscountAmount = input.discountAmount;
        self.DiscountTypeId = input.discountTypeId;
        self.DiscountValue = input.discountValue;

        self.Increment = input.increment;
        self.MaxBookings = input.maxBookings;
        self.MaxItems = input.maxItems;
        self.PriceMaxItems = input.maxItems;
        self.FinalMaxItems = input.maxItems;
        self.MinItems = input.minItems;

        if(input.menuItemPhotos != null && input.menuItemPhotos.length > 0) {
            input.menuItemPhotos.forEach((photo:any) => {
                self.MenuItemPhotos.push(new LocationMenuItemPhotoModel().deserialize(photo));
            });

            if(self.CoverPicture == null) {
                self.CoverPicture = self.MenuItemPhotos[0].PhotoUrl;
            }
        }

        if(input.menuItemPrices != null && input.menuItemPrices.length > 0) {
            input.menuItemPrices.forEach((price:any) => {
                self.MenuItemPrices.push(new LocationMenuItemPriceModel().deserialize(price));
            });

            self.updateFinalMax(0);
        }
        
        if(self.CoverPicture != null && self.CoverPicture != '' && (self.MenuItemPhotos == null || self.MenuItemPhotos.length == 0)) {
            self.MenuItemPhotos = [];
            self.MenuItemPhotos.push(new LocationMenuItemPhotoModel().deserialize({
                photoUrl: self.CoverPicture
            }));
        }

        if(input.menuItemData != null && input.menuItemData != '') {
            self.ExtraData = new LocationMenuItemExtraModel().deserialize(JSON.parse(input.menuItemData));
        }

        return self;
    }

    updateFinalMax(priceIndex: number) {
        var self = this;

        var max = self.MenuItemPrices[priceIndex].Quantity;

        // if both are infinite
        if(self.MaxItems == -1 && max == -1) {
            self.FinalMaxItems = -1;
        }
        // if menu item is infinite and price isn't
        else if(self.MaxItems == -1 && max != -1) {
            self.FinalMaxItems = max;
        }
        // if price is infinite and menu item isn't
        else if(max == -1 && self.MaxItems != -1) {
            self.FinalMaxItems = self.MaxItems;
        }
    }

    update(input: any) {
        var self = this;
        
        self.Name = input.name;
        self.Description = input.description;
        self.Price = input.price;
        self.Hidden = input.hidden;
        
        self.JsonData = input.jsonData || '';
        self.MenuItemData = input.menuItemData || '';
    }
  }
  