import { Deserializable } from '../deserialize.model';

export class LocationMenuItemPropertyModel implements Deserializable  {
  PropertyKey: string = '';
  PropertyName: string = '';
  PropertyList: string[] = [];
  Required: boolean = false;
  ExtraInfoTypeId: number = 0;

  deserialize(input: any) {
      var self = this;

      self.PropertyKey = input.propertyName.replace(/\s/g, '');;
      self.PropertyName = input.propertyName;
      self.Required = input.required;
      self.Required = input.required;
      self.ExtraInfoTypeId = input.extraInfoTypeId;

      switch(input.extraInfoTypeId)
      {
          case 1:
              {
                self.PropertyList = input.propertyData.split(';;');
                  break;
              }
      }

    //   if(input.propertyList != null && input.propertyList.length > 0) {
    //     input.propertyList.forEach(property => {
    //         self.PropertyList.push(new DropdownModel().deserialize(property));
    //     });
    //   }
      
      return self;
  }
}