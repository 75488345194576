import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BasketExtraInfoModel } from 'src/app/models/basket/basket.extra.info.model';
import { LocationMenuItemExtraModel } from 'src/app/models/locations/location.menu.item.extra.model';
import { LocationMenuItemModel } from 'src/app/models/locations/location.menu.item.model';
import { LocationMenuItemPropertyModel } from 'src/app/models/locations/location.menu.item.property.model';
import { LocationScheduleModel } from 'src/app/models/locations/location.schedule.model';
import { OrderItemModel } from 'src/app/models/order/order.item.model';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';
import { SessionService } from 'src/app/services/session.service';
import { CheckoutPage } from '../checkout/checkout.page';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-menu-item-pop',
  templateUrl: './menu-item-pop.page.html',
  styleUrls: ['./menu-item-pop.page.scss'],
})
export class MenuItemPopPage implements OnInit {
  inputMenuItem: LocationMenuItemModel;
  inputSchedule: LocationScheduleModel;

  loading: boolean = false;
  showSelectSchedule: boolean = false;
  showSelectDate: boolean = false;
  canBook: boolean = false;
  showCheckout: boolean = false;
  
  amount: number = 1;
  total: number = 0;
  selectedSchedule: LocationScheduleModel;
  basketInfo: BasketExtraInfoModel[] = [];

  slideOpts = {
    speed: 400,
    slidesPerView: 3,
    loop: true,
    autoplay: true,
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 320px
      320: {
        slidesPerView: 1,
      },
      // when window width is <= 480px
      480: {
        slidesPerView: 1,
      },
      // when window width is <= 640px
      640: {
        slidesPerView: 2,
      },
      // when window width is <= 640px
      900: {
        slidesPerView: 3,
      }
    }
  };

  constructor(private router: Router, public orderService: OrderService, public notificationService: NotificationService, public sessionService: SessionService, 
    public locationService: LocationService, public formBuilder: FormBuilder, public authService: AuthService, public modalController: ModalController) { }

  ngOnInit() {
    var self = this;
    self.total = self.amount * self.inputMenuItem.Price;
    self.loading = true;

    self.locationService.getExtraInfo(self.inputMenuItem.LocationMenuItemId).subscribe((resp: any) => {
      if(resp.outcome == true && resp.outcomeData != null) {
        self.inputMenuItem.ExtraData = new LocationMenuItemExtraModel().deserialize(resp.outcomeData);
        self.prepareForm();

        if(self.inputSchedule != null) self.selectLocationSchedule(self.inputSchedule);

        // if(self.inputMenuItem.ScheduledItem == false || self.inputMenuItem.ExtraData.Schedule.length > 0) {
        //   self.canBook = true;
        // }
      }

      self.loading = false;
    });
  }

  purchaseForm: FormGroup;
  prepareForm() {
    var self = this;
    self.purchaseForm = self.formBuilder.group({
    });

    if(self.inputMenuItem.ExtraData.Properties != null) {
      self.inputMenuItem.ExtraData.Properties.forEach((itemProperty: LocationMenuItemPropertyModel) => {
        self.purchaseForm.addControl(itemProperty.PropertyKey, new FormControl('', itemProperty.Required ? Validators.required : null));
      });
    }
  }

  increment() {
    var self = this;

    self.amount++;
    self.total = self.amount * self.inputMenuItem.Price;
  }

  decrement() {
    var self = this;

    if(self.amount > 1) {
      self.amount--;

      self.total = self.amount * self.inputMenuItem.Price;
    }
  }

  selectLocationSchedule(model: LocationScheduleModel) {
    var self = this;

    self.selectedSchedule = model;
    self.showSelectSchedule = false;

    self.updateExtraInfo('ScheduleSelectedDate', self.selectedSchedule.DateStart.toString().substring(4, 21));

    self.verifyCanBook();
  }

  addToCart() {
    var self = this;

    if(!self.authService.isAuthenticated()) {
      self.notificationService.showAuthError();

      return;
    }

    self.verifyCanBook();

    if(!self.canBook) {
      if(!self.purchaseForm.valid) {
        self.notificationService.showFormError();
      }
      else {
        self.notificationService.showToastrError('Oops', 'Please select a date.');
      }

      return;
    }

    var formItem = null;

    // loop all properties and add to basketInfo
    self.inputMenuItem.ExtraData.Properties.forEach((item: any) => {
      formItem = self.purchaseForm.get(item.PropertyKey);
      if(formItem != null) self.updateExtraInfo(item.PropertyKey, formItem.value);
    });

    var model = {
      LocationMenuItemId: self.inputMenuItem.LocationMenuItemId,
      Name: self.inputMenuItem.Name,
      Price: self.inputMenuItem.Price,
      Quantity: self.amount,
      SessionId: self.sessionService.sessionId,
      JsonData: JSON.stringify(self.basketInfo),
      VendorScheduleId: null
    };

    if(self.selectedSchedule != null) model.VendorScheduleId = self.selectedSchedule.VendorScheduleId;

    self.loading = true;

    self.orderService.saveToBasket(model).subscribe((resp : any) => {
      if(resp != null) {
          var index = -1;
          var result = 0;
          result = resp.quantity;
          model.Quantity = result;

          for(var count = 0; count < self.orderService.orderItems.length; count++) {
              if(self.orderService.orderItems[count].BasketItemId == resp.basketItemId) {
                  self.orderService.orderItems[count].Quantity = resp.quantity;
                  self.orderService.orderItems[count].Total = self.orderService.orderItems[count].Quantity * self.orderService.orderItems[count].Price;
                  index = count;
                  count = self.orderService.orderItems.length;
              }
          }

          if(resp.quantity == 0 && index > -1) {
              self.orderService.orderItems.splice(index, 1);
              self.notificationService.showToastrSuccess("Success","Item removed from your basket.");
          }
          else if(index == -1) {
              resp.price = model.Price;

              self.orderService.orderItems.push(new OrderItemModel().deserialize(resp));
              self.notificationService.showToastrSuccess("Success","Item added to your basket.");
          }
          else {
              self.notificationService.showToastrSuccess("Success","Basket item updated.");
          }

          self.loading = false;

          self.orderService.calculateTotal();
          self.showCheckout = true;
      }
      else {
          self.notificationService.showToastrError('Oops', 'Product not added, please try again.');
      }
    });
  }

  showBasket() {
    var self = this; 

    self.dismissModal();

    setTimeout(() => {
      self.router.navigate(['/tabs','basket']);
    }, 100);
  }

  async showCheckoutModal() {
    var self = this;

    self.dismissModal();

    const modal = await self.modalController.create({
      component: CheckoutPage
    });
    return await modal.present();
  }

  formatDate(value: string) {
    return value;
  }

  selectedDate: Date;
  selectDate(value: any) {
    var self = this;
    self.selectedDate = new Date(value);
    self.updateExtraInfo('Date', self.selectedDate.toString().substring(4, 21));
    
    self.verifyCanBook();
  }

  startDate: string = '';
  showSelectStart: boolean = false;

  selectStart(value: string) {
    var self = this;
    self.startDate = value;
    self.showSelectStart = false;
  }

  endDate: string = '';
  showSelectEnd: boolean = false;
  selectEnd(value: string) {
    var self = this;
    self.endDate = value;
    self.showSelectEnd = false;
  }

  verifyCanBook() {
    var self = this;
    
    // validate if the product or service requires a form to be complete
    self.canBook = self.purchaseForm.valid;

    if(!self.canBook) {
      return;
    }

    // validation for product
    if(self.inputMenuItem.MenuItemTypeId == 1) {
    }
    // validation for service
    else if(self.inputMenuItem.MenuItemTypeId == 2) {
      // validate if a schedule has been selected for scheduled items
      if(self.inputMenuItem.ScheduledItem) {
        self.canBook = self.selectedSchedule != null;
      }
      // validate if a date has been selected for items that require a date
      else {
        self.canBook = self.selectedDate != null;
      }
    }
  }

  updateExtraInfo(key: string, value: string) {
    var self = this;

    var found = false;

    self.basketInfo.forEach((info: BasketExtraInfoModel) => {
      if(info.Key == key) {
        found = true;
        info.Value = value;
      }
    });

    if(!found) {
      self.basketInfo.push(new BasketExtraInfoModel().deserialize({
        key: key,
        value: value
      }));
    }
  }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
