import { Deserializable } from '../deserialize.model';

export class CustomerAddressModel implements Deserializable  {
    CustomerAddressId: string = '';
    Name: string = '';
    
    AddressInfo: string = '';
    AddressLines: string[] = [];

    Longitude: number = 0;
    Latitude: number = 0;

    JsonData: string = '';
    AddressTypeId: number = 9;

    Pickup: boolean = false;
    Hidden: boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.CustomerAddressId = input.customerAddressId;
        self.Name = input.name;

        self.AddressInfo = input.addressInfo;

        self.Longitude = input.longitude;
        self.Latitude = input.latitude;

        if(self.AddressInfo != null && self.AddressInfo.length > 0) {
            self.AddressLines = self.AddressInfo.split(',');
        }

        if(self.AddressLines.length == 0) self.AddressLines.push('');

        self.JsonData = input.jsonData;
        self.AddressTypeId = input.addressTypeId;
        self.Pickup = input.pickup;

        return self;
    }

    update(input: CustomerAddressModel) {
        var self = this;

        self.CustomerAddressId = input.CustomerAddressId;
        self.Name = input.Name;

        self.AddressInfo = input.AddressInfo;
        self.Longitude = input.Longitude;
        self.Latitude = input.Latitude;

        if(self.AddressInfo != null && self.AddressInfo.length > 0) {
            self.AddressLines = self.AddressInfo.split(',');
        }

        self.JsonData = input.JsonData;
        self.AddressTypeId = input.AddressTypeId;
        self.Pickup = input.Pickup;

        return self;
    }
}