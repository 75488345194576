import { Deserializable } from '../deserialize.model';

export class CustomerBusinessDetailModel implements Deserializable  {
    CustomerBusinessDetailId: string = '';
    Name: string = '';
    VatNumber: string = '';

    Hidden: boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.CustomerBusinessDetailId = input.customerBusinessDetailId;
        self.Name = input.name;
        self.VatNumber = input.vatNumber;
        
        return self;
    }
}