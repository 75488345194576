import { Deserializable } from '../deserialize.model';
import { CustomerAddressModel } from './customer.address.model';
import { CustomerBusinessDetailModel } from './customer.business.detail.model';
import { CustomerContactDetailModel } from './customer.contact.detail.model';
import { PaymentTypeModel } from './customer.payment.type.model';

export class CustomerModel implements Deserializable  {
    CustomerId: string = '';
    
    Addresses: CustomerAddressModel[] = [];
    BusinessDetails: CustomerBusinessDetailModel[] = [];
    ContactDetails: CustomerContactDetailModel[] = [];

    PaymentTypes: PaymentTypeModel[] = [];

    SelectedAddressId: string = '';
    SelectedBusinessDetailsId: string = '';
    SelectedContactDetailsId: string = '';
    SelectedPaymentTypeId: number = 0;
  
    deserialize(input: any) {
        var self = this;

        if(input.customerContactDetails != null) {
            input.customerContactDetails.forEach((item: any) => {
                self.ContactDetails.push(new CustomerContactDetailModel().deserialize(item));
            });
        }

        if(input.customerAddresses != null) {
            input.customerAddresses.forEach((item: any) => {
                self.Addresses.push(new CustomerAddressModel().deserialize(item));
            });
        }

        if(input.customerBusinessDetails != null) {
            input.customerBusinessDetails.forEach((item: any) => {
                self.BusinessDetails.push(new CustomerBusinessDetailModel().deserialize(item));
            });
        }

        if(input.paymentTypes != null) {
            input.paymentTypes.forEach((item: any) => {
                self.PaymentTypes.push(new PaymentTypeModel().deserialize(item));
            });
        }

        if(input.basketPreferences != null) {
            self.SelectedAddressId = input.basketPreferences.customerAddressId || '';
            self.SelectedBusinessDetailsId = input.basketPreferences.customerBusinessDetailId || '';
            self.SelectedContactDetailsId = input.basketPreferences.customerContactDetailId || '';
            self.SelectedPaymentTypeId = input.basketPreferences.paymentTypeId || 0;

            // if(input.basketPreferences.customerAddressId != null) {
            //      self.Addresses.find((obj: CustomerAddressModel) => {
            //         return obj.CustomerAddressId === input.basketPreferences.customerAddressId
            //       })
            // }
            // if(input.basketPreferences.customerAddressId != null) {
            //     self.SelectedAddress = self.Addresses.find((obj: CustomerAddressModel) => {
            //         return obj.CustomerAddressId === input.basketPreferences.customerAddressId
            //       })
            // }
        }

        return self;
    }
}