import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocationModel } from '../models/locations/location.model';
import { environment } from 'src/environments/environment';
import { LocationMenuModel } from '../models/locations/location.menu.model';
import { LocationAccessModel } from '../models/users/location.access.model';

const routes = {
};

@Injectable()
export class LocationService {
    loading: boolean = false;
    locationId: string = environment.vendorId;
    directory: any[] = [];
    specials: any[] = [];
    homeSpecials: any[] = [];
    locations: any[] = [];
    selectedLocation: LocationModel = null;
    selectedMenu: LocationMenuModel = new LocationMenuModel();
    merchandiseMenu: LocationMenuModel = new LocationMenuModel();
    surfLessonMenu: LocationMenuModel = new LocationMenuModel();
    socialIcons: any[] = [];
    locationAccessLevel: LocationAccessModel = null;
    isEditor: boolean = false;

    staticPages: any[] = [];

    constructor(private httpClient: HttpClient) {
        var self = this;

        self.populateSurfLessonMenu();
        self.populateMerchandiseMenu();
    }

    populateLocation(resp: any) {
        var self = this;
        self.socialIcons = [];

        if(resp != null) {
            self.selectedLocation = new LocationModel().deserialize(resp);

            if(self.selectedLocation.ContactDetails != null) {
                self.selectedLocation.ContactDetails.forEach((detail: any) => {
                    if(detail.DetailTypeId == 4 || detail.DetailTypeId == 5 || detail.DetailTypeId == 6) {
                        self.socialIcons.push(detail);
                    }
                });
            }

            if(self.selectedLocation.LocationMenu != null && self.selectedLocation.LocationMenu.length > 0) {
                self.populateSelectedMenu(self.selectedLocation.LocationMenu[0].LocationMenuId);
            }
            else {
                self.loading = false;
            }
          }
    }

    populateSelectedMenu(menuId: string) {
        var self = this;

        self.loading = true;
        self.getMenu(menuId).subscribe((resp: any) => {
            if(resp != null) {
                self.selectedMenu = new LocationMenuModel().deserialize(resp);
            }

            self.loading = false;
        })
    }

    populateMerchandiseMenu() {
        var self = this;

        self.loading = true;
        self.getMenu('f342b6ff-8023-433d-e542-08db17fabf1e').subscribe((resp: any) => {
            if(resp != null) {
                self.merchandiseMenu = new LocationMenuModel().deserialize(resp);
            }

            self.loading = false;
        })
    }

    populateSurfLessonMenu() {
        var self = this;

        self.loading = true;
        self.getMenu('a8c7e409-b177-487e-b404-08db0824dbe3').subscribe((resp: any) => {
            if(resp != null) {
                self.surfLessonMenu = new LocationMenuModel().deserialize(resp);
            }

            self.loading = false;
        })
    }

    // get a location
    getLocation(locationId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Location/Get/` + locationId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get a location's menu
    getLocationMenu(locationId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/LocationMenu/GetLocationMenu/` + locationId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get menu
    getMenu(locationMenuId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/LocationMenu/GetMenu/` + locationMenuId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get menu item
    getMenuItem(itemId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/LocationMenu/GetMenuItem/` + itemId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get menu item extra info
    getExtraInfo(itemId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/LocationMenuItem/GetExtraInfo/` + itemId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get schedule by date
    getScheduleByDate(itemId: string, selectedDate: string = null) {
        var url = `${environment.apiUrl.ecomm}/api/VendorSchedule/GetScheduleByDate?id=` + itemId;
        if(selectedDate != null && selectedDate != '') url += `&selectedDate=` + selectedDate;
        var self = this;
        return self.httpClient.get(url).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    getPosition(): Promise<any>
    {
        return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resp => {
            resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
            },
            err => {
            reject(err);
            });
        });
    }

    getMenuPage(menuItemId: string) {
        var self = this;
    }

    populateLocationAccess() {
        var self = this;

        self.verifyMyAccess(environment.vendorId).subscribe((resp: any) => {
            if(resp != null) {
                self.locationAccessLevel = new LocationAccessModel().deserialize(resp);

                self.isEditor = (self.locationAccessLevel.UserPermissionLevel == 1 || self.locationAccessLevel.UserPermissionLevel == 2);
            }
        });
    }

    verifyMyAccess(locationId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/LocationPermission/VerifyMyAccess/` + locationId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }
}