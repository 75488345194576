import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PushModel } from 'src/app/models/comms/push.model';
import { PaymentTypeModel } from 'src/app/models/customer/customer.payment.type.model';
import { PaymentRequestOutcomeModel } from 'src/app/models/order/payment.request.ouctome.model';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PushService } from 'src/app/services/push.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  loading: boolean = false;
  @Input() paymentId : string = '';
  @Output() paymentEvent = new EventEmitter<boolean>();
  showPaymentTypes: boolean = false;
  paymentSubmitted: boolean = false;
  paymentReqPesponse: PaymentRequestOutcomeModel = null;
  zapperUrl: SafeHtml = null;
  zapperUrlString: string = '';
  baseLaunchZapperUrl: string = 'https://www.zapper.com/payWithZapper?qr=';
  launchZapperUrl: string = '';
  showQRCode: boolean = false;
  paymentReference: string = '';
  step: number = 1;
  foundPayment: boolean = false;

  constructor(public orderService: OrderService, public paymentService: PaymentService, public notificationService: NotificationService,
    public pushService: PushService, private sanitizer: DomSanitizer) {
    var self = this;
  }

  ngOnInit() {
    var self = this;
    self.loading = true;

    if(self.orderService.selectedOrder.PaymentTypeId > 0) {
      self.paymentService.paymentTypes.forEach((item: any) => {
        if(self.orderService.selectedOrder.PaymentTypeId == item.PaymentTypeId) {
          self.selectPaymentType(item);
          self.foundPayment = true;
        }
      });
    }

    if(self.foundPayment == true) {
      self.step = 2;
      self.startPayment();
    }
    else {
      self.loading = false;
    }
  }

  selectPaymentType(paymentType: PaymentTypeModel) {
    var self = this;

    self.paymentService.selectedPaymentType = paymentType;
  }

  selectNewPaymentType() {
    var self = this;

    self.paymentService.selectedPaymentType = null;
  }

  startPayment() {
    var self = this;
    self.loading = true;

    var model = {
      orderId : self.orderService.selectedOrder.OrderId,
      paymentTypeId: self.paymentService.selectedPaymentType.PaymentTypeId,
      amount: self.orderService.orderPaymentAmount,
      returnUrl: environment.baseUrl + "tabs/orders",
      locationId: environment.vendorId
    };

    self.paymentService.savePayment(model).subscribe((resp: any) => {
      if(resp != null) {
        self.paymentReqPesponse = new PaymentRequestOutcomeModel().deserialize(resp);
        self.paymentSubmitted = true;

        self.completePaymentUI();
      }
      else {
        self.notificationService.showToastrError('Oops', 'An error occured');
        self.loading = false;
      }
    });
  }

  completePaymentUI() {
    var self = this;
    self.step = 2;

    switch(self.paymentService.selectedPaymentType.PaymentTypeId) {
      // EFT
      case 0: {
        if(self.paymentReqPesponse.PaymentSuccesful) {
          self.notificationService.showToastrSuccess('Done', 'Order Saved!');
          self.orderService.refreshSelectedOrder();
        }

        // self.housekeeping();

        break;
      }
      // PayFast
      case 1: {
        // submit to PayFast
        var responseJson = JSON.parse(self.paymentReqPesponse.JsonData);
        self.completeReDirect(responseJson);
        
        break;
      }
      // zapper
      case 2: {
        self.zapperUrl = self.paymentReqPesponse.PaymentResponseURL;
        self.zapperUrlString = self.sanitizer.sanitize(SecurityContext.HTML, self.zapperUrl)
        self.launchZapperUrl = self.baseLaunchZapperUrl + self.paymentReqPesponse.PaymentResponseURL;

        // receive push
        self.pushService.hubConnection.on(self.pushService.pushServicePrefix + '-' + self.paymentReqPesponse.OrderPaymentId, (data) => {
          var model = new PushModel().deserialize(data);
          
          if(model.PushType == 2) {
            if(model.PushOutcome == true) {
              self.paymentSuccessfull();
            }
            else {
              self.notificationService.showToastrError('Oops', 'Payment failed.');
            }
          }
        });

        self.showQRCode = true;
        self.loading = false;

        break;
      }
      // EFT
      case 4: {
        self.paymentReference = self.paymentReqPesponse.PaymentReference;

        self.loading = false;
        break;
      }
    }
  }

  paymentSuccessfull() {
    var self = this;

    self.notificationService.showToastrSuccess('Done', 'Payment successful!');
    self.orderService.refreshSelectedOrder();
    self.paymentEvent.next(true);
  }

  private createHiddenElement(name: string, value: string): HTMLInputElement {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }

  completeReDirect(inputData : any) {
    var self = this;

    self.paymentReqPesponse = new PaymentRequestOutcomeModel().deserializePayFast(inputData);

    var shMerchantDetails = null; 
    
    if(self.paymentReqPesponse.SplitMerchantId != null && self.paymentReqPesponse.SplitMerchantId != '' && self.paymentReqPesponse.SplitAmount > 0) {
      shMerchantDetails = {
        split_payment :{
          merchant_id : self.paymentReqPesponse.SplitMerchantId,
          percentage: self.paymentReqPesponse.SplitAmount
        }
      };
    }

    // create a form for the post request
    const form = window.document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', environment.externalSites.payFast.baseUrl);

    form.appendChild(self.createHiddenElement('merchant_id', self.paymentReqPesponse.MerchantId));
    form.appendChild(self.createHiddenElement('merchant_key', self.paymentReqPesponse.MerchantKey));

    form.appendChild(self.createHiddenElement('return_url', environment.baseUrl + "tabs/orders"));
    form.appendChild(self.createHiddenElement('notify_url', environment.externalSites.payFast.notificationUrl));
    form.appendChild(self.createHiddenElement('m_payment_id', self.paymentReqPesponse.OrderPaymentId));
    form.appendChild(self.createHiddenElement('amount', self.paymentReqPesponse.Amount.toString()));
    form.appendChild(self.createHiddenElement('item_name', self.paymentReqPesponse.ItemName));
    if(shMerchantDetails != null) form.appendChild(self.createHiddenElement('setup', JSON.stringify(shMerchantDetails)));
    // form.appendChild(self.createHiddenElement('signature', self.paymentReqPesponse.Signature.toLowerCase()));

    window.document.body.appendChild(form);
    form.submit();
    // self.loading = false;

    self.pushService.hubConnection.on(self.pushService.pushServicePrefix + '-' + self.paymentReqPesponse.OrderPaymentId.replace('ord-', ''), (data) => {
      var model = new PushModel().deserialize(data);
      
      if(model.PushType == 2) {
        if(model.PushOutcome == true) {
          self.paymentSuccessfull();
        }
        else {
          self.notificationService.showToastrError('Oops', 'Payment failed.');
        }
      }
    });
  }
}
