import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-location-capture-tracking',
  templateUrl: './location-capture-tracking.page.html',
  styleUrls: ['./location-capture-tracking.page.scss'],
})
export class LocationCaptureTrackingPage implements OnInit {
  loading: boolean = false;
  trackingForm: FormGroup;

  constructor(public formBuilder: FormBuilder, public notificationService: NotificationService, public modalController: ModalController,
    public orderService: OrderService) { }
    
    ngOnInit() {
      var self = this;
  
      self.prepareForm();
    }

    prepareForm() {
      var self = this;
  
      self.trackingForm = self.formBuilder.group({
        orderId: [self.orderService.selectedAdminOrder.OrderId],
        trackingNumber: ['', Validators.required],
        locationId: [self.orderService.selectedAdminOrder.LocationId, Validators.required]
      });
    }

    saveTrackingNumber() {
      var self = this;

      if(!self.trackingForm.valid) {
        self.notificationService.showFormError();
  
        return;
      }

      var model = self.trackingForm.value;

      self.orderService.captureTrackingNumber(model).subscribe((resp: any) => {
        if(resp != null) {
          self.notificationService.showToastrSuccess('Done', 'Order tracking saved.');
          self.orderService
          self.loadOrder();
        }
      });
    }

    loadOrder() {
      var self = this;
  
      self.orderService.loadingOrder = true;
  
      self.orderService.getLocationOrder(self.orderService.selectedAdminOrder.LocationId, self.orderService.selectedAdminOrder.OrderId).subscribe((resp: any) => {
        self.orderService.populateAdminOrder(resp);
  
        self.orderService.loadingOrder = false;
        self.dismissModal();
      });
    }
  
    dismissModal() {
      this.modalController.dismiss({
        'dismissed': true
      });
    }
}
