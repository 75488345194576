export const environment = {
  production: true,
  serverUrl: '/api',

  baseUrl: 'http://localhost:8100/',

  // sa surf hub on live
  //vendorId: 'A15133C5-C159-46FF-3342-08DAC710BAA0',
  // bss live
  vendorId: 'aa5ce11b-622b-4509-3343-08dac710baa0',
  rentals: '65769fbd-b3bf-49d1-0cd0-08da98650169',
  websiteName: 'Ballito Surf School',
  websiteLogo: '/assets/menu-logo.png',
  
  authentication: {
    url: "https://auth.sasurfhub.co.za",
    // url: "https://localhost:5001",
    client_id: "vendors_web_local",
    scope: "openid profile",
    secret: "vendorsClient@IU",
    oidc: true
  },

  apiUrl: {
    profile: 'https://ecommlive.sasurfhub.co.za',
    ecomm: 'https://ecommlive.sasurfhub.co.za',
    pushHubConnect: 'https://ecommlive.sasurfhub.co.za/PushHub'

    // profile: 'https://ecommstage.sasurfhub.co.za',
    // ecomm: 'https://ecommstage.sasurfhub.co.za',
    // pushHubConnect: 'https://ecommstage.sasurfhub.co.za/PushHub'

    // profile: 'https://localhost:44300',
    // ecomm: 'https://localhost:44300',
    // pushHubConnect: 'https://localhost:44300/PushHub'
  },

  externalSites: {
    payFast: {
      // testing
      // baseUrl: 'https://sandbox.payfast.co.za/eng/process',

      // bss live
      baseUrl: 'https://www.payfast.co.za/eng/process',
      notificationUrl: 'https://ecommlive.sasurfhub.co.za/api/payfast/paymentoutcomeform'
    }
  },
  // communicationsConfig: {
  //   key: "CC4E4BF4-17EF-478C-80C6-6AAB6D355D46",
  //   secret: "842E919E-BB3D-4902-9E46-DB4B8F6435A6",
  //   contactEmail: "ryan@ingenuityunlimited.co.za",
  //   contactName: "Ryan",
  //   contactTemplateId: ""
  // },
  communicationsConfig: {
    key: "5101FF8B-147F-3160-931C-08D7F73A8F01",
    secret: "CC4E4BF4-17EF-478C-80C6-6AAB6D355D41",
    contactEmail: "ryan@ingenuityunlimited.co.za",
    contactName: "Ryan",
    contactTemplateId: "",
    mediaTemplateId: "BDAAD464-09CD-42A4-1BD1-08DAC775BF1F",
    registerTemplateId: "55d2caaa-157f-4429-8b8f-a03a722f435e",
    forgotTemplateId: "31fdd928-5c8e-44b6-b8b2-3b34389b78f2"
  },
};