import { Deserializable } from "../deserialize.model";

export class BasketExtraInfoModel implements Deserializable  {
    Key: string = '';
    Value: string = '';
  
    deserialize(input: any) {
        var self = this;

        self.Key = input.key;
        self.Value = input.value;
        
        return self;
    }
  }
  