import { Deserializable } from "../deserialize.model";

export class PushModel implements Deserializable  {
    PushId: string = '';
    PushUserId: string = '';
    PushReferenceId: string = '';
    PushType: number = 0;
    PushMessage: string = '';
    PushOutcome: boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.PushId = input.pushId;
        self.PushUserId = input.pushUserId;
        self.PushReferenceId = input.pushReferenceId;
        self.PushType = input.pushType;
        self.PushMessage = input.pushMessage;
        self.PushOutcome = input.pushOutcome;
        
        return self;
    }
  }
  