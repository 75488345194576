import { Deserializable } from "../deserialize.model";

export class PaymentRequestOutcomeModel implements Deserializable  {
    OrderPaymentId: string = '';
    ClassifiedPaymentId: string = '';
    FootagePaymentId: string = '';
    OrderId: string = '';
    PaymentResponse: string = '';
    JsonData: string = '';
    PaymentResponseURL: string = '';
    PaymentReference: string = '';
    Amount: number = 0;
    Signature: string = '';
    PaymentSuccesful: boolean = false;
    PaymentSubmitted: boolean = false;
    ItemName: string = '';
    MerchantId: string = '';
    MerchantKey: string = '';
    NotifyURL: string = '';
    ReturnURL: string = '';

    SplitMerchantId: string = '';
    SplitMerchantKey: string = '';
    SplitAmount: number = 0;
  
    deserialize(input: any) {
        var self = this;

        self.OrderId = input.orderId || '';
        self.ItemName = input.item_name || '';
        self.Amount = input.amount || 0;
        self.OrderPaymentId = input.orderPaymentId || '';
        self.ClassifiedPaymentId = input.classifiedPaymentId || '';
        self.FootagePaymentId = input.footagePaymentId || '';
        
        self.PaymentResponse = input.paymentResponse || '';
        self.JsonData = input.jsonData || '';
        self.PaymentResponseURL = input.paymentResponseURL || '';
        self.PaymentReference = input.paymentReference || '';
        
        self.PaymentSuccesful = input.paymentSuccesful || false;
        self.PaymentSubmitted = input.paymentSubmitted || false;

        return self;
    }

    deserializePayFast(input: any) {
        var self = this;

        self.OrderPaymentId = input.m_payment_id || '';
        self.ClassifiedPaymentId = input.classifiedPaymentId || '';
        self.OrderId = input.orderId || '';
        self.PaymentResponse = input.paymentResponse || '';
        self.JsonData = input.jsonData || '';
        self.PaymentResponseURL = input.paymentResponseURL || '';
        self.Amount = input.amount || 0;
        self.PaymentSuccesful = input.paymentSuccesful || false;
        self.PaymentSubmitted = input.paymentSubmitted || false;
        self.Signature = input.signature;
        self.ItemName = input.item_name || '';
        self.MerchantId = input.merchant_id || '';
        self.MerchantKey = input.merchant_key || '';
        self.NotifyURL = input.notify_url || '';
        self.ReturnURL = input.return_url || '';

        self.SplitMerchantId = input.split_merchant_id || '';
        self.SplitMerchantKey = input.split_merchant_key || '';
        self.SplitAmount = input.split_amount || 0;

        return self;
    }
}