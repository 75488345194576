import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { MenuItemCardComponent } from '../components/menu-item-card/menu-item-card.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SocialLinksComponent } from '../components/social-links/social-links.component';
import { BookNowPopComponent } from '../components/book-now-pop/book-now-pop.component';
import { CheckoutComponent } from '../components/checkout/checkout.component';
import { AddCustomerContactComponent } from '../components/customers/add-customer-contact/add-customer-contact.component';
import { PaymentComponent } from '../components/payment/payment.component';
import { CamelCasePipe } from '../components/pipes/camel.case.pipe';
import { QRCodeModule } from 'angular2-qrcode';
import { AddCustomerAddressComponent } from '../components/customers/add-customer-address/add-customer-address.component';
import { FooterComponent } from '../components/footer/footer.component';
import { HeaderComponent } from '../components/header/header.component';
import { AdvertButtonComponent } from '../components/advert-button/advert-button.component';
import { ProductsBlockComponent } from '../components/products-block/products-block.component';
import { TagsStripComponent } from '../components/tags-strip/tags-strip.component';
import { IconPipe } from '../components/pipes/icon.pipe';
import { LoaderComponent } from '../components/loader/loader.component';
import { ScheduleListComponent } from '../components/schedule-list/schedule-list.component';
import { SwiperModule } from 'swiper/angular';
import { PhotoSliderComponent } from '../components/photo-slider/photo-slider.component';
import { OrderContactTabComponent } from '../components/orders/order-contact-tab/order-contact-tab.component';
import { OrderDeliveryTabComponent } from '../components/orders/order-delivery-tab/order-delivery-tab.component';
import { OrderDetailsTabComponent } from '../components/orders/order-details-tab/order-details-tab.component';
import { OrderPaymentsTabComponent } from '../components/orders/order-payments-tab/order-payments-tab.component';
import { AddMultiScheduleComponent } from '../components/add-multi-schedule/add-multi-schedule.component';
import { OrderCommsTabComponent } from '../components/orders/order-comms-tab/order-comms-tab.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    QRCodeModule,
    SwiperModule
  ],
  declarations: [
    MenuItemCardComponent, SocialLinksComponent, BookNowPopComponent, CheckoutComponent, AddCustomerContactComponent, 
    AddCustomerAddressComponent, PaymentComponent, CamelCasePipe, FooterComponent, HeaderComponent, AdvertButtonComponent,
    ProductsBlockComponent, TagsStripComponent, IconPipe, LoaderComponent, ScheduleListComponent, PhotoSliderComponent,
    OrderContactTabComponent, OrderDeliveryTabComponent, OrderDetailsTabComponent, OrderPaymentsTabComponent, AddMultiScheduleComponent,
    OrderCommsTabComponent
  ],
  exports: [
    MenuItemCardComponent, SocialLinksComponent, BookNowPopComponent, CheckoutComponent, AddCustomerContactComponent, 
    AddCustomerAddressComponent, PaymentComponent, CamelCasePipe, FooterComponent, HeaderComponent, AdvertButtonComponent,
    ProductsBlockComponent, TagsStripComponent, IconPipe, LoaderComponent, ScheduleListComponent, PhotoSliderComponent,
    OrderContactTabComponent, OrderDeliveryTabComponent, OrderDetailsTabComponent, OrderPaymentsTabComponent, AddMultiScheduleComponent,
    OrderCommsTabComponent
  ]
})
export class SharedModule {}
