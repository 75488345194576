import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { PaymentTypeModel } from 'src/app/models/customer/customer.payment.type.model';
import { OrderItemModel } from 'src/app/models/order/order.item.model';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-update-order-item',
  templateUrl: './update-order-item.page.html',
  styleUrls: ['./update-order-item.page.scss'],
})
export class UpdateOrderItemPage implements OnInit {
  updateForm: FormGroup;
  loading: boolean = false;
  inputOrderItem: OrderItemModel;
  orderStatuses: any[] = [];

  constructor(public modalController: ModalController, public formBuilder: FormBuilder, public notificationService: NotificationService,
    public orderService: OrderService) { }

  ngOnInit() {
    var self = this;
    self.prepareUpdateForm();
  }

  // prepare form
  prepareUpdateForm() {
    var self = this;

    self.updateForm = self.formBuilder.group({
      orderItemId: [self.inputOrderItem.OrderItemId],
      orderId: [self.orderService.selectedAdminOrder.OrderId],
      locationId: [self.orderService.selectedAdminOrder.LocationId, Validators.required],
      statusId: [self.orderService.selectedAdminOrder.OrderStatusId, Validators.required]
    });

    self.orderStatuses = [];
    self.orderStatuses.push({
      value: 20, text: 'Payment'
    });
    self.orderStatuses.push({
      value: 3, text: 'Cancelled'
    });
    self.orderStatuses.push({
      value: 5, text: 'Returned'
    });
    self.orderStatuses.push({
      value: 40, text: 'Processing'
    });
    self.orderStatuses.push({
      value: 41, text: 'In Progress'
    });
    self.orderStatuses.push({
      value: 52, text: 'Ready for courier'
    });
    self.orderStatuses.push({
      value: 51, text: 'Ready for pickup'
    });
    self.orderStatuses.push({
      value: 50, text: 'Out for delivery'
    });
    self.orderStatuses.push({
      value: 69, text: 'Delivered'
    });
    self.orderStatuses.push({
      value: 19, text: 'Complete'
    });
  }

  // submit form
  submitUpdate() {
    var self = this;

    if(!self.updateForm.valid) {
      self.notificationService.showFormError();
    }
    else {
      self.orderService.loadingOrder = true;

      self.orderService.updateItem(self.updateForm.value).subscribe((resp: any) => {
        if(resp != null && resp.outcome == true) {
          self.orderService.getLocationOrder(self.orderService.selectedAdminOrder.LocationId, self.orderService.selectedAdminOrder.OrderId).subscribe((orderResp: any) => {
            if(orderResp != null && orderResp.outcome == true) {
              self.orderService.populateAdminOrder(orderResp);
              self.orderService.loadingOrder = false;
  
              self.dismissModal();
            }
            else {
              self.notificationService.showToastrError('Oops', resp.outcomeMessage);
              self.orderService.loadingOrder = false;
            }
          });
        }
        else {
          self.notificationService.showToastrError('Oops', resp.outcomeMessage);
          self.orderService.loadingOrder = false;
        }
      });
    }
  }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
