import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./pages/terms-of-use/terms-of-use.module').then( m => m.TermsOfUsePageModule)
  },
  {
    path: 'contact-details',
    loadChildren: () => import('./components/customers/contact-details/contact-details.module').then( m => m.ContactDetailsPageModule)
  },
  {
    path: 'address-details',
    loadChildren: () => import('./components/customers/address-details/address-details.module').then( m => m.AddressDetailsPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'menu-item-pop',
    loadChildren: () => import('./pages/menu-item-pop/menu-item-pop.module').then( m => m.MenuItemPopPageModule)
  },
  {
    path: 'use-promo-pop',
    loadChildren: () => import('./components/use-promo-pop/use-promo-pop.module').then( m => m.UsePromoPopPageModule)
  },
  {
    path: 'user-permission',
    loadChildren: () => import('./components/user-permission/user-permission.module').then( m => m.UserPermissionPageModule)
  },
  {
    path: 'schedule-admin',
    loadChildren: () => import('./pages/schedule-admin/schedule-admin.module').then( m => m.ScheduleAdminPageModule)
  },
  {
    path: 'schedule-edit',
    loadChildren: () => import('./components/schedule-edit/schedule-edit.module').then( m => m.ScheduleEditPageModule)
  },
  {
    path: 'assign-user',
    loadChildren: () => import('./components/assign-user/assign-user.module').then( m => m.AssignUserPageModule)
  },
  {
    path: 'business-details',
    loadChildren: () => import('./components/customers/business-details/business-details.module').then( m => m.BusinessDetailsPageModule)
  },
  {
    path: 'media',
    loadChildren: () => import('./pages/media/media.module').then( m => m.MediaPageModule)
  },
  {
    path: 'media-admin',
    loadChildren: () => import('./pages/media-admin/media-admin.module').then( m => m.MediaAdminPageModule)
  },
  {
    path: 'media-edit',
    loadChildren: () => import('./pages/media-edit/media-edit.module').then( m => m.MediaEditPageModule)
  },
  {
    path: 'media-notify',
    loadChildren: () => import('./pages/media-notify/media-notify.module').then( m => m.MediaNotifyPageModule)
  },
  {
    path: 'surf-lessons',
    loadChildren: () => import('./pages/surf-lessons/surf-lessons.module').then( m => m.SurfLessonsPageModule)
  },
  {
    path: 'merchandise',
    loadChildren: () => import('./pages/merchandise/merchandise.module').then( m => m.MerchandisePageModule)
  },
  {
    path: 'media-notify',
    loadChildren: () => import('./pages/media-notify/media-notify.module').then( m => m.MediaNotifyPageModule)
  },  {
    path: 'location-orders',
    loadChildren: () => import('./pages/location-orders/location-orders.module').then( m => m.LocationOrdersPageModule)
  },
  {
    path: 'location-order',
    loadChildren: () => import('./pages/location-order/location-order.module').then( m => m.LocationOrderPageModule)
  },
  {
    path: 'location-capture-payment',
    loadChildren: () => import('./pages/location-capture-payment/location-capture-payment.module').then( m => m.LocationCapturePaymentPageModule)
  },
  {
    path: 'location-order-edit',
    loadChildren: () => import('./pages/location-order-edit/location-order-edit.module').then( m => m.LocationOrderEditPageModule)
  },
  {
    path: 'update-order-item',
    loadChildren: () => import('./pages/update-order-item/update-order-item.module').then( m => m.UpdateOrderItemPageModule)
  },
  {
    path: 'update-order',
    loadChildren: () => import('./pages/update-order/update-order.module').then( m => m.UpdateOrderPageModule)
  },
  {
    path: 'location-capture-tracking',
    loadChildren: () => import('./pages/location-capture-tracking/location-capture-tracking.module').then( m => m.LocationCaptureTrackingPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    OAuthModule.forRoot()
  ],
  providers: [OAuthService],
  exports: [RouterModule]
})
export class AppRoutingModule {}
