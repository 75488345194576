import { Component, Input, OnInit, ViewChild } from '@angular/core';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Swiper, Zoom } from 'swiper';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-photo-slider',
  templateUrl: './photo-slider.component.html',
  styleUrls: ['./photo-slider.component.scss'],
})
export class PhotoSliderComponent implements OnInit {
  @Input() photos: any[] = [];
  @ViewChild('imgSlider', { static: true}) imgSlider: Swiper;
  imageSrc: string = '';
  imageInterval: any = null;
  imageIndex: number = 0;

  slideOpts = {
    initialSlide: 0,
    slidesPerView: 4,
    spaceBetween: 1,
    speed: 400,
    loop: true,
    // centeredSlides: true,
    autoplay: {
      delay: 2000
    },
  };

  constructor() { }

  ngOnInit() {
    var self = this;

    if(self.photos.length > 0) {
      self.setIndex(0); 
    }
  }

  setIndex(index: number) {
    var self = this;

    self.imageIndex = index;
    
    if(self.imageInterval != null) clearInterval(self.imageInterval);

    self.imageSrc = self.photos[self.imageIndex].PhotoUrl;

    self.imageInterval = setInterval(function() {
      self.imageIndex++;

      if(self.imageIndex == self.photos.length) self.imageIndex = 0;

      self.imageSrc = self.photos[self.imageIndex].PhotoUrl;
    }, 3000);
  }
}
