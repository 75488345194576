import { Deserializable } from "../deserialize.model";

export class PaymentModel implements Deserializable  {
    OrderPaymentId: string = '';
    OrderId: string = '';
    PaymentType: string = '';
    PaymentTypeId: number = 0;
    RequestAmount: string = '';
    Status: string = '';
    StatusId: number = 0;
    PaymentDate: string = '';
    PaymentReference: string = '';
  
    deserialize(input: any) {
        var self = this;

        self.OrderPaymentId = input.orderPaymentId || '';
        self.OrderId = input.orderId || '';
        self.PaymentType = input.paymentType || '';
        self.PaymentTypeId = input.paymentTypeId || 0;
        self.RequestAmount = input.requestAmount || '0';
        self.Status = input.status || '';
        self.StatusId = input.statusId || 0;
        self.PaymentDate = input.paymentDate || '';
        self.PaymentReference = input.paymentReference || '';

        return self;
    }
}