import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BasketExtraInfoModel } from 'src/app/models/basket/basket.extra.info.model';
import { LocationMenuItemExtraModel } from 'src/app/models/locations/location.menu.item.extra.model';
import { LocationMenuItemModel } from 'src/app/models/locations/location.menu.item.model';
import { LocationMenuItemPropertyModel } from 'src/app/models/locations/location.menu.item.property.model';
import { LocationScheduleModel } from 'src/app/models/locations/location.schedule.model';
import { OrderItemModel } from 'src/app/models/order/order.item.model';
import { CheckoutPage } from 'src/app/pages/checkout/checkout.page';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';
import { SessionService } from 'src/app/services/session.service';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-book-now-pop',
  templateUrl: './book-now-pop.component.html',
  styleUrls: ['./book-now-pop.component.scss'],
})
export class BookNowPopComponent implements OnInit {
  @Input() selectedMenuItem: LocationMenuItemModel = new LocationMenuItemModel();
  @Input() inputSchedule: LocationScheduleModel;
  @Output() basketEvent = new EventEmitter<boolean>();
  @Output() popEvent = new EventEmitter<boolean>();
  amount: number = 1;
  total: number = 0;
  showCheckout: boolean = false;
  showSelectSchedule: boolean = false;
  showSelectDate: boolean = false;
  canBook: boolean = false;
  loading: boolean = false;
  selectedSchedule: LocationScheduleModel;
  basketInfo: BasketExtraInfoModel[] = [];

  slideOpts = {
    speed: 400,
    slidesPerView: 3,
    loop: true,
    autoplay: true,
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 320px
      320: {
        slidesPerView: 1,
      },
      // when window width is <= 480px
      480: {
        slidesPerView: 1,
      },
      // when window width is <= 640px
      640: {
        slidesPerView: 2,
      },
      // when window width is <= 640px
      900: {
        slidesPerView: 3,
      }
    }
  };
  
  constructor(public orderService: OrderService, public notificationService: NotificationService, public sessionService: SessionService, 
    public locationService: LocationService, public formBuilder: FormBuilder, public authService: AuthService, public modalController: ModalController) { }

  ngOnInit() {
    var self = this;
    self.total = self.amount * self.selectedMenuItem.Price;
    self.loading = true;

    self.locationService.getMenuItem(self.selectedMenuItem.LocationMenuItemId).subscribe((resp: any) => {
      //console.log(resp);
    });

    self.locationService.getExtraInfo(self.selectedMenuItem.LocationMenuItemId).subscribe((resp: any) => {
      if(resp.outcome == true && resp.outcomeData != null) {
        self.selectedMenuItem.ExtraData = new LocationMenuItemExtraModel().deserialize(resp.outcomeData);
        self.prepareForm();

        if(self.inputSchedule != null) self.selectLocationSchedule(self.inputSchedule);

        // if(self.selectedMenuItem.ScheduledItem == false || self.selectedMenuItem.ExtraData.Schedule.length > 0) {
        //   self.canBook = true;
        // }
      }

      self.loading = false;
    });
  }

  purchaseForm: FormGroup;
  prepareForm() {
    var self = this;
    self.purchaseForm = self.formBuilder.group({
    });

    if(self.selectedMenuItem.ExtraData.Properties != null) {
      self.selectedMenuItem.ExtraData.Properties.forEach((itemProperty: LocationMenuItemPropertyModel) => {
        self.purchaseForm.addControl(itemProperty.PropertyKey, new FormControl('', itemProperty.Required ? Validators.required : null));
      });
    }
  }

  increment() {
    var self = this;

    self.amount++;
    self.total = self.amount * self.selectedMenuItem.Price;
  }

  decrement() {
    var self = this;

    if(self.amount > 1) {
      self.amount--;

      self.total = self.amount * self.selectedMenuItem.Price;
    }
  }

  selectLocationSchedule(model: LocationScheduleModel) {
    var self = this;

    self.selectedSchedule = model;
    self.showSelectSchedule = false;

    self.updateExtraInfo('ScheduleSelectedDate', self.selectedSchedule.DateStart.toString().substring(4, 21));

    self.verifyCanBook();
  }

  addToCart() {
    var self = this;

    if(!self.authService.isAuthenticated()) {
      self.notificationService.showAuthError();

      return;
    }

    self.verifyCanBook();

    if(!self.canBook) {
      if(!self.purchaseForm.valid) {
        self.notificationService.showFormError();
      }
      else {
        self.notificationService.showToastrError('Oops', 'Please select a date.');
      }

      return;
    }

    var formItem = null;

    // loop all properties and add to basketInfo
    self.selectedMenuItem.ExtraData.Properties.forEach((item: any) => {
      formItem = self.purchaseForm.get(item.PropertyKey);
      if(formItem != null) self.updateExtraInfo(item.PropertyKey, formItem.value);
    });

    var model = {
      LocationMenuItemId: self.selectedMenuItem.LocationMenuItemId,
      Name: self.selectedMenuItem.Name,
      Price: self.selectedMenuItem.Price,
      Quantity: self.amount,
      SessionId: self.sessionService.sessionId,
      JsonData: JSON.stringify(self.basketInfo),
      VendorScheduleId: null
    };

    if(self.selectedSchedule != null) model.VendorScheduleId = self.selectedSchedule.VendorScheduleId;

    self.loading = true;

    self.orderService.saveToBasket(model).subscribe((resp : any) => {
      if(resp != null) {
          var index = -1;
          var result = 0;
          result = resp.quantity;
          model.Quantity = result;

          for(var count = 0; count < self.orderService.orderItems.length; count++) {
              if(self.orderService.orderItems[count].BasketItemId == resp.basketItemId) {
                  self.orderService.orderItems[count].Quantity = resp.quantity;
                  self.orderService.orderItems[count].Total = self.orderService.orderItems[count].Quantity * self.orderService.orderItems[count].Price;
                  index = count;
                  count = self.orderService.orderItems.length;
              }
          }

          if(resp.quantity == 0 && index > -1) {
              self.orderService.orderItems.splice(index, 1);
              self.notificationService.showToastrSuccess("Success","Item removed from your basket.");
              // self.basketEvent.next(true);
          }
          else if(index == -1) {
              resp.price = model.Price;

              self.orderService.orderItems.push(new OrderItemModel().deserialize(resp));
              self.notificationService.showToastrSuccess("Success","Item added to your basket.");
              // self.basketEvent.next(true);
          }
          else {
              self.notificationService.showToastrSuccess("Success","Basket item updated.");
              // self.basketEvent.next(true);
          }

          self.loading = false;

          self.orderService.calculateTotal();
          self.showCheckout = true;
      }
      else {
          self.notificationService.showToastrError('Oops', 'Product not added, please try again.');
      }
    });
  }

  showBasket() {
    var self = this;

    self.basketEvent.next(true);
  }

  async showCheckoutModal() {
    var self = this;

    const modal = await self.modalController.create({
      component: CheckoutPage
    });
    return await modal.present();
  }

  formatDate(value: string) {
    return value;
  }

  selectedDate: Date;
  selectDate(value: any) {
    var self = this;
    self.selectedDate = new Date(value);
    self.updateExtraInfo('Date', self.selectedDate.toString().substring(4, 21));
    
    self.verifyCanBook();
  }

  startDate: string = '';
  showSelectStart: boolean = false;

  selectStart(value: string) {
    var self = this;
    self.startDate = value;
    self.showSelectStart = false;
  }

  endDate: string = '';
  showSelectEnd: boolean = false;
  selectEnd(value: string) {
    var self = this;
    self.endDate = value;
    self.showSelectEnd = false;
  }

  verifyCanBook() {
    var self = this;
    
    // validate if the product or service requires a form to be complete
    self.canBook = self.purchaseForm.valid;

    if(!self.canBook) {
      return;
    }

    // validation for product
    if(self.selectedMenuItem.MenuItemTypeId == 1) {
    }
    // validation for service
    else if(self.selectedMenuItem.MenuItemTypeId == 2) {
      // validate if a schedule has been selected for scheduled items
      if(self.selectedMenuItem.ScheduledItem) {
        self.canBook = self.selectedSchedule != null;
      }
      // validate if a date has been selected for items that require a date
      else {
        self.canBook = self.selectedDate != null;
      }
    }
  }

  updateExtraInfo(key: string, value: string) {
    var self = this;

    var found = false;

    self.basketInfo.forEach((info: BasketExtraInfoModel) => {
      if(info.Key == key) {
        found = true;
        info.Value = value;
      }
    });

    if(!found) {
      self.basketInfo.push(new BasketExtraInfoModel().deserialize({
        key: key,
        value: value
      }));
    }
  }
}