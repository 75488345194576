import { Deserializable } from '../deserialize.model';

export class CustomerContactDetailModel implements Deserializable  {
    CustomerContactDetailId: string = '';
    Name: string = '';
    ContactPerson: string = '';
    Detail: string = '';
    ContactTypeId: number = 1;

    Hidden: boolean = false;

    ContactDetailIcon: string = '';
    ContactDetailLink: string = '';
  
    deserialize(input: any) {
        var self = this;

        self.CustomerContactDetailId = input.customerContactDetailId;
        self.Name = input.name;
        self.ContactPerson = input.contactPerson;
        self.Detail = input.detail;
        self.ContactTypeId = input.contactTypeId;

        self.setLinkAndIcon();
        
        return self;
    }

    setLinkAndIcon() {
        var self = this;

        switch(self.ContactTypeId) {
            case 1:
                {
                    self.ContactDetailIcon = 'call';
                    self.ContactDetailLink = 'tel:' + self.Detail;
                    break;
                }
            case 2:
                {
                    self.ContactDetailIcon = 'mail';
                    self.ContactDetailLink = 'mailto:' + self.Detail;
                    break;
                }
            case 3:
                {
                    self.ContactDetailIcon = 'globe';
                    if(self.Detail.indexOf('http://') < 0) {
                        self.ContactDetailLink = 'http://' + self.Detail;;
                    }
                    else {
                        self.ContactDetailLink = self.Detail;
                    }
                    
                    break;
                }
            case 4:
                {
                    self.ContactDetailIcon = 'logo-facebook';
                    self.ContactDetailLink = 'https://facebook.com/' + self.Detail;
                    break;
                }
            case 5:
                {
                    self.ContactDetailIcon = 'logo-twitter';
                    self.ContactDetailLink =  'https://twitter.com/' + self.Detail;
                    break;
                }
            case 6:
                {
                    self.ContactDetailIcon = 'logo-instagram';
                    if(self.Detail.indexOf('instagram.com') < 0) {
                        self.ContactDetailLink = 'https://instagram.com/' + self.Detail.replace('@', '');
                    }
                    else {
                        self.ContactDetailLink = self.Detail;
                    }
                    
                    break;
                }
            case 7:
                {
                    self.ContactDetailIcon = 'logo-whatsapp';
                    self.ContactDetailLink = 'https://wa.me/' + self.Detail;
                    
                    break;
                }
        }
    }
}