import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocationModel } from '../models/locations/location.model';
import { environment } from 'src/environments/environment';
import { LocationMenuModel } from '../models/locations/location.menu.model';
import { LocationAccessModel } from '../models/users/location.access.model';

const routes = {
};

@Injectable()
export class UserService {
    loading: boolean = false;
    locationId: string = environment.vendorId;
    userPermissions: LocationAccessModel[] = [];

    constructor(private httpClient: HttpClient) {
        var self = this;
    }

    // get user permissions
    getLocationPermissions(userPermission: number = 0) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/LocationPermission/GetUsers?id=` + self.locationId + `&userPermission=` + userPermission).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get non subscribers
    getNonSubscribers() {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/LocationPermission/GetNonSubscribers?id=` + self.locationId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get permission
    verifyUser(profileId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/LocationPermission/VerifyUser?locationId=` + self.locationId + `&profileId=` + profileId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // save permission
    savePermission(context: any) :  Observable<any> {
        return this.httpClient
        .post(`${environment.apiUrl.ecomm}/api/LocationPermission/SaveUserPermission`, context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
                })
            );
    }

    // search profiles
    searchProfiles(searchText: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Profile/Search/` + searchText).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }
}