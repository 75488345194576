import { Deserializable } from "../deserialize.model";

export class MediaProfileModel implements Deserializable  {
    ProfileId: string = '';
    ProfileName: string = '';
    CoverPicture: string = '';
  
    deserialize(input: any) {
        var self = this;

        self.ProfileId = input.profileId || '';
        self.ProfileName = input.name || '';
        self.CoverPicture = input.coverPicture || '/assets/imgs/profile-photo.png';
        
        return self;
    }

    deserializeAccess(input: any) {
        var self = this;

        self.ProfileId = input.profileId || '';
        self.ProfileName = input.profileName || '';
        self.CoverPicture = input.coverPicture || '/assets/imgs/profile-photo.png';
        
        return self;
    }
  }
  