import { Deserializable } from '../deserialize.model';
import { LocationAddressDetailsModel } from './location.address.detail.model';

export class LocationAddressModel implements Deserializable  {
    LocationId: string = '';
    AddressId: string = '';
    AddressType: string = '';
    AddressTypeId: number = 9;
    AddressDetails: LocationAddressDetailsModel = new LocationAddressDetailsModel();
    Delete: boolean = false;
  
    deserialize(inputModel: any) {
        var self = this;

        self.LocationId = inputModel.locationId;
        self.AddressId = inputModel.addressId;
        self.AddressType = inputModel.addressType;
        self.AddressTypeId = inputModel.addressTypeId;
        self.Delete = inputModel.delete;

        if(inputModel.addressDetails != null && inputModel.addressDetails != '') {
            self.AddressDetails = new LocationAddressDetailsModel().deserialize(JSON.parse(inputModel.addressDetails));
        }
        
        return self;
    }

    update(inputModel: any) {
        var self = this;

        self.AddressType = inputModel.addressType;
        self.AddressTypeId = inputModel.addressTypeId;

        if(inputModel.addressDetails != null && inputModel.addressDetails != '') {
            self.AddressDetails = new LocationAddressDetailsModel().deserialize(JSON.parse(inputModel.addressDetails));
        }
    }
}