import { Deserializable } from "../deserialize.model";
import { LocationAccessModel } from "../users/location.access.model";

export class ProfileModel implements Deserializable  {
    Id: string = '';
    Name: string = '';
    EmailAddress: string = '';
    Description: string = '';
    CoverPicture: string = '';
    LoggedIn: boolean = false;
    LocationAccess: LocationAccessModel = null;
  
    deserialize(input: any) {
        var self = this;

        self.Id = input.profileId || '';
        self.Name = input.name || '';
        self.EmailAddress = input.emailAddress || '';        
        self.Description = input.description || '';
        self.CoverPicture = input.coverPicture || '/assets/imgs/profile-photo.png';

        if(input.loggedIn != null && input.loggedIn == true) self.LoggedIn = true;

        if(input.locationAccess != null) {
            self.LocationAccess = new LocationAccessModel().deserialize(input.locationAccess);
        }
        
        return self;
    }
  }
  