import { Deserializable } from "../deserialize.model";
import { ProfileModel } from "../profile/profile.model";
import { ProfileMediaModel } from "./media.model";
import { MediaProfileModel } from "./media.profile.model";

export class MediaAlbumModel implements Deserializable  {
    ProfileMediaAlbumId: string = '';
    Name: string = '';
    Description: string = '';
    CoverPicture: string = '';
    MediaURL: string = '';

    LocationId: string = '';
    LocationName: string = '';

    DateCreated: string = '';
    DeleteDate: string = '';

    ProfileMedia: ProfileMediaModel[] = [];
    Profiles: MediaProfileModel[] = [];

    Hidden: boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.ProfileMediaAlbumId = input.profileMediaAlbumId;
        self.Name = input.name;
        self.Description = input.description;
        self.CoverPicture = input.coverPicture;
        self.MediaURL = input.mediaURL;
        self.LocationId = input.locationId;
        self.LocationName = input.locationName;
        self.DateCreated = input.dateCreated;
        self.DeleteDate = input.deleteDate;

        if(input.profileMedia != null) {
            input.profileMedia.forEach((media: any) => {
                self.ProfileMedia.push(new ProfileMediaModel().deserialize(media));
            });
        }

        if(input.profiles != null) {
            console.log(input.profiles);
            input.profiles.forEach((profile: any) => {
                
                self.Profiles.push(new MediaProfileModel().deserialize(profile));
            });
        }
        
        return self;
    }
}