import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-advert-button',
  templateUrl: './advert-button.component.html',
  styleUrls: ['./advert-button.component.scss'],
})
export class AdvertButtonComponent implements OnInit {
  @Input() inputText : string = '';
  @Input() inputLink : string[] = [];
  linkString : string[] = [];
  @Input() inputButtonText : string = '';
  loading: boolean = true;

  constructor() { }

  ngOnInit() {
    var self = this;

    // console.log(self.inputText);
    // console.log(self.inputLink);
    // console.log(self.inputButtonText);
    // self.loading = false;
  }

}
