import { Component } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from 'src/environments/environment';
import { PushModel } from './models/comms/push.model';
import { AuthService } from './services/auth.service';
import { CoreService } from './services/core.service';
import { CustomerService } from './services/customer.service';
import { LocationService } from './services/location.service';
import { OrderService } from './services/order.service';
import { PushService } from './services/push.service';
import { SessionService } from './services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private authService: AuthService, private oauthService: OAuthService, public sessionService: SessionService, 
    public locationService: LocationService, public orderService: OrderService, public customerService: CustomerService,
    public pushService: PushService, public coreService: CoreService) {}
  
  ngOnInit() {
    var self = this;
    self.configureOAuth();
    self.authService.checkForAuthCookie();
    self.getLocationDetails();

    // TODO: push notifications
    self.pushService.startConnection();
    self.pushService.hubConnection.on(self.pushService.pushServicePrefix, (data) => {
      var model = new PushModel().deserialize(data);
    });
  }

  private configureOAuth() {
    const self = this;
    
    const authConfig: AuthConfig = {
      issuer: environment.authentication.url,          
      clientId: environment.authentication.client_id,
      scope: environment.authentication.scope,
      dummyClientSecret: environment.authentication.secret,
      oidc: environment.authentication.oidc,
      strictDiscoveryDocumentValidation: false,
      showDebugInformation: true
    };

    self.oauthService.configure(authConfig);
    self.oauthService.setupAutomaticSilentRefresh();
    self.oauthService.tokenValidationHandler = new JwksValidationHandler();

    self.oauthService.loadDiscoveryDocument().then(result => {
      if(self.oauthService.hasValidAccessToken()) {
        self.tryLogin();
      }
      else {
        self.sessionService.houseKeeping();
      }
    }, error => {
      
    });
  }

  tryLogin() {
    var self = this;
    self.oauthService.tryLogin().then((resp : any) => {
      if(self.oauthService.hasValidAccessToken() == true) {
        self.authService.authtoken = self.oauthService.getAccessToken();
        self.authService.authenticationState.next(true);
        self.orderService.populateBasket();
        self.customerService.populateCustomer();
        self.locationService.populateLocationAccess();
        
        self.sessionService.houseKeeping();

        self.authService.getMyProfile().subscribe(resp => {
          self.completeLogin(resp);
        }, error => {
          self.sessionService.houseKeeping();
        });
      }
      else {
        self.sessionService.houseKeeping();
      }
    }, (error: any) => {
      self.sessionService.houseKeeping();
    });
  }

  completeLogin(inputData: any) {
    var self = this;
    
    if(inputData != null) {
      // show pop up
      // self.notificationService.showToastrSuccess('Welcome', 'Login success.');

      self.authService.gotMyProfile(inputData);
    }
    else {
      // show pop up
      // self.notificationService.showToastrSuccess('Welcome', 'Login success.');
      // self.authService.openProfileEdit();
    }
  }

  getLocationDetails() {
    var self = this;

    self.locationService.loading = true;
    self.locationService.getLocation(environment.vendorId).subscribe((resp: any) => {
      self.locationService.populateLocation(resp);
      self.coreService.populatePopularLinks();
    });
  }
}
