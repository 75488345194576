import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerModel } from '../models/customer/customer.model';
import { CustomerContactDetailModel } from '../models/customer/customer.contact.detail.model';
import { CustomerAddressModel } from '../models/customer/customer.address.model';
import { CustomerBusinessDetailModel } from '../models/customer/customer.business.detail.model';
import { PaymentTypeModel } from '../models/customer/customer.payment.type.model';

const routes = {
    get: () => environment.apiUrl.ecomm + `/api/Customers/Get`,
    // getSomething: (id: string) => environment.apiUrl.ecomm + `/api/Customers/Get/` + id,
    saveContactDetails: () => environment.apiUrl.ecomm + `/api/CustomerContactDetail/Save`,
    saveBusinessDetails: () => environment.apiUrl.ecomm + `/api/CustomerBusinessDetail/Save`,
    saveAddress: () => environment.apiUrl.ecomm + `/api/CustomerAddress/Save`,
};

@Injectable()
export class CustomerService {
    loadingCustomer: boolean = false;
    selectedCustomer: CustomerModel = new CustomerModel();
    
    selectedContactDetails: CustomerContactDetailModel = null;
    deliveryContactDetails: CustomerContactDetailModel[] = [];
    
    selectedAddress: CustomerAddressModel = null;
    deliveryAddress: CustomerAddressModel = null;

    selectedBusinessDetails: CustomerBusinessDetailModel = null;
    businessDetails: CustomerBusinessDetailModel = null;

    selectedPaymentType: PaymentTypeModel = null;
    paymentTypes: PaymentTypeModel[] = [];

    checkoutDisplayView: string = '';

    constructor(private httpClient: HttpClient) {
        var self = this;
    }

    populateCustomer() {
        var self = this;

        self.loadingCustomer = true;
        self.getCustomer().subscribe((resp: any) => {
            if(resp != null) {
              self.selectedCustomer = new CustomerModel().deserialize(resp);
            }
      
            self.loadingCustomer = false;
          });
    }

    // get customer
    getCustomer() {
        var self = this;
        return self.httpClient.get(routes.get()).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // save customer contact details
    saveCustomerContactDetails(context: any) :  Observable<any> {
        return this.httpClient
        .post(routes.saveContactDetails(), context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
                })
            );
    }

    // save customer business details
    saveCustomerBusinessDetails(context: any) :  Observable<any> {
        return this.httpClient
        .post(routes.saveBusinessDetails(), context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
                })
            );
    }

    // save customer address
    saveCustomerAddress(context: any) :  Observable<any> {
        return this.httpClient
        .post(routes.saveAddress(), context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
                })
            );
    }

    // set the selected customer address
    selectCustomerAddress(model: CustomerAddressModel) {
        var self = this;

        // get index
        var index = self.selectedCustomer.Addresses.indexOf(model);

        if(index > -1) {
            // remove item from selectable details
            self.selectedCustomer.Addresses.splice(index, 1);
        }

        if(self.deliveryAddress != null) self.selectedCustomer.Addresses.splice(0,0, self.deliveryAddress);

        // add new
        self.deliveryAddress = model;
    }

    // set the selected customer business details
    selectCustomerBusiness(model: CustomerBusinessDetailModel) {
        var self = this;

        // get index
        var index = -1; 
        for(var count = 0; count < self.selectedCustomer.BusinessDetails.length; count++) {
            if(self.selectedCustomer.BusinessDetails[count].CustomerBusinessDetailId == model.CustomerBusinessDetailId) {
                index = count;
                count = self.selectedCustomer.BusinessDetails.length;
            }
        }

        if(index > -1) {
            // remove item from selectable details
            self.selectedCustomer.BusinessDetails.splice(index, 1);
        }

        // if there is already a business selected
        if(self.businessDetails != null) {
            // add back to the customer's businesss
            self.selectedCustomer.BusinessDetails.splice(0, 0, self.businessDetails);
        }

        // add new
        self.businessDetails = model;
    }

    // set the selected customer contact details
    selectCustomerContact(model: CustomerContactDetailModel) {
        var self = this;

        // get index
        var index = self.selectedCustomer.ContactDetails.indexOf(model);

        if(index > -1) {
            // remove item from selectable details
            self.selectedCustomer.ContactDetails.splice(index, 1);
        }

        // if there is already a contact selected
        if(self.deliveryContactDetails.length > 0) {
            self.deliveryContactDetails.forEach(contact => {
                // add back to the customer's contacts
                self.selectedCustomer.ContactDetails.splice(0, 0, contact);
            });
            
            // empty list
            self.deliveryContactDetails = [];
        }

        // add new
        self.deliveryContactDetails.push(model);
    }

    // set the selected customer payment type
    selectPaymentType(model: PaymentTypeModel) {
        var self = this;

        self.selectedPaymentType = model;
    }

    // get by id
    /*getSomething(id: string) {
        var self = this;
        return self.httpClient.get(routes.getSomething(id)).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }*/

    
}