import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ProfileModel } from '../models/profile/profile.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    authtoken: string = '';
    authenticationState = new BehaviorSubject(false);
    profileUrl = environment.apiUrl.profile;
    profile = new ProfileModel();
    authenticated: boolean = false;
    cookieName: string = 'vendors-auth-token';
    emailCookieName: string = 'vendors-auth-email';
    isEcommOwner: boolean = false;
    authDisplayView: string = 'login';
    url = environment.authentication.url;
    userId: string = '';
//   vendorDropdown: any[] = [];
 
  constructor(
    private http: HttpClient, private oauthService: OAuthService) {
      const self = this;
    //   self.http.options
  }

  getMyProfile() {
    var self = this;

    return self.http.get(`${self.profileUrl}/api/Profile/GetMyProfile?locationId=` + environment.vendorId).pipe(
      catchError(e => {
        // self.showAlert(e.error.msg);
        throw new Error(e);
      })
    );
  }
 
  checkToken() {
    return this.oauthService.hasValidAccessToken();
  }
 
  register(credentials: any) {
    return this.http.post(`${this.url}/api/Account/Register`, credentials).pipe(
      catchError(e => {
        // this.showAlert(e.error.msg);
        throw new Error(e);
      })
    );
  }

  forgotPassword(credentials: any) {
    return this.http.post(`${environment.apiUrl.ecomm}/api/Profile/SendResetPasswordOTP`, credentials).pipe(
      catchError(e => {
        // this.showAlert(e.error.msg);
        throw new Error(e);
      })
    );
  }

  completeForgotPassword(credentials: any) {
    return this.http.post(`${environment.apiUrl.ecomm}/api/Profile/ResetPassword`, credentials).pipe(
      catchError(e => {
        // this.showAlert(e.error.msg);
        throw new Error(e);
      })
    );
  }

  // forgotPassword(email: any, clientId: string = '') {
  //   var url = `${this.url}/api/Account?email=` + email;

  //   if(clientId != null && clientId != '') url += `&clientId=` + clientId;
  //   return this.http.get(url).pipe(
  //     catchError(e => {
  //       // this.showAlert(e.error.msg);
  //       throw new Error(e);
  //     })
  //   );
  // }
 
  login(credentials: any) {
    const self = this;
    
    return self.oauthService.fetchTokenUsingPasswordFlow(credentials.username, credentials.password).then((resp : any) => {
      return resp;
    });
  }
 
  logout() {
    const self = this;

    self.profile.LoggedIn = false;
    self.authenticationState.next(false);
    self.saveProfile(self.profile);
    
    self.oauthService.logOut();
    self.authenticated = self.isAuthenticated();
    self.housekeeping();
    // self.clearCookie();

    self.authDisplayView = self.authenticated == true ? 'profile' : 'login';
  }

  isAuthenticated() {
    const self = this;
    return self.oauthService.hasValidAccessToken();
  }

  /* PROFILE */

  openProfileEdit() {
    var self = this;
    self.authDisplayView = 'profile-edit';
  }

  completeLogin() {
    var self = this;
    self.getMyProfile().subscribe(resp => {
      self.gotMyProfile(resp);
    }, error => {
    });
  }

  gotMyProfile(resp: any) {
    var self = this;
    if(resp != null) {
      self.profile = new ProfileModel().deserialize(resp);
      self.authenticated = self.isAuthenticated();
      self.authDisplayView = self.authenticated == true ? 'profile' : 'login';
      self.validateEcommAuth();
    }
  }

  getProfile(id: any) {
    var self = this;

    return this.http.get(`${self.profileUrl}/api/Profile/Get?id=` + id).pipe(
      catchError(e => {
        // this.showAlert(e.error.msg);
        throw new Error(e);
      })
    );
  }

  saveProfile(model: any) {
    var self = this;

    return this.http.post(`${self.profileUrl}/api/Profile/Save`, model).pipe(
      catchError(e => {
        // this.showAlert(e.error.msg);
        throw new Error(e);
      })
    );
  }
  /* PROFILE */

  /***** COOKIE *****/
  
  // check for cookie token
  checkForAuthCookie() {
    var self = this;

    var cookie = null;

    // check for cookie
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${self.cookieName}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            cookie = c.substring(cookieName.length, c.length);
        }
    }
    
    if(cookie != null) {
      self.authenticated = false;
      self.authtoken = cookie;

      self.completeLogin();
    }
  }

  getAuthEmail() {
    var self = this;

    var cookie = null;

    // check for cookie
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${self.emailCookieName}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            cookie = c.substring(cookieName.length, c.length);
        }
    }
    
    // if none, show message
    if(cookie != null) {
      return cookie;
    }

    return '';
  }

  // clear cookie token
  clearCookie() {
      var self = this;
      self.setCookie(0);
  }

  // create cookie
  setCookie(hours: number, email: string = '') {
      var self = this;

      let d:Date = new Date();
      d.setTime(d.getTime() + hours * 60 * 60 * 1000);
      let expires:string = `expires=${d.toUTCString()}`;
      document.cookie = `${self.cookieName}=${self.authtoken}; ${expires}`;
      document.cookie = `${self.emailCookieName}=${email}; ${expires}`;
  }

  /***** COOKIE *****/
 
  housekeeping() {
    var self = this;
    self.profile = new ProfileModel();
    self.userId = '';
  }

  getHeaders() : HttpHeaders {
    var self = this;

    let headers = new HttpHeaders({
      'Authorization':'Bearer ' + self.authtoken
    });

    return headers;
  }

  validateEcommAuth() {
    var self = this;

    self.checkIsOwner().subscribe((resp: any) => {
      if(resp != null) self.isEcommOwner = resp;
    });
  }

  checkIsOwner() {
    var self = this;

    return self.http.get(`${environment.apiUrl.ecomm}/api/AuthCheck/Get`).pipe(
      catchError(e => {
        // self.showAlert(e.error.msg);
        throw new Error(e);
      })
    );
  }

  /***** CONFIG *****/
//   populateVendorDropdown() {
//     var self = this;

//     self.vendorDropdown = [];

//     self.getVendorDropdownData().subscribe((resp: any) => {
//       if(resp != null && resp.length > 0) {
//         resp.forEach((item: any) => {
//           self.vendorDropdown.push(new DropdownModel().deserialize(item));
//         });
//       }
//     });
//   }

//   getVendorDropdownData(searchText: string = '') {
//     var self = this;

//     return self.http.get(`${environment.apiUrl.ecomm}/api/Location/GetLocationDropdown?searchText=` + searchText).pipe(
//       catchError(e => {
//         // self.showAlert(e.error.msg);
//         throw new Error(e);
//       })
//     );
//   }
  /***** CONFIG *****/
}