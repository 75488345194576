import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CustomerBusinessDetailModel } from 'src/app/models/customer/customer.business.detail.model';
import { CoreService } from 'src/app/services/core.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.page.html',
  styleUrls: ['./business-details.page.scss'],
})
export class BusinessDetailsPage implements OnInit {
  businessForm: FormGroup;
  loading: boolean = false;
  isNew: boolean = false;
  
  constructor(public formBuilder: FormBuilder, public modalController: ModalController,
    private coreService: CoreService, public customerService: CustomerService, public notificationService: NotificationService) {
    var self = this;

    self.prepareBusinessForm();
  }

  ngOnInit() {}

  prepareBusinessForm() {
    var self = this;

    if(self.customerService.selectedBusinessDetails == null) self.customerService.selectedBusinessDetails = new CustomerBusinessDetailModel();

    if(self.customerService.selectedBusinessDetails.CustomerBusinessDetailId == '') self.isNew = true;

    self.businessForm = self.formBuilder.group({
      customerBusinessDetailId: [self.customerService.selectedBusinessDetails.CustomerBusinessDetailId],
      name: [self.customerService.selectedBusinessDetails.Name, Validators.required],
      vatNumber: [self.customerService.selectedBusinessDetails.VatNumber, Validators.required]
    });
  }

  saveBusiness() {
    var self = this;

    if(!self.businessForm.valid) {
      self.notificationService.showFormError();

      return;
    }

    self.loading = true;

    var model = self.businessForm.value;

    if(self.isNew) model.customerBusinessDetailId = null;

    self.customerService.saveCustomerBusinessDetails(model).subscribe((resp: any) => {
      if(resp == null) {
        self.notificationService.showPostError();
      }
      else {
        if(self.customerService.selectedBusinessDetails.CustomerBusinessDetailId == '') {
          self.customerService.selectedCustomer.BusinessDetails.push(new CustomerBusinessDetailModel().deserialize(resp));
          self.customerService.selectedBusinessDetails = self.customerService.selectedCustomer.BusinessDetails[self.customerService.selectedCustomer.BusinessDetails.length - 1];
          self.notificationService.showToastrSuccess('Done!', 'Business Added.');
        }
        else {
          self.customerService.selectedCustomer.BusinessDetails.forEach((business: CustomerBusinessDetailModel) => {
            if(business.CustomerBusinessDetailId == resp.customerBusinessDetailId) {
              business.Name = resp.name;
              business.VatNumber = resp.vatNumber;
              self.customerService.selectedBusinessDetails = business;
              self.notificationService.showToastrSuccess('Done!', 'Business Updated.');
            }
          });
        }

        self.customerService.checkoutDisplayView = '';
        self.isNew = false;
        self.dismissModal();
      }
      self.loading = false;
    });
  }

  dismissModal() {
    var self = this;

    if(self.isNew) self.customerService.selectedBusinessDetails = null;

    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
