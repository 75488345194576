import { Deserializable } from "../deserialize.model";
import { ScheduleModel } from "./schedule.model";

export class VendorScheduleProfileLinkModel implements Deserializable  {
    ProfileScheduleLinkId: string = '';
    ProfileId: string = '';
    ProfileName: string = '';
    CoverPicture: string = '';

    deserialize(input: any) {
        var self = this;

        self.ProfileScheduleLinkId = input.profileScheduleLinkId;
        self.ProfileId = input.profileId;
        self.ProfileName = input.profileName;
        self.CoverPicture = input.coverPicture || '/assets/imgs/profile-photo.png';
        
        return self;
    }
}