import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TouchSequence } from 'selenium-webdriver';
import { CustomerAddressModel } from 'src/app/models/customer/customer.address.model';
import { CustomerContactDetailModel } from 'src/app/models/customer/customer.contact.detail.model';
import { PaymentTypeModel } from 'src/app/models/customer/customer.payment.type.model';
import { OrderItemModel } from 'src/app/models/order/order.item.model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  loading: boolean = false;
  displayView: string = '';
  
  showContacts: boolean = false;
  showAddresses: boolean = false;
  showPaymentTypes: boolean = false;
  requiresDelivery: boolean = false;
  @Output() checkoutEvent = new EventEmitter<boolean>();

  constructor(public orderService: OrderService, public paymentService: PaymentService, public customerService: CustomerService,
    public formBuilder: FormBuilder, public notificationService: NotificationService, public authService: AuthService) {
    var self = this;
  }

  ngOnInit() {
    var self = this;

    self.paymentService.populatePaymentTypes();
    self.validateOrder();

    self.requiresDelivery = false;
    self.orderService.orderItems.forEach((item: OrderItemModel) => {
      if(item.RequiresDelivery == true) self.requiresDelivery = true;
    });
    
    if(self.customerService.selectedCustomer.ContactDetails.length == 1) {
      self.selectContact(self.customerService.selectedCustomer.ContactDetails[0]);
    }
  }

  selectPaymentType(paymentType: PaymentTypeModel) {
    var self = this;
    self.customerService.selectedPaymentType = paymentType;
    self.showPaymentTypes = false;

    self.validateOrder();
  }

  selectContact(contact: CustomerContactDetailModel) {
    var self = this;

    self.customerService.selectedContactDetails = contact;
    self.showContacts = false;

    self.validateOrder();
  }

  selectAddress(address: CustomerAddressModel) {
    var self = this;

    self.customerService.selectedAddress = address;
    self.showAddresses = false;

    self.validateOrder();
  }

  contactPopVisible: boolean = false;

  showEditContact(contact: any) {
    var self = this;
    self.customerService.selectedContactDetails = contact;
    self.showContactPop();
  }
  
  hideContactPop() {
    var self = this;

    self.customerService.selectedContactDetails = null;

    self.contactPopVisible = true;
    self.contactPopVisible = false;
  }

  showNewContact() {
    var self = this;

    self.customerService.selectedContactDetails = null;
    self.showContactPop();
  }

  showContactPop() {
    var self = this;

    self.contactPopVisible = false;
    self.contactPopVisible = true;
  }

  showAddContactView() {
    var self = this;

    self.displayView = 'contacts';

    if(self.customerService.selectedCustomer.ContactDetails.length == 0) {
      self.showNewContact();
    }
  }

  showAddAddressView() {
    var self = this;

    self.displayView = 'addresses';

    if(self.customerService.selectedCustomer.Addresses.length == 0) {
      self.showNewAddress();
    }
  }

  orderValid:boolean = true;
  validateOrder() {
    var self = this;

    self.displayView = '';

    self.orderValid = self.customerService.selectedContactDetails != null;
    if(!self.orderValid) return;
    self.orderValid = self.customerService.selectedPaymentType != null;
    if(!self.orderValid) return;
    if(self.requiresDelivery && !self.isPickup) self.orderValid = self.customerService.selectedAddress != null;
  }

  orderName: string = '';
  orderNotes: string = '';
  isPickup: boolean = false;

  saveOrder() {
    var self = this;

    var deliveryId = '';

    if(!self.isPickup && self.customerService.selectedAddress != null) {
      deliveryId = self.customerService.selectedAddress.CustomerAddressId;
    }

    var model = {
      CustomerBusinessDetailId: '', //self.customerService.businessDetails == null ? '' : self.customerService.businessDetails.CustomerBusinessDetailId,
      CustomerContactDetailId: self.customerService.selectedContactDetails.CustomerContactDetailId,
      CustomerAddressId: deliveryId,
      Name: self.orderName,
      Notes: self.orderNotes,
      PaymentTypeId: self.customerService.selectedPaymentType.PaymentTypeId,
      Email: self.authService.profile.EmailAddress
    };

    self.orderService.placeOrder(model).subscribe((resp: any) => {
      if(resp == null) {
        self.notificationService.showPostError();
      }
      else {
        self.notificationService.showToastrSuccess('Done!', 'Order placed successfully!');
        self.orderService.populateBasket();
        if(resp.length > 0) {
          self.orderService.populateOrder(resp[0]);
        }
        
        self.checkoutEvent.next(true);
      }
    });
  }

  addressPopVisible: boolean = false;

  makePickup() {
    var self = this;

    self.customerService.selectedAddress = null;
    self.isPickup = true;
    self.validateOrder();
    self.displayView = '';
  }

  showNewAddress() {
    var self = this;

    self.customerService.selectedAddress = null;
    self.showAddressPop();
  }

  showEditAddress(address: any) {
    var self = this;
    self.customerService.selectedAddress = address;
    self.showAddressPop();
  }

  showAddressPop() {
    var self = this;

    self.addressPopVisible = false;
    self.addressPopVisible = true;
  }

  hideAddressPop() {
    var self = this;

    self.customerService.selectedContactDetails = null;

    self.addressPopVisible = true;
    self.addressPopVisible = false;
  }
}
