import { Component, Input, OnInit } from '@angular/core';
import { CustomerAddressModel } from 'src/app/models/customer/customer.address.model';

@Component({
  selector: 'app-order-delivery-tab',
  templateUrl: './order-delivery-tab.component.html',
  styleUrls: ['./order-delivery-tab.component.scss'],
})
export class OrderDeliveryTabComponent implements OnInit {
  @Input() inputDetails : CustomerAddressModel = new CustomerAddressModel();

  constructor() { }

  ngOnInit() {}

}
