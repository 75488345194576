import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocationModel } from '../models/locations/location.model';
import { environment } from 'src/environments/environment';
import { LocationMenuModel } from '../models/locations/location.menu.model';
import { LocationAccessModel } from '../models/users/location.access.model';
import { ScheduleModel } from '../models/schedule/schedule.model';
import { ScheduleDayModel } from '../models/schedule/schedule.day.model';
import { ProfileMediaModel } from '../models/media/media.model';
import { MediaAlbumModel } from '../models/media/media.album.model';

const routes = {
};

@Injectable()
export class MediaService {
    loading: boolean = false;
    locationId: string = environment.vendorId;

    locationMediaAlbums: MediaAlbumModel[] = [];
    locationProfileMedia: ProfileMediaModel[] = [];
    selectedProfileMedia: ProfileMediaModel;
    selectedMediaAlbum: MediaAlbumModel;

    constructor(private httpClient: HttpClient) {
        var self = this;
    }

    loadLocationMediaAlbums() {
      var self = this;

      self.locationMediaAlbums = [];
      self.loading = true;

      self.getMediaAlbums().subscribe((resp: any) => {
        if(resp != null) {
          resp.forEach((item: any) => {
            self.locationMediaAlbums.push(new MediaAlbumModel().deserialize(item));
          });
        }

        self.loading = false;
      });
    }

    loadLocationMedia() {
      var self = this;
      
      self.locationProfileMedia = [];
      self.loading = true;

      self.getLocationMedia().subscribe((resp: any) => {
        if(resp != null) {
          resp.forEach((item: any) => {
            self.locationProfileMedia.push(new ProfileMediaModel().deserialize(item));
          });
        }

        self.loading = false;
      });
    }

    getLocationMedia() {
      var self = this;

      return self.httpClient.get(`${environment.apiUrl.ecomm}/api/ProfileMedia/GetLocationMedia/` + self.locationId).pipe(
        catchError(e => {
        // self.showAlert(e.error.msg);
        throw new Error(e);
        }));
    }

    getMyMedia() {
      var self = this;

      return self.httpClient.get(`${environment.apiUrl.ecomm}/api/ProfileMedia/GetMyMedia?id=` + self.locationId).pipe(
        catchError(e => {
        // self.showAlert(e.error.msg);
        throw new Error(e);
        }));
    }

    getMyMediaAlbums() {
      var self = this;

      return self.httpClient.get(`${environment.apiUrl.ecomm}/api/ProfileMediaAlbum/GetMyMedia?id=` + self.locationId).pipe(
        catchError(e => {
        // self.showAlert(e.error.msg);
        throw new Error(e);
        }));
    }

    // save profile media
    saveProfileMedia(context: any) :  Observable<any> {
        return this.httpClient
        .post(`${environment.apiUrl.ecomm}/api/ProfileMedia/SaveMedia`, context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
                })
            );
    }

    // save media album
    saveMediaAlbum(context: any) :  Observable<any> {
      return this.httpClient
      .post(`${environment.apiUrl.ecomm}/api/ProfileMediaAlbum/SaveMediaAlbum`, context)
      .pipe(
          map((response: any) => response),
          catchError(e => {
              // self.showAlert(e.error.msg);
              throw new Error(e);
              })
          );
  }

  // get media albums for location
  getMediaAlbums() :  Observable<any> {
    var self = this;

    return this.httpClient
    .get(`${environment.apiUrl.ecomm}/api/ProfileMediaAlbum/GetLocationMediaAlbums?locationId=` + self.locationId)
    .pipe(
        map((response: any) => response),
        catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
  }

  // get media albums for location for non subscribers
  getLocationMediaAlbumsForNonSubscribers() :  Observable<any> {
    var self = this;

    return this.httpClient
    .get(`${environment.apiUrl.ecomm}/api/ProfileMediaAlbum/GetLocationMediaAlbumsForNonSubscribers?locationId=` + self.locationId)
    .pipe(
        map((response: any) => response),
        catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
  }

  // get media album
  getMediaAlbum(albumId: string) :  Observable<any> {
    return this.httpClient
    .get(`${environment.apiUrl.ecomm}/api/ProfileMediaAlbum/GetLocationMediaAlbum/` + albumId)
    .pipe(
        map((response: any) => response),
        catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
  }

  // notify profile of media
  notifyProfile(context: any) :  Observable<any> {
    return this.httpClient
    .post(`${environment.apiUrl.ecomm}/api/ProfileMedia/NotifyProfile`, context)
    .pipe(
        map((response: any) => response),
        catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
  }

  notifyAlbumProfiles(context: any) :  Observable<any> {
    console.log(context);
    return this.httpClient
    .post(`${environment.apiUrl.ecomm}/api/ProfileMediaAlbum/NotifyUsers`, context)
    .pipe(
        map((response: any) => response),
        catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
  }
}