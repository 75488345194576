import { Deserializable } from "../deserialize.model";

export class OrderRequestModel implements Deserializable  {
    UpdateId: string = '';
    OrderId: string = '';
    
    LocationId: string = '';
    LocationName: string = '';

    Name: string = '';

    JsonData: string = '';
    Reason: string = '';

    StatusId: number = 0;
    Status: string = '';

    OrderReference: string = '';
    DateCreated: string = '';

    deserialize(input: any) {
        var self = this;

        self.UpdateId = input.updateId || '';
        self.OrderId = input.orderId || '';

        self.LocationId = input.locationId || '';
        self.LocationName = input.locationName || '';

        self.Name = input.name || '';

        self.JsonData = input.jsonData || '';
        self.Reason = input.reason || '';

        self.Status = input.status;
        self.StatusId = input.statusId;

        self.OrderReference = input.orderReference;
        self.DateCreated = input.dateCreated;

        return self;
    }
}