import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PopularLinksModel } from '../models/core/popular.links.model';
import { LocationService } from './location.service';
import { LocationMenuModel } from '../models/locations/location.menu.model';

const routes = {
    //get: (id: string) => environment.apiUrl.photos + `/Sale/Get?id=`,
};

@Injectable()
export class CoreService {
    websiteName: string = environment.websiteName;
    websiteLogo: string = environment.websiteLogo;
    contactDetailTypes: any = [];
    popularLinks: PopularLinksModel[] = [];
    bannerPosition: string = 'banner-left';
    intervalTimer: any = null;
    activeMenu: string = '';

    slideOpts = {
        speed: 400,
        slidesPerView: 5,
        // Responsive breakpoints
        breakpoints: {
          // when window width is <= 320px
          320: {
            slidesPerView: 1,
          },
          // when window width is <= 480px
          480: {
            slidesPerView: 2,
          },
          // when window width is <= 640px
          640: {
            slidesPerView: 5,
          }
        }
      };

    constructor(private httpClient: HttpClient, public locationService: LocationService) {
        var self = this;

        self.populateContactDetailTypes();
        // self.populatePopularLinks();

        self.intervalTimer = setInterval(function() {
            self.swopBanner();
        }, 5000);
    }

    populateContactDetailTypes() {
        var self = this;

        self.contactDetailTypes = [];

        self.contactDetailTypes.push({
            detailTypeId: 1,
            detailType: 'Phone',
            iconClass: 'call'
        });

        self.contactDetailTypes.push({
            detailTypeId: 2,
            detailType: 'Email',
            iconClass: 'mail'
        });

        self.contactDetailTypes.push({
            detailTypeId: 3,
            detailType: 'Website',
            iconClass: 'globe'
        });

        self.contactDetailTypes.push({
            detailTypeId: 4,
            detailType: 'Facebook',
            iconClass: 'logo-facebook'
        });

        self.contactDetailTypes.push({
            detailTypeId: 5,
            detailType: 'Twitter',
            iconClass: 'logo-twitter'
        });

        self.contactDetailTypes.push({
            detailTypeId: 6,
            detailType: 'Instagram',
            iconClass: 'logo-instagram'
        });

        self.contactDetailTypes.push({
            detailTypeId: 7,
            detailType: 'WhatsApp',
            iconClass: 'logo-whatsapp'
        });
    }

    populatePopularLinks() {
        var self = this;

        self.popularLinks = [];

        if(self.locationService.selectedLocation != null && self.locationService.selectedLocation.LocationMenu.length > 0) {
            self.locationService.selectedLocation.LocationMenu.forEach((menu: LocationMenuModel) => {
                self.popularLinks.push(new PopularLinksModel().deserialize({
                    menuId: menu.LocationMenuId,
                    linkText: menu.Name
                }));
            });
        }
    }

    swopBanner() {
        var self = this;

        if(self.bannerPosition == 'banner-left') {
            self.bannerPosition = 'banner-right';
        } else {
            self.bannerPosition = 'banner-left';
        }
    }

    menuClass: string = 'hidden-menu';
    hideShowMenu() {
        var self = this;

        if(self.menuClass == 'hidden-menu') {
            self.menuClass = 'visible-menu';
        }
        else {
            self.hideMenu();
        }
    }

    hideMenu() {
        var self = this;

        self.menuClass = 'hidden-menu';
    }
}