import { Deserializable } from '../deserialize.model';

export class LocationContactDetailModel implements Deserializable  {
    LocationId: string = '';
    ContactDetailId: string = '';
    Details: string = '';
    DetailTypeId: number = 1;
    ContactDetailType: string = '';
    ContactDetailIcon: string = '';
    ContactDetailLink: string = '';
    Delete: boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.LocationId = input.locationId;
        self.ContactDetailId = input.contactDetailId;
        self.Details = input.details;
        self.DetailTypeId = input.detailTypeId;

        if(self.Details == null) self.Details = '';

        // self.ContactDetailType = input.contactDetailType;
        self.setLinkAndIcon();
        
        return self;
    }

    setLinkAndIcon() {
        var self = this;

        switch(self.DetailTypeId) {
            case 1:
                {
                    self.ContactDetailIcon = 'call';
                    self.ContactDetailLink = 'tel:' + self.Details;
                    break;
                }
            case 2:
                {
                    self.ContactDetailIcon = 'mail';
                    self.ContactDetailLink = 'mailto:' + self.Details;
                    break;
                }
            case 3:
                {
                    self.ContactDetailIcon = 'globe';
                    if(self.Details.indexOf('http://') < 0) {
                        self.ContactDetailLink = 'http://' + self.Details;;
                    }
                    else {
                        self.ContactDetailLink = self.Details;
                    }
                    
                    break;
                }
            case 4:
                {
                    self.ContactDetailIcon = 'logo-facebook';
                    self.ContactDetailLink = 'https://facebook.com/' + self.Details;
                    break;
                }
            case 5:
                {
                    self.ContactDetailIcon = 'logo-twitter';
                    self.ContactDetailLink =  'https://twitter.com/' + self.Details;
                    break;
                }
            case 6:
                {
                    self.ContactDetailIcon = 'logo-instagram';
                    if(self.Details.indexOf('instagram.com') < 0) {
                        self.ContactDetailLink = 'https://instagram.com/' + self.Details.replace('@', '');
                    }
                    else {
                        self.ContactDetailLink = self.Details;
                    }
                    
                    break;
                }
            case 7:
                {
                    self.ContactDetailIcon = 'logo-whatsapp';
                    self.ContactDetailLink = 'https://wa.me/' + self.Details;
                    
                    break;
                }
        }
    }

    update(input: any) {
        if(input == null) return;

        var self = this;

        self.Details = input.details;
        self.DetailTypeId = input.detailTypeId;
        
        self.setLinkAndIcon();
    }
}