import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaymentTypeModel } from '../models/customer/customer.payment.type.model';
import { PaymentModel } from '../models/order/payment.model';

const routes = {
    savePayment: () => environment.apiUrl.ecomm + `/api/Payment/SavePayment`,
    payFast: () => environment.externalSites.payFast.baseUrl,
    eftComplete: () => environment.apiUrl.ecomm + `/api/EFTPayment/PaymentOutcomeComplete`
};

@Injectable()
export class PaymentService {
    paymentTypes: PaymentTypeModel[] = [];
    selectedPaymentType: PaymentTypeModel;

    selectedPayment: PaymentModel = new PaymentModel();

    constructor(private httpClient: HttpClient) {
        var self = this;
    }

    // get payment types
    getPaymentTypes() {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/GetPaymentTypes`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // save payment
    savePayment(context: any) :  Observable<any> {
        return this.httpClient
        .post(routes.savePayment(), context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
            })
        );
    }

    // submit to PayFast
    submitPayFast(context: any) :  Observable<any> {
        return this.httpClient
        .post(routes.payFast(), context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
            })
        );
    }

    // submit to complete eft
    completeEFTPayment(context: any) :  Observable<any> {
        return this.httpClient
        .post(routes.eftComplete(), context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
            })
        );
    }

    paymentModalVisible: boolean = false;

    showPaymentModal() {
        var self = this;

        self.paymentModalVisible = false;
        self.paymentModalVisible = true;
    }
    hidePaymentModal() {
        var self = this;
        
        self.paymentModalVisible = true;
        self.paymentModalVisible = false;
    }

    populatePaymentTypes() {
        var self = this;
    
        self.getPaymentTypes().subscribe((resp: any) => {
          self.paymentTypes = [];
          if(resp != null) {
            resp.forEach((paymentType: any) => {
              self.paymentTypes.push(new PaymentTypeModel().deserialize(paymentType));
            });
          }
        });
      }
}