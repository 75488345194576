import { Deserializable } from "../deserialize.model";

export class PopularLinksModel implements Deserializable  {
    MenuId: string = '';
    TextDisplay: string = '';
    ButtonText: string = '';
    LinkText: string = '';
  
    deserialize(inputModel: any) {
        var self = this;

        self.MenuId = inputModel.menuId;
        self.TextDisplay = inputModel.textDisplay;
        self.ButtonText = inputModel.buttonText;
        self.LinkText = inputModel.linkText;

        return self;
    }
}