import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocationModel } from '../models/locations/location.model';
import { environment } from 'src/environments/environment';
import { LocationMenuModel } from '../models/locations/location.menu.model';
import { LocationAccessModel } from '../models/users/location.access.model';
import { ScheduleModel } from '../models/schedule/schedule.model';
import { ScheduleDayModel } from '../models/schedule/schedule.day.model';

const routes = {
};

@Injectable()
export class ScheduleService {
    loading: boolean = false;
    locationId: string = environment.vendorId;
    userPermissions: LocationAccessModel[] = [];
    selectedDay: ScheduleDayModel;
    selectedSchedule: ScheduleModel;
    days: any[] = [];
    year: number = 1;
    month: number = 1;

    constructor(private httpClient: HttpClient) {
        var self = this;
    }

    loadSchedule() {
        var self = this;
    
        self.loading = true;
    
        self.filterSchedule(self.month, self.year).subscribe((resp: any) => {
          if(resp != null && resp.outcomeData != null) {
            self.populateSchedule(resp.outcomeData);
          }
        });
      }
    
      populateSchedule(inputData: any) {
        var self = this;
    
        self.days = [];
    
        if(inputData != null && inputData.length > 0) {
          inputData.forEach((item: any) => {
            self.days.push(new ScheduleDayModel().deserialize(item));
          });
    
          self.initViewData();
        }
      }
    
      initViewData() {
        var self = this;
    
        var startDay = 1;
        var end = 0;
    
        if(self.days.length > 0) {
          startDay = self.days[0].DayOfWeek + 1;
          end = self.days[self.days.length - 1].DayOfMonth;
    
          if(startDay == 1) startDay = 7;
        }
    
        for(var count = 0; count < end; count++) {
          if(self.days.length == count || self.days[count].DayOfMonth > (count + 1)) {
            self.days.splice(count,0, new ScheduleDayModel().deserialize({
              dayOfWeek: startDay,
              dayOfMonth: count + 1
            }));
    
            startDay++;
    
            if(startDay == 8) startDay = 1;
          }
        }
    
        if(self.days[0].DayOfWeek > 1 || self.days[0].DayOfWeek == 0) {
          var dayOfWeek = self.days[0].DayOfWeek;
          if(dayOfWeek == 0) dayOfWeek = 7;
          for (var count = 1; count  < dayOfWeek; count++) {
            self.days.splice(0,0, new ScheduleDayModel());
          }
        }
    
        self.loading = false;
      }

    // get location schedule
    getLocationSchedule() {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/VendorSchedule/GetLocationSchedule/` + self.locationId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get menu item schedule
    getMenuItemSchedule(menuItemId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/VendorSchedule/GetMenuItemSchedule/` + menuItemId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get menu item schedule
    get(scheduleId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/VendorSchedule/Get/` + scheduleId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get employee schedule
    getEmployeeSchedule(employeeId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/VendorSchedule/GetEmployeeSchedule/` + employeeId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // get my schedule
    getMySchedule(locationId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/VendorSchedule/getMySchedule/` + locationId).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // filter schedule
    filterSchedule(month: number, year: number) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/VendorSchedule/Filter?id=` + self.locationId + `&month=` + month + `&year=` + year).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // save schedule
    saveSchedule(context: any) :  Observable<any> {
        return this.httpClient
        .post(`${environment.apiUrl.ecomm}/api/VendorSchedule/Save`, context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
                })
            );
    }

    // Save Multi Schedule
    saveMulti(model: any) {
      var self = this;
  
      return self.httpClient.post(`${environment.apiUrl.ecomm}/api/VendorSchedule/SaveMulti`, model).pipe(
        catchError(e => {
          //this.showAlert(e.error.msg);
          throw new Error(e);
        })
      );
  }

    // assign employee
    assignEmployee(context: any) :  Observable<any> {
        return this.httpClient
        .post(`${environment.apiUrl.ecomm}/api/VendorSchedule/AssignEmployee`, context)
        .pipe(
            map((response: any) => response),
            catchError(e => {
                // self.showAlert(e.error.msg);
                throw new Error(e);
                })
            );
    }

    // assign yourself to the schedule
    addMe(context: any) :  Observable<any> {
      return this.httpClient
      .post(`${environment.apiUrl.ecomm}/api/VendorSchedule/AssignMe`, context)
      .pipe(
          map((response: any) => response),
          catchError(e => {
              // self.showAlert(e.error.msg);
              throw new Error(e);
              })
          );
  }
}