import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { LocationMenuItemModel } from 'src/app/models/locations/location.menu.item.model';
import { LocationMenuModel } from 'src/app/models/locations/location.menu.model';
import { ScheduleModel } from 'src/app/models/schedule/schedule.model';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ScheduleService } from 'src/app/services/schedule.service';

@Component({
  selector: 'app-schedule-edit',
  templateUrl: './schedule-edit.page.html',
  styleUrls: ['./schedule-edit.page.scss'],
})
export class ScheduleEditPage implements OnInit {
  loading: boolean = true;
  startDate: string = '';
  endDate: string = '';
  maxBookings: number = 1;
  maxInstructors: number = 1;
  inputDay: string = '';
  inputMonth: string = '';
  inputYear: string = '';
  inputSchedule: ScheduleModel = null;
  inputLocationId: string = '';

  scheduleView: string = '';
  locationMenu: LocationMenuModel[] = [];
  selectedMenu: LocationMenuModel = null;
  selectedMenuId: string = '';
  selectedMenuItem: LocationMenuItemModel = null;

  isNew: boolean = false;

  scheduleForm: FormGroup;

  constructor(public modalController: ModalController, public locationService: LocationService, public scheduleService: ScheduleService,
    private formBuilder: FormBuilder, public notificationService: NotificationService) { }

  ngOnInit() {
    var self = this;

    if(self.inputSchedule != null) {
      self.startDate = self.inputSchedule.DateStart.toString();
      self.endDate = self.inputSchedule.DateEnd.toString();
      self.maxBookings = self.inputSchedule.MaxBookings;
      self.maxInstructors = self.inputSchedule.MaxInstructors;
      self.scheduleService.selectedSchedule = self.inputSchedule;
    }
    else if(self.inputDay != '' && self.inputMonth != '' && self.inputYear != '') {
      self.startDate = self.setNumber(self.inputYear, 4) + '-' + self.setNumber(self.inputMonth, 2) + '-' + self.setNumber(self.inputDay, 2) + 'T06:00:00+02:00';
      self.endDate = self.setNumber(self.inputYear, 4) + '-' + self.setNumber(self.inputMonth, 2) + '-' + self.setNumber(self.inputDay, 2) + 'T07:00:00+02:00';
      self.isNew = true;
      self.scheduleService.selectedSchedule = null;
    }

    self.preparescheduleForm();

    // self.populateMenu();
    self.populateLocationMenus();
  }

  preparescheduleForm() {
    var self = this;

    if(self.scheduleService.selectedSchedule == null) {
      self.scheduleService.selectedSchedule = new ScheduleModel();
      self.scheduleService.selectedSchedule.LocationId = self.inputLocationId;
    }

    self.scheduleForm = self.formBuilder.group({
      vendorScheduleId: [self.scheduleService.selectedSchedule.VendorScheduleId],
      LocationMenuItemId: [self.scheduleService.selectedSchedule.LocationMenuItemId],
      locationId: [self.scheduleService.selectedSchedule.LocationId],
      dateStart: [self.scheduleService.selectedSchedule.DateStart],
      dateEnd: [self.scheduleService.selectedSchedule.DateEnd],
      published: [self.scheduleService.selectedSchedule.Published],
      delete: [false]
    });
  }

  // setup the view for creating a schedule
  housekeepingForSchedule() {
    var self = this;
    
    self.selectedMenu = null;
    self.selectedMenuItem = null;
    self.scheduleService.selectedSchedule = new ScheduleModel();
    self.startDate = '';
    self.endDate = '';
    self.scheduleView = '';
  }

  // setup the view to select a menu item
  housekeepingForMenuItem() {
    var self = this;

    self.selectedMenuItem = null;
  }

  // setup the view for setting a schedule start date
  housekeepingStartDate() {
    var self = this;

    self.startDate = '';
    self.scheduleView = 'startDate';
  }

  // setup the view for setting a schedule end date
  housekeepingEndDate() {
    var self = this;

    self.endDate = '';
    self.scheduleView = 'endDate';
  }

  startChanged(event: any) {
    //console.log(event);
  }

  setNumber(inputNumber: string, inputLength: number) {
    return parseInt(inputNumber).toLocaleString('en-US', {
      minimumIntegerDigits: inputLength,
      useGrouping: false
    });
  }

  populateLocationMenus() {
    var self = this;

    self.loading = true;
    self.locationService.getLocationMenu(self.inputLocationId).subscribe((resp: any) => {
      if(resp != null && resp.length > 0) {
        resp.forEach((menu: any) => {
          self.locationMenu.push(new LocationMenuModel().deserialize(menu));
        });
      }
      self.loading = false;
    });
  }

  populateMenu() {
    var self = this;

    self.loading = true;

    self.selectedMenuItem = null;

    self.locationService.getMenu(self.selectedMenuId).subscribe((resp: any) => {
      if(resp != null) {
        self.selectedMenu = new LocationMenuModel().deserialize(resp);

        if(self.scheduleService.selectedSchedule != null && self.scheduleService.selectedSchedule.LocationMenuItemId != '' && self.selectedMenu.Items != null) {
          self.selectedMenu.Items.forEach((item : any) =>{
            if(self.scheduleService.selectedSchedule.LocationMenuItemId == item.LocationMenuItemId) {
              self.selectMenuItem(item);
            }
          });
        }
      }

      self.loading = false;
    });
  }

  selectMenu(menu: LocationMenuModel) {
    var self = this;

    self.selectedMenuItem = null;
    
    self.locationService.getMenu(menu.LocationMenuId).subscribe((resp: any) => {
      self.selectedMenu = menu;

      if(resp != null) {
        self.selectedMenu = new LocationMenuModel().deserialize(resp);
      }
    });
  }

  selectMenuItem(menuItem: LocationMenuItemModel) {
    var self = this;

    self.selectedMenuItem = menuItem;
    self.scheduleService.selectedSchedule.LocationMenuItemId = menuItem.LocationMenuItemId;
    self.scheduleView = '';
  }

  clearStartDate() {
    var self = this;
    self.scheduleView = '';
  }

  saveStartDate() {
    var self = this;
    self.scheduleView = '';
  }

  clearEndDate() {
    var self = this;
    self.scheduleView = '';
  }

  saveEndDate() {
    var self = this;
    self.scheduleView = '';
  }

  saveSchedule() {
    var self = this;

    var startDate = new Date(self.startDate);
    startDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60 * 1000));

    var endDate = new Date(self.endDate);
    endDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60 * 1000));

    var model = {
      vendorScheduleId: self.scheduleService.selectedSchedule.VendorScheduleId,
      LocationMenuItemId: self.scheduleService.selectedSchedule.LocationMenuItemId,
      locationId: self.inputLocationId,
      dateStart: startDate,
      dateEnd: endDate,
      published: true,
      maxBookings: self.maxBookings,
      maxInstructors: self.maxInstructors
    };

    if(model.vendorScheduleId == '') model.vendorScheduleId = null;

    self.scheduleService.saveSchedule(model).subscribe((resp: any) => {
      if(resp != null && resp.outcomeData != null) {
        self.scheduleService.selectedSchedule = new ScheduleModel().deserialize(resp.outcomeData);
        self.scheduleService.loadSchedule();

        self.notificationService.showToastrSuccess('Done!', 'Schedule Saved.');
        self.dismissModal();
      }
      else {
        self.notificationService.showToastrError('Oops', 'An error occured. Please try again.');
      }
    });
  }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
