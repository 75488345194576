import { Deserializable } from "../deserialize.model";
import { ProfileModel } from "../profile/profile.model";

export class LocationAccessModel implements Deserializable  {
    LocationId: string = '';
    ProfileId: string = '';
    ProfileName: string = '';
    CoverPicture: string = '';
    UserPermissionLevel: number = 0;
    Delete: boolean = false;
    Hidden: boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.LocationId = input.locationId || '';
        self.ProfileId = input.profileId || '';
        self.ProfileName = input.profileName || '';
        self.CoverPicture = input.coverPicture || '/assets/imgs/menu-logo.png';
        self.UserPermissionLevel = input.userPermissionLevel || 0;
        self.Delete = input.delete || '';

        return self;
    }
  }
  