import { Pipe } from "@angular/core";

@Pipe({
name: 'icon'
})

export class IconPipe {
    transform(value: number) {
        switch(value) {
            case 21: {
                return 'cash-outline';
            }
            case 22: {
                return 'warning-outline';
            }
            case 20: {
                return 'card';
            }
            case 3: {
                return 'trash';
            }
            case 5: {
                return 'arrow-undo';
            }
            case 6: {
                return 'information';
            }
            case 8: {
                return 'information';
            }
            case 19: {
                return 'checkmark-done';
            }
            case 40: {
                return 'build';
            }
            case 50: {
                return 'rocket';
            }
        }
        return 'warning-outline';
    }
}