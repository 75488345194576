import { BasketExtraInfoModel } from "../basket/basket.extra.info.model";
import { Deserializable } from "../deserialize.model";

export class OrderItemModel implements Deserializable  {
    OrderItemId: string = '';
    BasketItemId: string = '';
    AlbumId: string = '';
    PhotoId: string = '';
    DirectoryId: string = '';
    LocationMenuItemId: string = '';
    
    LocationId: string = '';
    LocationName: string = '';
    Name: string = '';
    PhotoURL: string = '';

    JsonData: string = '';

    Quantity: number = 0;
    MaxItems: number = 0;
    MinItems: number = 0;

    Price: number = 0;
    UnitPrice: number = 0;
    DiscountTypeId: number = 0;
    DiscountValue: number = 0;
    Total: number = 0;
    Status: string = '';
    PriceName: string = '';

    OrderItemCancelled: boolean = false;
    OrderItemReturned: boolean = false;
    OrderItemDelivered: boolean = false;
    OrderItemBeingDelivered: boolean = false;
    RequiresDelivery: boolean = false;
    ShowExtraInfo: boolean = false;
    ExtraInfo: BasketExtraInfoModel[] = [];

    VisitURL: string = '';
  
    deserialize(input: any) {
        var self = this;
        self.OrderItemId = input.orderItemId || '';
        self.BasketItemId = input.basketItemId || '';
        self.AlbumId = input.albumId || '';
        self.PhotoId = input.photoId || '';
        self.DirectoryId = input.directoryId || '';
        self.LocationMenuItemId = input.locationMenuItemId || '';

        self.LocationId = input.locationId || '';
        self.LocationName = input.locationName || '';

        self.Name = input.name || '';
        self.PhotoURL = input.photoURL || '';

        self.JsonData = input.jsonData || '';
        if(self.JsonData != null && self.JsonData != '') {
            self.ExtraInfo = JSON.parse(self.JsonData);
        }

        self.Quantity = input.quantity || 1;
        self.MaxItems = input.maximumQuantity;
        self.MinItems = input.minimumQuantity;

        self.Price = input.price || 0;
        self.UnitPrice = input.unitPrice || 0;
        self.DiscountTypeId = input.discountTypeId;
        self.DiscountValue = input.discountValue;
        self.Total = self.Quantity * self.Price;
        self.Status = input.status;
        self.PriceName = input.priceName;

        self.OrderItemCancelled = input.orderItemCancelled || false;
        self.OrderItemReturned = input.orderItemReturned || false;
        self.OrderItemDelivered = input.orderItemDelivered || false;
        self.OrderItemBeingDelivered = input.orderItemBeingDelivered || false;
        self.RequiresDelivery = input.requiresDelivery || false;

        self.configureMenuLink();

        return self;
    }

    configureMenuLink() {
        var self = this;

        // if going to a directory
        if(self.DirectoryId != '') {
            self.VisitURL = '/Product/' + self.DirectoryId;
        }
        // if going to a product
        else if(self.LocationMenuItemId != '') {
            self.VisitURL = '/menu-item/' + self.LocationMenuItemId;
        }
        // if going to an ablum and photo
        else if(self.AlbumId != '') {
            self.VisitURL = '/album/' + self.AlbumId;

            if(self.PhotoId != '') {
                self.VisitURL += '/' + self.PhotoId;
            }
        }
    }
}