import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CustomerContactDetailModel } from 'src/app/models/customer/customer.contact.detail.model';
import { CoreService } from 'src/app/services/core.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.page.html',
  styleUrls: ['./contact-details.page.scss'],
})
export class ContactDetailsPage implements OnInit {
  contactForm: FormGroup;
  loading: boolean = false;
  isNew: boolean = false;
  
  constructor(public formBuilder: FormBuilder, public modalController: ModalController,
    private coreService: CoreService, public customerService: CustomerService, public notificationService: NotificationService) {
    var self = this;

    self.prepareContactForm();
  }

  ngOnInit() {}

  prepareContactForm() {
    var self = this;

    if(self.customerService.selectedContactDetails == null) self.customerService.selectedContactDetails = new CustomerContactDetailModel();

    if(self.customerService.selectedContactDetails.CustomerContactDetailId == '') self.isNew = true;

    self.contactForm = self.formBuilder.group({
      customerContactDetailId: [self.customerService.selectedContactDetails.CustomerContactDetailId],
      name: [self.customerService.selectedContactDetails.Name],
      contactPerson: [self.customerService.selectedContactDetails.ContactPerson, Validators.required],
      detail: [self.customerService.selectedContactDetails.Detail, Validators.required],
      contactTypeId: [self.customerService.selectedContactDetails.ContactTypeId, Validators.required]
    });
  }

  saveContact() {
    var self = this;

    if(!self.contactForm.valid) {
      self.notificationService.showFormError();

      return;
    }

    self.loading = true;

    var model = self.contactForm.value;

    if(self.isNew) model.customerContactDetailId = null;

    self.customerService.saveCustomerContactDetails(model).subscribe((resp: any) => {
      if(resp == null) {
        self.notificationService.showPostError();
      }
      else {
        if(self.customerService.selectedContactDetails.CustomerContactDetailId == '') {
          self.customerService.selectedCustomer.ContactDetails.push(new CustomerContactDetailModel().deserialize(resp));
          self.customerService.selectedContactDetails = self.customerService.selectedCustomer.ContactDetails[self.customerService.selectedCustomer.ContactDetails.length - 1];
          self.notificationService.showToastrSuccess('Done!', 'Contact Added.');
        }
        else {
          self.customerService.selectedCustomer.ContactDetails.forEach((contact: CustomerContactDetailModel) => {
            if(contact.CustomerContactDetailId == resp.customerContactDetailId) {
              contact.Detail = resp.detail;
              contact.Name = resp.name;
              contact.ContactPerson = resp.contactPerson;
              self.customerService.selectedContactDetails = contact;
              self.notificationService.showToastrSuccess('Done!', 'Contact Updated.');
            }
          });
        }

        self.customerService.checkoutDisplayView = '';
        self.isNew = false;
        self.dismissModal();
      }
      self.loading = false;
    });
  }

  dismissModal() {
    var self = this;

    if(self.isNew) self.customerService.selectedContactDetails = null;

    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
