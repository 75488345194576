import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { environment } from 'src/environments/environment';
import { PushModel } from '../models/comms/push.model';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  public data: PushModel;
  public hubConnection: signalR.HubConnection;
  public pushServicePrefix: string = 'IUwhatsIN';

  constructor(){}

  public startConnection = () => {
    var self = this;

    self.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(environment.apiUrl.pushHubConnect)
    .build();
    
    self.hubConnection
      .start()
      .then(() => {
        //console.log('Connection started');
      })
      .catch((err: any) => console.log('Error while starting connection: ' + err))
  }

  public pushListener = (userId: string) => {
    var self = this;

    self.hubConnection.on(self.pushServicePrefix + '-' + userId, (data: any) => {
      self.data = new PushModel().deserialize(data);
    });
  }
}