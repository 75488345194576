import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LocationMenuItemModel } from 'src/app/models/locations/location.menu.item.model';
import { MenuItemPopPage } from 'src/app/pages/menu-item-pop/menu-item-pop.page';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-menu-item-card',
  templateUrl: './menu-item-card.component.html',
  styleUrls: ['./menu-item-card.component.scss'],
})
export class MenuItemCardComponent implements OnInit {
  @Input() menuItem: LocationMenuItemModel = new LocationMenuItemModel();
  @Input() hideMenu: boolean = false;

  constructor(private router: Router, private authService: AuthService, public notificationService: NotificationService, public modalController: ModalController) { }

  ngOnInit() {}

  showBookNow: boolean = false;

  async showBookNowModal() {
    var self = this;

    const modal = await this.modalController.create({
      component: MenuItemPopPage,
      componentProps: { 
        inputMenuItem: self.menuItem
      }
    });

    return await modal.present();
  }

  hideBookNowModal() {
    var self = this;

    self.showBookNow = true;
    self.showBookNow = false;
  }

  showBasket() {
    var self = this; 

    self.hideBookNowModal();

    setTimeout(() => {
      self.router.navigate(['/tabs','basket']);
    }, 100);
  }
}
