import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProfileModel } from 'src/app/models/profile/profile.model';
import { VendorScheduleProfileLinkModel } from 'src/app/models/schedule/schedule.profile.link.model';
import { LocationAccessModel } from 'src/app/models/users/location.access.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-assign-user',
  templateUrl: './assign-user.page.html',
  styleUrls: ['./assign-user.page.scss'],
})
export class AssignUserPage implements OnInit {
  loading: boolean = false;
  searchText: string = '';
  displayView: string = '';
  users: LocationAccessModel[] = [];
  selectedUser: LocationAccessModel;

  constructor(public modalController: ModalController, public userService: UserService, public scheduleService: ScheduleService,
    public notificationService: NotificationService) { }

  ngOnInit() {
    var self = this;

    self.populatePermissions();
  }

  populatePermissions() {
    var self = this;

    self.loading = true;
    self.userService.getLocationPermissions().subscribe((resp: any) => {
      self.userService.userPermissions = [];
      if(resp != null && resp.length > 0) {
        resp.forEach((user: any) => {
          self.userService.userPermissions.push(new LocationAccessModel().deserialize(user));
        });
      }

      self.loading = false;
    });
  }

  showAddUser(user: LocationAccessModel) {
    var self = this;

    self.selectedUser = user;
    self.displayView = 'user';
  }

  searchForUsers() {
    var self = this;

    if(self.searchText != null) self.searchText = self.searchText.toLowerCase();

    self.userService.userPermissions.forEach((user: any) => {
      user.Hidden = user.ProfileName.toLowerCase().indexOf(self.searchText) < 0;
    });
  }

  cancelSave() {
    var self = this;
    self.displayView = '';
    self.selectedUser = null;
  }

  saveUser() {
    var self = this;

    var model = {
      profileId: self.selectedUser.ProfileId,
      vendorScheduleId: self.scheduleService.selectedSchedule.VendorScheduleId
    };

    self.scheduleService.assignEmployee(model).subscribe((resp: any) => {
      if(resp != null && resp.outcomeData != null) {
        self.notificationService.showToastrError('Done', 'User assigned.');
        self.scheduleService.selectedSchedule.ScheduleProflieLinks.push(new VendorScheduleProfileLinkModel().deserialize(resp.outcomeData));
        self.dismissModal();
      }
      else {
        self.notificationService.showToastrError('Oops', resp.outcomeMessage);
      }
    });
  }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  clearSearch() {
    var self = this;

    self.searchText = '';
  }
}
