import { Deserializable } from '../deserialize.model';

export class LocationMenuItemPhotoModel implements Deserializable  {
  LocationMenuItemPhotoId: string = '';
  LocationMenuItemId: string = '';
  PhotoUrl: string = '';
  FileName: string = '';
  Order: number = 0;

  deserialize(input: any) {
      var self = this;

      self.LocationMenuItemPhotoId = input.locationMenuItemPhotoId || '';
      self.LocationMenuItemId = input.locationMenuItemId || '';
      self.PhotoUrl = input.photoUrl || '';
      self.FileName = input.fileName || '';
      self.Order = input.order || 0;
      
      return self;
  }
}