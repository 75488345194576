import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CoreService } from './core.service';
import { environment } from 'src/environments/environment';

const routes = {
};

@Injectable()
export class SessionService {
    searchModel: any;
    tags: any[] = [];
    sessionId: string = '';

    showLoadingScreen: boolean = false;
    showCookieMessage: boolean = false;
    cookieMessageAccepted: boolean = false;

    cookieName: string = 'iu-vendors-froth';
    cookieLength: number = 30;

    constructor(private httpClient: HttpClient, public coreService: CoreService) {
        var self = this;
    }

    houseKeeping() {
        var self = this;

        self.checkForCookie();
        self.populateSession();
    }

    // populate session
    populateSession() {
        var self = this;

        self.getSession().subscribe((resp: any) => {
            self.setSession(resp);
        });
    }

    // set session
    setSession(inputData: any) {
        var self = this;

        if(inputData == null) return;

        self.sessionId = inputData.sessionId || "";
        self.setTags(inputData.tags);
    }

    // get session
    getSession() {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Session/Get`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // save info about the session
    saveInfo(model: any) {
        var self = this;

        return this.httpClient.post(`${environment.apiUrl.ecomm}/api/Session/SaveInfo`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // populate tags
    populateTags() {
        var self = this;

        // self.getTags().subscribe((resp: any) => {
        //     self.setTags(resp);
        // })
    }

    // set tags
    setTags(inputData: any) {
        var self = this;

        inputData.split(' ').forEach((tag: any) => {
            if(tag != null && tag != '' && tag != ' ' && self.tags.indexOf(tag) < 0) self.tags.push(tag);
        });

        self.tags.sort();
    }

    // get tags
    getTags() {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Tag/GetMyTags`).pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    addTags(tags: string[]) {
        var self = this;

        // tags.forEach((tag: string) => {
        //     if(self.tags.indexOf(tag.toLowerCase()) < 0) self.tags.push(tag);
        // });
    }

    /***** COOKIE *****/

    // check for cookie
    // gets session and shows message if none
    // otherwise populates session from cookie
    checkForCookie() {
        var self = this;

        var cookie = null;

        // check for cookie
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${self.cookieName}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                cookie = c.substring(cookieName.length, c.length);
                // return c.substring(cookieName.length, c.length);
            }
        }

        // if none, show message
        if(cookie == null || cookie == '') {
            self.populateSession();
            self.showCookieMessage = true;
        }
        else {
            self.cookieMessageAccepted = true;
            self.sessionId = cookie;
        }
    }

    clearCookie() {
        var self = this;
        self.setCookie(0);
    }

    setCookie(days: number, path: string = '') {
        var self = this;

        let d:Date = new Date();
        d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
        let expires:string = `expires=${d.toUTCString()}`;
        let cpath:string = path ? `; path=${path}` : '';
        document.cookie = `${self.cookieName}=${self.sessionId}; ${expires}${cpath}`;
    }

    declineCookie() {
        var self = this;

        self.showCookieMessage = false;
    }

    acceptCookie() {
        var self = this;

        self.cookieMessageAccepted = true;
        self.showCookieMessage = false;

        // create cookie
        self.setCookie(self.cookieLength);
    }
    /***** COOKIE *****/

    logout() {
        var self = this;

        self.clearCookie();
        self.populateSession();
    }
}