import { Deserializable } from '../deserialize.model';

export class PaymentTypeModel implements Deserializable  {
    PaymentTypeId: number = 0;
    Name: string = '';
    
    Hidden: boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.PaymentTypeId = input.paymentTypeId;
        self.Name = input.name;
        
        return self;
    }
}