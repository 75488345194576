import { Deserializable } from "../deserialize.model";
import { VendorScheduleProfileLinkModel } from "./schedule.profile.link.model";

export class ScheduleModel implements Deserializable  {
    VendorScheduleId: string = '';
    LocationMenuItemId: string = '';
    LocationMenuItemName: string = '';
    LocationId: string = '';
    DateStart: Date;
    DateEnd: Date;
    Published: boolean = false;
    MaxBookings: number = 1;
    MaxInstructors: number = 1;

    ScheduleTime: string = '';
    ScheduleBookings: number = 0;

    BookingNames: string[] = [];

    ScheduleProflieLinks: any[] = [];

    deserialize(input: any) {
        var self = this;

        self.VendorScheduleId = input.vendorScheduleId;
        self.LocationMenuItemId = input.locationMenuItemId;
        self.LocationMenuItemName = input.locationMenuItemName;
        self.LocationId = input.locationId;
        self.DateStart = input.dateStart;
        self.DateEnd = input.dateEnd;
        self.Published = input.published;
        self.ScheduleTime = input.scheduleTime;
        self.ScheduleBookings = input.scheduleBookings;
        self.BookingNames = input.bookingNames;
        self.MaxInstructors = input.maxInstructors || 1;
        self.MaxBookings = input.maxBookings || 1;

        if(input.proflieLinks != null && input.proflieLinks.length > 0) {
            self.ScheduleProflieLinks = [];
            input.proflieLinks.forEach((link: any) => {
                self.ScheduleProflieLinks.push(new VendorScheduleProfileLinkModel().deserialize(link));
            });
        }

        return self;
    }
}