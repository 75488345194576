import { Deserializable } from '../deserialize.model';
import { LocationMenuItemPropertyModel } from './location.menu.item.property.model';
import { LocationScheduleModel } from './location.schedule.model';

export class LocationMenuItemExtraModel implements Deserializable  {
    LocationMenuItemId: string = '';
    Schedule: LocationScheduleModel[] = [];
    Properties: LocationMenuItemPropertyModel[] = [];
    Checklist: string[] = [];
    Features: string[] = [];
    Latitude: number = 0;
    Longitude: number = 0;
  
    deserialize(input: any) {
        var self = this;
        
        self.LocationMenuItemId = input.locationMenuItemId;
        self.Checklist = input.Checklist;
        self.Features = input.Features;

        self.Schedule = [];
        if(input.schedule != null && input.schedule != '') {
            input.schedule.forEach(schedule => {
                self.Schedule.push(new LocationScheduleModel().deserialize(schedule));
            });
        }

        self.Properties = [];
        if(input.properties != null && input.properties != '') {
            input.properties.forEach(property => {
                self.Properties.push(new LocationMenuItemPropertyModel().deserialize(property));
            });
        }

        // var model = JSON.parse(input);

        // if(model != null) {
        //     self.DateStart = model.DateStart || '';
        //     self.ListData = model.ListData || [];
        //     self.Latitude = model.Latitude || 0;
        //     self.Longitude = model.Longitude || 0;
        // }

        return self;
    }
  }
  