import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SchedulePage } from 'src/app/pages/schedule/schedule.page';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss'],
})
export class ScheduleListComponent implements OnInit {
  @Input() scheduleList: any[] = [];

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  async showSchedulePop(entityId: string) {
    var self = this;

    const modal = await self.modalController.create({
      component: SchedulePage,
      componentProps: {
        inputScheduleId: entityId,
        inputLocationId: environment.vendorId,
      }
    });
    return await modal.present();
  }
}
