import { Deserializable } from '../deserialize.model';

export class LocationPhotoModel implements Deserializable  {
  PhotoId: string = '';
  LocationId: string = '';
  PhotoUrl: string = '';
  Order: number = 0;

  deserialize(input: any) {
      var self = this;

      self.PhotoId = input.photoId || '';
      self.LocationId = input.locationId || '';
      self.PhotoUrl = input.photoUrl || '';
      self.Order = input.order || 0;
      
      return self;
  }
}