import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderItemModel } from 'src/app/models/order/order.item.model';
import { OrderModel } from 'src/app/models/order/order.model';
import { LocationCapturePaymentPage } from 'src/app/pages/location-capture-payment/location-capture-payment.page';
import { LocationCaptureTrackingPage } from 'src/app/pages/location-capture-tracking/location-capture-tracking.page';
import { UpdateOrderItemPage } from 'src/app/pages/update-order-item/update-order-item.page';
import { UpdateOrderPage } from 'src/app/pages/update-order/update-order.page';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-details-tab',
  templateUrl: './order-details-tab.component.html',
  styleUrls: ['./order-details-tab.component.scss'],
})
export class OrderDetailsTabComponent implements OnInit {
  @Input() inputOrder : OrderModel = new OrderModel();

  constructor(public modalController: ModalController, public orderService: OrderService) { }

  ngOnInit() {}

  async showUpdateOrderModal() {
    var self = this;

    const modal = await this.modalController.create({
      component: UpdateOrderPage,
      componentProps: { 
        inputOrder: self.orderService.selectedAdminOrder
      }
    });

    return await modal.present();
  }

  async showUpdateOrderItemModal(orderItem: OrderItemModel) {
    var self = this;

    const modal = await this.modalController.create({
      component: UpdateOrderItemPage,
      componentProps: { 
        inputOrderItem: orderItem
      }
    });

    return await modal.present();
  }

  async showCapturePaymentModal() {
    var self = this;

    const modal = await this.modalController.create({
      component: LocationCapturePaymentPage,
      componentProps: { 
        inputOrderId: self.inputOrder.OrderId
      }
    });

    return await modal.present();
  }

  async showCaptureTrackingModal() {
    var self = this;

    const modal = await this.modalController.create({
      component: LocationCaptureTrackingPage,
      componentProps: { 
        inputOrderId: self.inputOrder.OrderId
      }
    });

    return await modal.present();
  }
}
