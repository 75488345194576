import { Deserializable } from "../deserialize.model";
import { ScheduleModel } from "./schedule.model";

export class ScheduleDayModel implements Deserializable  {
    DayOfWeek: number = 0;
    DayOfMonth: number = 0;
    Month: number = 0;
    Selected: boolean = false;

    Schedule: ScheduleModel[] = [];
  
    deserialize(input: any) {
        var self = this;

        self.DayOfWeek = input.dayOfWeek;
        self.DayOfMonth = input.dayOfMonth;
        self.Month = input.month;

        if(input.schedule != null) {
            input.schedule.forEach((schedule: any) => {
                self.Schedule.push(new ScheduleModel().deserialize(schedule));
            });
        }
        
        return self;
    }
}