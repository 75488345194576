import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { PaymentTypeModel } from 'src/app/models/customer/customer.payment.type.model';
import { ProfileMediaModel } from 'src/app/models/media/media.model';
import { ProfileModel } from 'src/app/models/profile/profile.model';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-location-capture-payment',
  templateUrl: './location-capture-payment.page.html',
  styleUrls: ['./location-capture-payment.page.scss'],
})
export class LocationCapturePaymentPage implements OnInit {
  loading: boolean = false;
  paymentForm: FormGroup;
  paymentTypes: any[] = [];  

  constructor(public formBuilder: FormBuilder, public notificationService: NotificationService, public modalController: ModalController,
    public orderService: OrderService) { }

  ngOnInit() {
    var self = this;

    self.preparePaymentForm();
  }

  preparePaymentForm() {
    var self = this;

    self.paymentTypes = [];
    self.paymentTypes.push(new PaymentTypeModel().deserialize({
      paymentTypeId: 6,
      name: 'Card Machine'
    }));
    self.paymentTypes.push(new PaymentTypeModel().deserialize({
      paymentTypeId: 5,
      name: 'Cash'
    }));
    self.paymentTypes.push(new PaymentTypeModel().deserialize({
      paymentTypeId: 4,
      name: 'EFT'
    }));
    /*self.paymentTypes.push(new PaymentTypeModel().deserialize({
      paymentTypeId: 2,
      name: 'Zapper'
    }));*/
    self.paymentTypes.push(new PaymentTypeModel().deserialize({
      paymentTypeId: 3,
      name: 'PayPal'
    }));

    self.paymentForm = self.formBuilder.group({
      orderId: [self.orderService.selectedAdminOrder.OrderId],
      amount: [self.orderService.selectedAdminOrder.OrderOutstanding, Validators.required],
      locationId: [self.orderService.selectedAdminOrder.LocationId, Validators.required],
      paymentTypeId: [self.paymentTypes[0].PaymentTypeId, Validators.required]
    });
  }

  savePayment() {
    var self = this;

    if(!self.paymentForm.valid) {
      self.notificationService.showFormError();

      return;
    }

    var model = self.paymentForm.value;

    self.orderService.capturePaymentByLocation(model).subscribe((resp: any) => {
      if(resp != null) {
        self.notificationService.showToastrSuccess('Done', 'Order payment saved.');
        self.orderService
        self.loadOrder();
      }
    });
  }

  loadOrder() {
    var self = this;

    self.orderService.loadingOrder = true;

    self.orderService.getLocationOrder(self.orderService.selectedAdminOrder.LocationId, self.orderService.selectedAdminOrder.OrderId).subscribe((resp: any) => {
      self.orderService.populateAdminOrder(resp);

      self.orderService.loadingOrder = false;
      self.dismissModal();
    });
  }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
