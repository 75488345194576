import { Deserializable } from '../deserialize.model';

export class LocationAddressDetailsModel implements Deserializable  {
    AddressLine1: string = '';
    AddressLine2: string = '';
    AddressLine3: string = '';
    City: string = '';
    Country: string = '';
    PostalCode: string = '';
  
    deserialize(inputModel: any) {
        var self = this;

        self.AddressLine1 = inputModel.addressLine1;
        self.AddressLine2 = inputModel.addressLine2;
        self.AddressLine3 = inputModel.addressLine3;
        self.City = inputModel.city;
        self.Country = inputModel.country;
        self.PostalCode = inputModel.postalCode;

        return self;
    }
}