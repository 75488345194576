import { Deserializable } from '../deserialize.model';

export class LocationMenuItemPriceModel implements Deserializable  {
    LocationMenuItemPriceId: string = '';
    LocationMenuItemId: string = '';
    Name: string = '';
    Price: number = 0;
    Quantity: number = 0;
    Loading : boolean = false;
    Delete : boolean = false;
    ShowDelete : boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.LocationMenuItemId = input.locationMenuItemId;
        self.LocationMenuItemPriceId = input.locationMenuItemPriceId || null;
        self.Name = input.name;
        self.Price = input.price;
        self.Quantity = input.quantity;

        return self;
    }
  }
  