import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-comms-tab',
  templateUrl: './order-comms-tab.component.html',
  styleUrls: ['./order-comms-tab.component.scss'],
})
export class OrderCommsTabComponent implements OnInit {
  @Input() inputOrderId : string = '';
  loading: boolean = false;
  commsType: string = '2';
  orderCommsType: string = '1';

  constructor(public orderService: OrderService, public notificationService: NotificationService) { }

  ngOnInit() {
  }

  processOrderComms() {
    var self = this;

    self.loading = true;

    self.orderService.processOrderComms(self.inputOrderId, self.commsType, self.orderCommsType)
    .subscribe((resp: any) => {
      if(resp == true) {
        self.notificationService.showToastrSuccess('Done', 'Communication sent.');
      }
      else {
        self.notificationService.showToastrError('Oops', 'An error occured.');
      }
      self.loading = false;
    });
  }
}
