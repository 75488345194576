import { Deserializable } from '../deserialize.model';

export class LocationScheduleModel implements Deserializable  {
    VendorScheduleId: string = '';
    LocationMenuItemId: string = '';
    LocationId: string = '';
    LocationMenuItemName: string = '';

    DateStart: Date;
    DateEnd: Date;
  
    deserialize(input: any) {
        var self = this;

        self.VendorScheduleId = input.vendorScheduleId;
        self.LocationMenuItemId = input.locationMenuItemId;
        self.LocationId = input.locationId;
        self.LocationMenuItemName = input.locationMenuItemName;
        self.DateStart = new Date(input.dateStart);
        self.DateEnd = new Date(input.dateEnd);

        return self;
    }
  }
  