import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { LocationMenuItemModel } from 'src/app/models/locations/location.menu.item.model';
import { LocationMenuModel } from 'src/app/models/locations/location.menu.model';
import { ScheduleDayModel } from 'src/app/models/schedule/schedule.day.model';
import { ScheduleSaveModel } from 'src/app/models/schedule/schedule.save.model';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-multi-schedule',
  templateUrl: './add-multi-schedule.component.html',
  styleUrls: ['./add-multi-schedule.component.scss'],
})
export class AddMultiScheduleComponent implements OnInit {
  loading: boolean = false;
  maxBookings: number = 1;
  maxInstructors: number = 1;
  locationMenu: LocationMenuModel[] = [];
  selectedMenu: LocationMenuModel = null;
  selectedMenuId: string = '';
  selectedMenuItemId: string = '';
  selectedMenuItem: LocationMenuItemModel = null;
  startDate: string = '';
  endDate: string = '';
  selectedDates: any[] = [];

  constructor(public modalController: ModalController, public locationService: LocationService, public scheduleService: ScheduleService,
    private formBuilder: FormBuilder, public notificationService: NotificationService) { }

  ngOnInit() {
    var self = this;

    self.startDate = '2023-01-01T06:00:00+02:00';
    self.endDate = '2023-01-01T07:00:00+02:00';
    self.scheduleService.selectedSchedule = null;

    self.populateLocationMenus();
  }

  populateLocationMenus() {
    var self = this;

    self.loading = true;
    self.locationService.getLocationMenu(environment.vendorId).subscribe((resp: any) => {
      if(resp != null && resp.length > 0) {
        resp.forEach((menu: any) => {
          self.locationMenu.push(new LocationMenuModel().deserialize(menu));
        });
      }
      self.loading = false;
    });
  }

  populateMenu() {
    var self = this;

    self.loading = true;

    self.selectedMenuItem = null;

    self.locationService.getMenu(self.selectedMenuId).subscribe((resp: any) => {
      if(resp != null) {
        self.selectedMenu = new LocationMenuModel().deserialize(resp);

        if(self.scheduleService.selectedSchedule != null && self.scheduleService.selectedSchedule.LocationMenuItemId != '' && self.selectedMenu.Items != null) {
          self.selectedMenu.Items.forEach((item : any) =>{
            if(self.scheduleService.selectedSchedule.LocationMenuItemId == item.LocationMenuItemId) {
              self.selectMenuItem(item);
            }
          });
        }
      }

      self.loading = false;
    });
  }

  selectMenu(menu: LocationMenuModel) {
    var self = this;

    self.selectedMenuItem = null;
    
    self.locationService.getMenu(menu.LocationMenuId).subscribe((resp: any) => {
      self.selectedMenu = menu;

      if(resp != null) {
        self.selectedMenu = new LocationMenuModel().deserialize(resp);
      }
    });
  }

  selectMenuItem(menuItem: LocationMenuItemModel) {
    var self = this;

    self.selectedMenuItem = menuItem;
  }

  // setup the view to select a menu item
  housekeepingForMenuItem() {
    var self = this;

    self.selectedMenuItem = null;
  }

  selectMultiDay(day: ScheduleDayModel) {
    var self = this;

    // check if day is already selected
    if(day.Selected == true) {
      var index = self.selectedDates.indexOf(day);

      if(index > -1) {
        self.selectedDates.splice(index, 1);
      }

      day.Selected = false;
    }
    else {
      day.Selected = true;
      self.selectedDates.push(day);
    }
  }

  saveSchedule() {
    var self = this;

    var startDate = new Date(self.startDate);
    startDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60 * 1000));

    var endDate = new Date(self.endDate);
    endDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60 * 1000));

    var model = new ScheduleSaveModel();

    model.LocationId = environment.vendorId;
    model.DateStart = startDate;
    model.DateEnd = endDate;
    model.Published = true;
    model.MaxBookings = self.maxBookings;
    model.MaxInstructors = self.maxInstructors;

    if(self.selectedMenuItem != null) model.LocationMenuItemId = self.selectedMenuItem.LocationMenuItemId;
    if(self.selectedMenu != null) model.LocationMenuId = self.selectedMenu.LocationMenuId;

    model.Dates = [];

    self.selectedDates.forEach((date: any) => {
      model.Dates.push(new Date(self.scheduleService.year, self.scheduleService.month - 1, date.DayOfMonth, 8));
    });

    model.VendorScheduleId = null;
    if(model.LocationMenuId == '') model.LocationMenuId = null;
    if(model.LocationMenuItemId == '') model.LocationMenuItemId = null;

    console.log(model);
    // return;

    self.scheduleService.saveMulti(model).subscribe((resp: any) => {
      console.log(resp);
      if(resp != null && resp.outcome == true) {
        self.notificationService.showToastrSuccess('Done!', 'Schedule Saved.');

        // self.filterSchedule();

        self.dismissModal();
      }
      else {
        self.notificationService.showToastrError('Oops', 'An error occured. Please try again.');
      }
      /*if(resp != null && resp.outcomeData != null) {
        var newSchedule = new ScheduleModel().deserialize(resp.outcomeData);

        if(self.scheduleService.selectedSchedule.VendorScheduleId == newSchedule.VendorScheduleId) {
          startDate = new Date(newSchedule.DateStart);
          startDate = new Date(startDate.getTime() + (startDate.getTimezoneOffset() * 60 * 1000));

          endDate = new Date(newSchedule.DateEnd);
          endDate = new Date(endDate.getTime() + (endDate.getTimezoneOffset() * 60 * 1000));

          self.scheduleService.selectedSchedule.DateStart = startDate;
          self.scheduleService.selectedSchedule.DateEnd = endDate;
          self.scheduleService.selectedSchedule.LocationMenuItemId = newSchedule.LocationMenuItemId;
          self.scheduleService.selectedSchedule.LocationMenuItemId = newSchedule.LocationMenuItemId;
          self.scheduleService.selectedSchedule.LocationMenuItemName = newSchedule.LocationMenuItemName;
        }
        else {
          startDate = new Date(newSchedule.DateStart);
          newSchedule.DateStart = new Date(startDate.getTime() + (startDate.getTimezoneOffset() * 60 * 1000));

          endDate = new Date(newSchedule.DateEnd);
          newSchedule.DateEnd = new Date(endDate.getTime() + (endDate.getTimezoneOffset() * 60 * 1000));

          self.scheduleService.selectedDay.Schedule.push(newSchedule);
        }

        self.scheduleService.selectedDay.Schedule.sort((a, b) => {
          return <any>new Date(a.DateStart) - <any>new Date(b.DateStart);
        });

        self.notificationService.showToastrSuccess('Done!', 'Schedule Saved.');
        self.closeModal();
      }
      else {
        self.notificationService.showToastrError('Oops', 'An error occured. Please try again.');
      }*/
    });
  }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
