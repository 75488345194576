import { Deserializable } from '../deserialize.model';
import { LocationMenuItemModel } from './location.menu.item.model';

export class LocationMenuModel implements Deserializable  {
    LocationId: string = '';
    
    LocationMenuId: string = '';
    Name: string = '';
    Description: string = '';
    CoverPicture: string = '';
    
    Hidden : boolean = false;
    Delete : boolean = false;
    OrderChanged : boolean = false;
    Order: number = 0;

    ShowItems : boolean = false;
    Loading : boolean = false;
    ItemsLoaded: boolean = false;
    Items: any[] = [];
  
    deserialize(input: any) {
        var self = this;

        self.LocationId = input.locationId;
        self.LocationMenuId = input.locationMenuId;
        self.Name = input.name;
        self.Description = input.description;
        self.CoverPicture = input.coverPicture;
        self.Order = input.order;
        self.Hidden = input.hidden;

        if(input.menuItems != null && input.menuItems.length > 0) {
            input.menuItems.forEach((item: any) => {
                self.Items.push(new LocationMenuItemModel().deserialize(item));
            });
        }
        
        return self;
    }

    update(input: any) {
        var self = this;
        
        self.Name = input.name;
        self.Description = input.description;
        self.Order = input.order;
        self.Hidden = input.hidden;
    }
  }
  