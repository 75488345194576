import { Deserializable } from '../deserialize.model';

export class LocationWorkingHourModel implements Deserializable  {
    LocationId: string = '';
    WorkingHourId: string = '';
    HoursText: string = '';
    HoursTime: string = '';
    Delete: boolean = false;
  
    deserialize(inputModel: any) {
        var self = this;

        self.LocationId = inputModel.locationId;
        self.WorkingHourId = inputModel.workingHourId;
        self.HoursText = inputModel.hoursText;
        self.HoursTime = inputModel.hoursTime;

        return self;
    }

    update(inputModel: any) {
        var self = this;

        self.HoursText = inputModel.hoursText;
        self.HoursTime = inputModel.hoursTime;
    }
}