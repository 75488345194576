import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
// import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {
    constructor(public toastController: ToastController/*private toastr: ToastrService*/) {
        var self = this;
    }

    showLoginPopover: boolean = false;

    showToastrSuccess(title: string, message: string) {
        var self = this;
        self.showToast(message).then((resp : any) => {
        });
        // self.toastr.success(message, title);
    }

    showToastrError(title: string, message: string) {
        var self = this;
        // self.toastr.error(message, title);
        self.showToast(message).then((resp : any) => {
        });
    }

    showToastrWarning(title: string, message: string) {
        var self = this;
        // self.toastr.warning(message, title);
    }

    showToastrInfo(title: string, message: string) {
        var self = this;
        // self.toastr.info(message, title);
    }

    showClipboarddMessage() {
        var self = this;
        // self.toastr.success('Done!', 'Copied.');
    }

    showPostError() {
        var self = this;
        
        self.showToast('An error occured. Please try again.').then((resp : any) => {
        });
    }

    showFormError() {
        var self = this;
        
        self.showToast('Please complete all required fields.').then((resp : any) => {
        });
    }

    showAuthError() {
        var self = this;
        
        self.showLoginPopover = false;
        self.showLoginPopover = true;
        // self.showToast('You need to be logged in to do this.').then((resp : any) => {
        // });
    }

    hideAuthError() {
        var self = this;
        self.showLoginPopover = true;
        self.showLoginPopover = false;
    }

    async showToast(message: string, duration: number = 2000) {
        const toast = await this.toastController.create({
            message: message,
            duration: duration,
            position: 'middle',
            buttons: [{
                icon: 'close'
            }]
          });
          toast.present();
    }
}