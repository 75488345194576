import { Pipe } from "@angular/core";

@Pipe({
name: 'camelcase'
})

export class CamelCasePipe {
    transform(value: string) {
        if ((typeof value) !== 'string') {
            return value;
        }

        value = value.split(/(?=[A-Z])/).join(' ');
        if(value != null && value.length > 0) value = value[0].toUpperCase() + value.slice(1);
        return value;
    }
}