import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-tags-strip',
  templateUrl: './tags-strip.component.html',
  styleUrls: ['./tags-strip.component.scss'],
})
export class TagsStripComponent implements OnInit {

  constructor(public locationService: LocationService) { }

  ngOnInit() {}

}
