import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerAddressModel } from 'src/app/models/customer/customer.address.model';
import { CoreService } from 'src/app/services/core.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-customer-address',
  templateUrl: './add-customer-address.component.html',
  styleUrls: ['./add-customer-address.component.scss'],
})
export class AddCustomerAddressComponent implements OnInit {
  addressForm: FormGroup;
  selectedSegment: string = 'address';
  loading: boolean = false;
  @Output() popEvent = new EventEmitter<boolean>();
  
  constructor(public formBuilder: FormBuilder,
    private coreService: CoreService, public customerService: CustomerService, public notificationService: NotificationService) {
    var self = this;

    self.prepareAddressForm();
  }

  ngOnInit() {}

  prepareAddressForm() {
    var self = this;

    if(self.customerService.selectedAddress == null) self.customerService.selectedAddress = new CustomerAddressModel();

    self.addressForm = self.formBuilder.group({
      customerAddressId: [self.customerService.selectedAddress.CustomerAddressId],
      name: [self.customerService.selectedAddress.Name],
      addressInfo: [''],
      longitude: [self.customerService.selectedAddress.Longitude],
      latitude: [self.customerService.selectedAddress.Latitude],
      jsonData: [self.customerService.selectedAddress.JsonData],
      addressTypeId: [self.customerService.selectedAddress.AddressTypeId],

      pickup: [self.customerService.selectedAddress.Pickup],
      hidden: [self.customerService.selectedAddress.Hidden]
    });

    if(self.customerService.selectedAddress.AddressLines.length == 0) self.customerService.selectedAddress.AddressLines.push('');

    var count = 0;
    self.customerService.selectedAddress.AddressLines.forEach((line: string) => {
      self.addressForm.addControl('address-line-' + count, new FormControl(line));
      count++;
    });
  }

  addLine() {
    var self = this;
    
    self.addressForm.addControl('address-line-' + self.customerService.selectedAddress.AddressLines.length, new FormControl(''));
    self.customerService.selectedAddress.AddressLines.push('');
  }

  saveAddress() {
    var self = this;

    if(!self.addressForm.valid) {
      self.notificationService.showFormError();

      return;
    }

    self.loading = true;

    if(self.customerService.selectedAddress.AddressLines.length > 0 && self.customerService.selectedAddress.AddressLines.length[0] != '') {
      var address = [];

      for(var count = 0; count < self.customerService.selectedAddress.AddressLines.length; count++) {
        address.push(self.addressForm.controls['address-line-' + count].value);
      }

      self.addressForm.patchValue({
        addressInfo: address.join(',')
      });
    }
    else {
      self.addressForm.patchValue({
        addressInfo: ''
      });
    }

    self.customerService.saveCustomerAddress(self.addressForm.value).subscribe((resp: any) => {
      if(resp == null) {
        self.notificationService.showPostError();
      }
      else {
        if(self.customerService.selectedAddress.CustomerAddressId == '') {
          self.customerService.selectedCustomer.Addresses.push(new CustomerAddressModel().deserialize(resp));
          self.notificationService.showToastrSuccess('Done!', 'Address Added.');
        }
        else {
          self.customerService.selectedCustomer.Addresses.forEach((address: CustomerAddressModel) => {
            if(address.CustomerAddressId == resp.customerAddressId) {
              address = address.update(new CustomerAddressModel().deserialize(resp))
            }
          });
        }
        self.popEvent.next(true);
      }
      self.loading = false;
    });
  }
}
