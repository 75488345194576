import { Component, Input, OnInit } from '@angular/core';
import { CustomerContactDetailModel } from 'src/app/models/customer/customer.contact.detail.model';

@Component({
  selector: 'app-order-contact-tab',
  templateUrl: './order-contact-tab.component.html',
  styleUrls: ['./order-contact-tab.component.scss'],
})
export class OrderContactTabComponent implements OnInit {
  @Input() inputDetails : CustomerContactDetailModel = new CustomerContactDetailModel();
  
  constructor() { }

  ngOnInit() {}

}
