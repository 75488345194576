import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { SessionService } from './session.service';
import { OrderModel } from '../models/order/order.model';
import { OrderItemModel } from '../models/order/order.item.model';
import { OrderItemUpdateModel } from '../models/order/order.item.update.model';
import { OrderRequestModel } from '../models/order/order.request.model';
import { LocationMenuItemModel } from '../models/locations/location.menu.item.model';
import { environment } from 'src/environments/environment';
import { DirectoryModel } from '../models/directory/directory.model';

const routes = {
};

@Injectable()
export class OrderService {
    loadingBasket: boolean = false;
    orderItems: OrderItemModel[] = [];
    basketTotal: number = 0;
    itemsTotal: number = 0;
    pickupOrder: boolean = false;
    placedOrder: OrderModel;

    loadingOrder: boolean = false;
    orders: OrderModel[] = [];
    selectedOrder: OrderModel = new OrderModel();
    orderPaymentAmount: number = 0;
    orderStatuses: any[] = [];
    ordersPages: number = 0;
    pageSize: number = 10;
    page: number = 1;
    orderStatusId: number = 0;
    ordersSearchText: string = '';

    adminOrders: OrderModel[] = [];
    selectedAdminOrder: OrderModel = new OrderModel();
    adminOrderStatuses: any[] = [];
    adminOrdersPages: number = 0;
    adminPage: number = 1;
    adminOrderStatusId: number = 0;
    adminOrdersSearchText: string = '';

    selectedUpdate: OrderItemUpdateModel = new OrderItemUpdateModel();
    orderItemUpdates: OrderItemUpdateModel[] = [];

    selectedRequest: OrderRequestModel = new OrderRequestModel();
    orderRequests: OrderRequestModel[] = [];

    constructor(private httpClient: HttpClient, 
        public notificationService: NotificationService, public sessionService: SessionService) {
        var self = this;
        self.houseKeeping();
    }

    // clean up crew
    houseKeeping() {
        var self = this;

        self.orderItems = [];
        self.calculateTotal();
    }

    calculateDeposit() {
        var self = this;

        self.orderPaymentAmount = self.selectedOrder.OrderOutstanding / 2;
    }

    // post to save basket item
    saveToBasket(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/Basket/Save`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // add menu item to basket
    addToBasket(model: LocationMenuItemModel) {
        var self = this;

        var requestModel = {
            LocationMenuItemId: model.LocationMenuItemId,
            Name: model.Name,
            JsonData: model.JsonData,
            SessionId: self.sessionService.sessionId,
            Price: model.Price,
            Quantity: model.Quantity
        };

        self.saveToBasket(requestModel).subscribe((resp : any) => {
            if(resp != null) {
                var index = -1;
                var result = 0;
                result = resp.quantity;
                // model.Quantity = result;

                for(var count = 0; count < self.orderItems.length; count++) {
                    if(self.orderItems[count].BasketItemId == resp.basketItemId) {
                        self.orderItems[count].Quantity = resp.quantity;
                        self.orderItems[count].Total = self.orderItems[count].Quantity * self.orderItems[count].Price;
                        index = count;
                        count = self.orderItems.length;
                    }
                }

                if(resp.quantity == 0 && index > -1) {
                    self.orderItems.splice(index, 1);
                    self.notificationService.showToastrSuccess("Success","Item removed from your basket.");
                }
                else if(index == -1) {
                    resp.price = model.Price;

                    self.orderItems.push(new OrderItemModel().deserialize(resp));
                    self.notificationService.showToastrSuccess("Success","Item added to your basket.");
                }
                else {
                    self.notificationService.showToastrSuccess("Success","Basket item updated.");
                }

                self.calculateTotal();
            }
            else {
                self.notificationService.showToastrError('Oops', 'Product not added, please try again.');
            }
        });
    }

    // add menu item
    addMenuItem(model: LocationMenuItemModel) {
        var self = this;

        var requestModel = {
            LocationMenuItemId: model.LocationMenuItemId,
            Name: model.Name,
            JsonData: model.JsonData,
            SessionId: self.sessionService.sessionId,
            Price: model.Price,
            Quantity: 1
        };

        self.saveToBasket(requestModel).subscribe((resp : any) => {
            if(resp != null) {
                var index = -1;
                var result = 0;
                result = resp.quantity;
                model.Quantity = result;

                for(var count = 0; count < self.orderItems.length; count++) {
                    if(self.orderItems[count].BasketItemId == resp.basketItemId) {
                        self.orderItems[count].Quantity = resp.quantity;
                        self.orderItems[count].Total = self.orderItems[count].Quantity * self.orderItems[count].Price;
                        index = count;
                        count = self.orderItems.length;
                    }
                }

                if(resp.quantity == 0 && index > -1) {
                    self.orderItems.splice(index, 1);
                }
                else if(index == -1) {
                    resp.price = model.Price;

                    self.orderItems.push(new OrderItemModel().deserialize(resp));
                }

                self.calculateTotal();
            }
            else {
                self.notificationService.showToastrError('Oops', 'Product not added, please try again.');
            }
        });
    }

    // remove menu item
    removeMenuItem(model: LocationMenuItemModel) {
        var self = this;

        var requestModel = {
            LocationMenuItemId: model.LocationMenuItemId,
            Name: model.Name,
            JsonData: model.JsonData,
            SessionId: self.sessionService.sessionId,
            Price: model.Price,
            Quantity: -1
        };

        self.saveToBasket(requestModel).subscribe((resp : any) => {
            if(resp != null) {
                var index = -1;
                var result = 0;
                result = resp.quantity;
                model.Quantity = result;

                for(var count = 0; count < self.orderItems.length; count++) {
                    if(self.orderItems[count].BasketItemId == resp.basketItemId) {
                        self.orderItems[count].Quantity = resp.quantity;
                        self.orderItems[count].Total = self.orderItems[count].Quantity * self.orderItems[count].Price;
                        index = count;
                        count = self.orderItems.length;
                    }
                }

                if(resp.quantity == 0 && index > -1) {
                    self.orderItems.splice(index, 1);
                }
                else if(index == -1) {
                    resp.price = model.Price;

                    self.orderItems.push(new OrderItemModel().deserialize(resp));
                }

                self.calculateTotal();
            }
            else {
                self.notificationService.showToastrError('Oops', 'Item not removed, please try again.');
            }
        });
    }

    // add an order item
    addOrderItem(model: OrderItemModel) {
        var self = this;

        if(model.Quantity == model.MaxItems) {
            self.notificationService.showToastrError('Oops',"You've reached the maximum orders for this product.");
            return;
        }

        var requestModel = {
            BasketItemId: model.BasketItemId,
            LocationMenuItemId: model.LocationMenuItemId,
            Name: model.Name,
            JsonData: model.JsonData,
            SessionId: self.sessionService.sessionId,
            Price: model.Price,
            Quantity: 1
        };

        self.saveToBasket(requestModel).subscribe((resp : any) => {
            if(resp != null) {
                var index = -1;
                var result = 0;
                result = resp.quantity;
                model.Quantity = result;

                for(var count = 0; count < self.orderItems.length; count++) {
                    if(self.orderItems[count].BasketItemId == resp.basketItemId) {
                        self.orderItems[count].Quantity = resp.quantity;
                        self.orderItems[count].Total = self.orderItems[count].Quantity * self.orderItems[count].Price;
                        index = count;
                        count = self.orderItems.length;
                    }
                }

                if(resp.quantity == 0 && index > -1) {
                    self.orderItems.splice(index, 1);
                }
                else if(index == -1) {
                    resp.price = model.Price;

                    self.orderItems.push(new OrderItemModel().deserialize(resp));
                }

                self.calculateTotal();
            }
            else {
                self.notificationService.showToastrError('Oops', 'Product not added, please try again.');
            }
        });
    }

    // decrease an order item
    decreaseOrderItem(model: OrderItemModel) {
        var self = this;

        var result = 0;
        var index = self.orderItems.indexOf(model);
        for(var count = 0; count < self.orderItems.length; count++) {
            if(
                ((self.orderItems[count].DirectoryId != null && self.orderItems[count].DirectoryId != '' && self.orderItems[count].DirectoryId == model.DirectoryId) || self.orderItems[count].LocationMenuItemId == model.LocationMenuItemId)
                && self.orderItems[count].JsonData == model.JsonData
            ) {
                index = count;
            }
        }

        if(index > -1) {
            if(self.orderItems[index].Quantity == 1) {
                self.orderItems.splice(index, 1);
            }
            else {
                self.orderItems[index].Quantity -= model.Quantity;
                result = self.orderItems[index].Quantity;

                if(self.orderItems[index].Quantity < 1) {
                    self.orderItems.splice(index, 1);
                    result = 0;
                }
            }
        }

        self.calculateTotal();

        return result;
    }

    // remove an order item
    removeOrderItem(model: OrderItemModel) {
        var self = this;

        var requestModel = {
            BasketItemId: model.BasketItemId,
            LocationMenuItemId: model.LocationMenuItemId,
            Name: model.Name,
            JsonData: model.JsonData,
            SessionId: self.sessionService.sessionId,
            Price: model.Price,
            Quantity: -1
        };

        self.saveToBasket(requestModel).subscribe((resp : any) => {
            if(resp != null) {
                var index = -1;
                var result = 0;
                result = resp.quantity;
                model.Quantity = result;

                for(var count = 0; count < self.orderItems.length; count++) {
                    if(self.orderItems[count].BasketItemId == resp.basketItemId) {
                        self.orderItems[count].Quantity = resp.quantity;
                        self.orderItems[count].Total = self.orderItems[count].Quantity * self.orderItems[count].Price;
                        index = count;
                        count = self.orderItems.length;
                    }
                }

                if(resp.quantity == 0 && index > -1) {
                    self.orderItems.splice(index, 1);
                }
                else if(index == -1) {
                    resp.price = model.Price;

                    self.orderItems.push(new OrderItemModel().deserialize(resp));
                }

                self.calculateTotal();
            }
            else {
                self.notificationService.showToastrError('Oops', 'Item not removed, please try again.');
            }
        });
    }

    // delete an order item
    deleteOrderItem(model: OrderItemModel) {
        var self = this;

        var requestModel = {
            BasketItemId: model.BasketItemId,
            LocationMenuItemId: model.LocationMenuItemId,
            Name: model.Name,
            JsonData: model.JsonData,
            SessionId: self.sessionService.sessionId,
            Price: model.Price,
            Quantity: model.Quantity * -1
        };

        self.saveToBasket(requestModel).subscribe((resp : any) => {
            if(resp != null) {
                var index = -1;
                var result = 0;
                result = resp.quantity;
                model.Quantity = result;

                for(var count = 0; count < self.orderItems.length; count++) {
                    if(self.orderItems[count].BasketItemId == resp.basketItemId) {
                        self.orderItems[count].Quantity = resp.quantity;
                        self.orderItems[count].Total = self.orderItems[count].Quantity * self.orderItems[count].Price;
                        index = count;
                        count = self.orderItems.length;
                    }
                }

                if(resp.quantity == 0 && index > -1) {
                    self.orderItems.splice(index, 1);
                    self.notificationService.showToastrSuccess('Done', 'Basket item removed.');
                }
                else if(index == -1) {
                    resp.price = model.Price;

                    self.orderItems.push(new OrderItemModel().deserialize(resp));
                }

                self.calculateTotal();
            }
            else {
                self.notificationService.showToastrError('Oops', 'Item not removed, please try again.');
            }
        });
    }

    // calculate basket total
    calculateTotal() {
        var self = this;

        self.basketTotal = 0;
        self.itemsTotal = 0;

        self.orderItems.forEach((item: OrderItemModel) => {
            self.basketTotal += item.Total;
            self.itemsTotal += item.Quantity;
        });
    }

    // increase the count of an order item
    increment(item: OrderItemModel) {
        var self = this;
        
        item.Quantity++;
        item.Total = item.Quantity * item.Price;

        self.calculateTotal();
    }

    // decrease the count of an order item
    decrement(item: OrderItemModel) {
        var self = this;

        if(item.Quantity > 1) item.Quantity--;
        item.Total = item.Quantity * item.Price;
        
        self.calculateTotal();
    }

    populateBasket() {
        var self = this;
        self.loadingBasket = true;
        self.orderItems = [];

        self.getBasket().subscribe((resp: any) => {
            if(resp != null && resp.length > 0) {
                resp.forEach((item: any) => {
                    self.orderItems.push(new OrderItemModel().deserialize(item));
                });

                self.calculateTotal();
            }

            self.loadingBasket = false;
        });
    }

    getBasket() {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Basket/Get?locationId=` + environment.vendorId)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    populateOrders(inputData: any) {
        var self = this;

        self.orders = [];

        if(inputData != null) {

            if(inputData.orders != null) {
                inputData.orders.forEach((order: any) => {
                    self.orders.push(new OrderModel().deserialize(order));
                });
            }

            if(inputData.orderStatuses != null) {
                inputData.orderStatuses.forEach((orderStatus: any) => {
                    self.orderStatuses.push({
                        value: orderStatus.integerValue,
                        text: orderStatus.text
                    });
                });
            }

            if(inputData.allOrdersCount > self.pageSize) {
                self.ordersPages = Math.ceil(inputData.allOrdersCount / self.pageSize);
            }
        }
    }

    populateAdminOrders(inputData: any) {
        var self = this;

        self.adminOrders = [];
        self.adminOrderStatuses = [];
        self.adminOrdersPages = 0;

        if(inputData != null) {

            if(inputData.orders != null) {
                inputData.orders.forEach((order: any) => {
                    self.adminOrders.push(new OrderModel().deserialize(order));
                });
            }

            if(inputData.orderStatuses != null) {
                inputData.orderStatuses.forEach((orderStatus: any) => {
                    self.adminOrderStatuses.push({
                        value: orderStatus.integerValue,
                        text: orderStatus.text
                    });
                });
            }

            if(inputData.allOrdersCount > self.pageSize) {
                self.adminOrdersPages = Math.ceil(inputData.allOrdersCount / self.pageSize);
            }
        }
    }

    getOrders(searchText: string = '', locationId: string = '', customerId: string = '', getExtras: boolean = false, locationOrders: boolean = false) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/GetOrders?locationId=${locationId}&customerId=${customerId}&orderStatusId=${self.orderStatusId}&page=${self.page}&pageSize=${self.pageSize}&getExtras=${getExtras}&locationOrders=${locationOrders}&searchText=${self.ordersSearchText}`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    getAdminOrders(searchText: string = '', locationId: string = '', customerId: string = '', getExtras: boolean = false, locationOrders: boolean = false) {
        var self = this;

        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/GetOrders?locationId=${locationId}&customerId=${customerId}&orderStatusId=${self.adminOrderStatusId}&page=${self.adminPage}&pageSize=${self.pageSize}&getExtras=${getExtras}&locationOrders=${locationOrders}&searchText=${self.adminOrdersSearchText}`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    refreshSelectedOrder() {
        var self = this;

        self.loadingOrder = true;
        
        self.getOrder(self.selectedOrder.OrderId).subscribe((resp: any) => {
            self.populateOrder(resp);
      
            self.loadingOrder = false;
          });
    }

    populateOrder(inputData: any) {
        var self = this;

        if(inputData != null) {
            self.selectedOrder = new OrderModel().deserialize(inputData);
        }
    }

    populateAdminOrder(inputData: any) {
        var self = this;

        if(inputData != null && inputData.outcomeData != null) {
            self.selectedAdminOrder = new OrderModel().deserialize(inputData.outcomeData);
        }
    }

    getOrder(orderId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/Get?orderId=${orderId}`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    getOrderDownload(orderId: string) {
        var self = this;
        
        return self.httpClient.get(`${environment.apiUrl.ecomm}/Downloads/Order?id=${orderId}`, {responseType: 'text'})
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    getLocationOrder(locationId: string, orderId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/GetLocationOrder/${locationId}/${orderId}`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    emptyBasket(){
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Basket/EmptyBasket?locationId=` + environment.vendorId)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    placeOrder(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/Orders/PlaceOrder`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // add an order item
    addDirectoryToBasket(model: DirectoryModel, jsonData: string = '', quantity: number = 1) {
        var self = this;

        var requestModel = {
            // BasketItemId: model.BasketItemId,
            LocationMenuItemId: model.LocationMenuItemId,
            DirectoryId: model.DirectoryId,
            Name: model.Name,
            JsonData: jsonData,
            SessionId: self.sessionService.sessionId,
            Price: model.Price,
            Quantity: quantity
        };

        self.saveToBasket(requestModel).subscribe((resp : any) => {
            if(resp != null) {
                var index = -1;
                var result = 0;
                result = resp.quantity;
                model.Quantity = result;

                for(var count = 0; count < self.orderItems.length; count++) {
                    if(self.orderItems[count].BasketItemId == resp.basketItemId) {
                        self.orderItems[count].Quantity = resp.quantity;
                        self.orderItems[count].Total = self.orderItems[count].Quantity * self.orderItems[count].Price;
                        index = count;
                        count = self.orderItems.length;
                    }
                }

                if(resp.quantity == 0 && index > -1) {
                    self.orderItems.splice(index, 1);
                }
                else if(index == -1) {
                    resp.price = model.Price;

                    self.orderItems.push(new OrderItemModel().deserialize(resp));
                }

                self.calculateTotal();
                
                self.notificationService.showToastrSuccess("Success","Item added to your basket.");
            }
            else {
                self.notificationService.showToastrError('Oops', 'Product not added, please try again.');
            }
        }, (error: any) => {
        });
    }

    getPaymentDetails(orderId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/GetPaymentDetails/` + orderId)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    getPaymentTypes(orderId: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/GetPaymentTypes/` + orderId)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    logout() {
        var self = this;

        self.orderItems = [];
        self.basketTotal = 0;
        self.itemsTotal = 0;
    }

    /***** ORDER ITEM UPDATES *****/

    getLocationUpdates(locationId: string, searchText: string = "") {
        var self = this;

        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/OrderItems/GetLocationUpdates?locationId=${locationId}&searchText=${searchText}`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    populateOrderUpdates(inputData: any) {
        var self = this;

        self.orderItemUpdates = [];

        if(inputData != null) {

            if(inputData.outcomeData != null) {
                inputData.outcomeData.forEach((update: any) => {
                    self.orderItemUpdates.push(new OrderItemUpdateModel().deserialize(update));
                });
            }
        }
    }

    acceptUpdate(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/OrderItems/AcceptUpdate`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    declineUpdate(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/OrderItems/DeclineUpdate`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // post to update item - admin function
    updateItem(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/OrderItems/updateItem`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    // post to update order - admin function
    updateOrder(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/Orders/updateOrder`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    validateOrder(orderId: string) {
        var self = this;
    
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/ValidateOrder?orderId=${orderId}`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
      }

    /***** ORDER ITEM UPDATES *****/

    /***** ORDERS PLACED ALREADY *****/

    requestUpdate(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/OrderItems/RequestUpdate`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    requestOrderUpdate(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/Orders/RequestUpdate`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    getLocationRequests(locationId: string, searchText: string = "") {
        var self = this;

        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/GetLocationUpdates?locationId=${locationId}&searchText=${searchText}`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    populateOrderRequests(inputData: any) {
        var self = this;

        self.orderItemUpdates = [];

        if(inputData != null) {

            if(inputData.outcomeData != null) {
                inputData.outcomeData.forEach((request: any) => {
                    self.orderRequests.push(new OrderRequestModel().deserialize(request));
                });
            }
        }
    }

    acceptRequest(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/Orders/CompleteUpdate`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    declineRequest(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/Orders/CompleteUpdate`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    usePromoCode(orderId: string, promoCode: string) {
        var self = this;

        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/UsePromoCode?orderId=` + orderId + `&promoCode=` + promoCode)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    capturePaymentByLocation(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/LocationOrders/CapturePayment`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    captureTrackingNumber(model: any) {
        var self = this;

        return self.httpClient.post(`${environment.apiUrl.ecomm}/api/LocationOrders/CaptureTrackingNumber`, model).pipe(
            catchError(e => {
            // this.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    processOrderComms(orderId: string, commsType: string, orderCommsType: string) {
        var self = this;
        return self.httpClient.get(`${environment.apiUrl.ecomm}/api/Orders/ProcessOrderComms?id=${orderId}&commsType=${commsType}&orderCommsType=${orderCommsType}`)
        .pipe(
            catchError(e => {
            // self.showAlert(e.error.msg);
            throw new Error(e);
            })
        );
    }

    /***** ORDERS PLACED ALREADY *****/

    checkoutModalVisible: boolean = false;

    showCheckoutModal() {
        var self = this;

        self.checkoutModalVisible = false;
        self.checkoutModalVisible = true;
    }
    hideCheckoutModal() {
        var self = this;
        
        self.checkoutModalVisible = true;
        self.checkoutModalVisible = false;
    }
}