import { Component, Input, OnInit } from '@angular/core';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-products-block',
  templateUrl: './products-block.component.html',
  styleUrls: ['./products-block.component.scss'],
})
export class ProductsBlockComponent implements OnInit {
  @Input() inputText : string = '';
  @Input() showMoreProducts : boolean = false;
  @Input() inputItems : any[] = [];

  slideOpts = {
    speed: 400,
    slidesPerView: 3,
    loop: true,
    autoplay: true,
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 320px
      320: {
        slidesPerView: 1,
      },
      // when window width is <= 480px
      480: {
        slidesPerView: 1,
      },
      // when window width is <= 640px
      640: {
        slidesPerView: 2,
      },
      // when window width is <= 640px
      900: {
        slidesPerView: 3,
      }
    }
  };

  constructor() { }

  ngOnInit() {}

  hoverPic(menuItem: any) {
    var self = this;

    if(menuItem != null && menuItem.MenuItemPhotos != null && menuItem.MenuItemPhotos.length > 1) {
      var tempUrl = menuItem.CoverPicture;
      menuItem.CoverPicture = menuItem.MenuItemPhotos[2].PhotoUrl;
      menuItem.MenuItemPhotos[2].PhotoUrl = tempUrl;
    }
  }
}
