import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerContactDetailModel } from 'src/app/models/customer/customer.contact.detail.model';
import { CoreService } from 'src/app/services/core.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-customer-contact',
  templateUrl: './add-customer-contact.component.html',
  styleUrls: ['./add-customer-contact.component.scss'],
})
export class AddCustomerContactComponent implements OnInit {
  selectedSegment: string = 'friends';
  contactForm: FormGroup;
  loading: boolean = false;
  @Output() popEvent = new EventEmitter<boolean>();
  
  constructor(public formBuilder: FormBuilder,
    private coreService: CoreService, public customerService: CustomerService, public notificationService: NotificationService) {
    var self = this;

    self.prepareContactForm();
  }

  ngOnInit() {}

  prepareContactForm() {
    var self = this;

    if(self.customerService.selectedContactDetails == null) self.customerService.selectedContactDetails = new CustomerContactDetailModel();

    self.contactForm = self.formBuilder.group({
      customerContactDetailId: [self.customerService.selectedContactDetails.CustomerContactDetailId],
      name: [self.customerService.selectedContactDetails.Name],
      contactPerson: [self.customerService.selectedContactDetails.ContactPerson, Validators.required],
      detail: [self.customerService.selectedContactDetails.Detail, Validators.required],
      contactTypeId: [self.customerService.selectedContactDetails.ContactTypeId, Validators.required]
    });
  }

  saveContact() {
    var self = this;

    if(!self.contactForm.valid) {
      self.notificationService.showFormError();

      return;
    }

    self.loading = true;

    self.customerService.saveCustomerContactDetails(self.contactForm.value).subscribe((resp: any) => {
      if(resp == null) {
        self.notificationService.showPostError();
      }
      else {
        if(self.customerService.selectedContactDetails.CustomerContactDetailId == '') {
          self.customerService.selectedCustomer.ContactDetails.push(new CustomerContactDetailModel().deserialize(resp));
          self.notificationService.showToastrSuccess('Done!', 'Contact Added.');
        }
        else {
          self.customerService.selectedCustomer.ContactDetails.forEach((contact: CustomerContactDetailModel) => {
            if(contact.CustomerContactDetailId == resp.customerContactDetailId) {
              contact.Detail = resp.detail;
              contact.Name = resp.name;
              contact.ContactPerson = resp.contactPerson;
              self.notificationService.showToastrSuccess('Done!', 'Contact Updated.');
            }
          })
        }
        self.popEvent.next(true);
      }
      self.loading = false;
    });
  }
}
