import { Deserializable } from "../deserialize.model";

export class ProfileMediaModel implements Deserializable  {
    ProfileMediaId: string = '';
    Name: string = '';
    Description: string = '';
    CoverPicture: string = '';
    MediaURL: string = '';

    ProfileId: string = '';
    ProfileName: string = '';

    LocationId: string = '';
    LocationName: string = '';

    MediaTypeId: number = 4;
    MediaTypeName: string = '';
    
    DateCreated: string = '';
    DeleteDate: string = '';
    Icon: string = '';

    Hidden: boolean = false;
    Favorite: boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.ProfileMediaId = input.profileMediaId;
        self.Name = input.name;
        self.Description = input.description;
        self.CoverPicture = input.coverPicture;
        self.MediaURL = input.mediaURL;
        self.Favorite = input.favorite;
        self.LocationId = input.locationId;
        self.LocationName = input.locationName;
        self.MediaTypeId = input.mediaTypeId;
        self.MediaTypeName = input.mediaTypeName;
        self.DateCreated = input.dateCreated;
        self.DeleteDate = input.deleteDate;
        self.ProfileId = input.profileId;
        self.ProfileName = input.profileName;

        switch(self.MediaTypeId) {
            case 1: {
                self.Icon = "image";
                break;
            }
            case 2: {
                self.Icon = "film";
                break;
            }
            case 3: {
                self.Icon = "link";
                break;
            }
            case 4: {
                self.Icon = "cloud-download";
                break;
            }
            default: {
                self.MediaTypeId = 4;
                self.Icon = "cloud-download";
                break;
            }
        }
        
        return self;
    }
}