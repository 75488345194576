import { CustomerAddressModel } from "../customer/customer.address.model";
import { CustomerContactDetailModel } from "../customer/customer.contact.detail.model";
import { Deserializable } from "../deserialize.model";
import { OrderItemModel } from "./order.item.model";
import { PaymentModel } from "./payment.model";

export class OrderModel implements Deserializable  {
    OrderId: string = '';
    OrderNumber: string = '';
    LocationId: string = '';
    LocationName: string = '';
    OrderReference: string = '';
    JsonData: string = '';
    OrderStatusId: number = 0;
    OrderStatus: string = '';
    OrderTotal: number = 0;
    OrderSubTotal: number = 0;
    OrderDiscount: number = 0;
    OrderOutstanding: number = 0;
    DeliveryTotal: number = 0;
    DateCreated: string = '';
    DateModified: string = '';
    
    PaymentTypeId: number = 0;
    PaymentTotal: number = 0;
    PaymentType: string = '';

    PaymentOpen: boolean = false;
    OrderCancelled: boolean = false;
    OrderReturned: boolean = false;
    OrderDelivered: boolean = false;
    OrderBeingDelivered: boolean = false;
    OrderRequiresDelivery: boolean = false;
    OrderHasServices: boolean = false;

    Name: string = '';
    Notes: string = '';

    OrderItems: OrderItemModel[] = [];
    DeliveryAddress: CustomerAddressModel = null;
    // BusinessDetails: CustomerBusinessDetailModel = null;
    ContactDetails: CustomerContactDetailModel = null;
    Payments: PaymentModel[] = [];
  
    deserialize(input: any) {
        var self = this;

        self.OrderId = input.orderId || '';
        self.OrderNumber = input.orderNumber || '';
        self.OrderReference = input.orderReference || '';
        self.LocationId = input.locationId || '';
        self.LocationName = input.locationName || '';
        self.JsonData = input.jsonData || '';
        self.OrderStatusId = input.orderStatusId || 0;
        self.OrderStatus = input.orderStatus || '';
        self.OrderTotal = input.orderTotal || 0;
        self.OrderSubTotal = input.orderSubTotal || 0;
        self.OrderDiscount = input.orderDiscount || 0;
        self.OrderOutstanding = input.orderOutstanding || 0;
        self.DeliveryTotal = input.deliveryTotal || 0;
        self.DateCreated = input.dateCreated || '';
        self.DateModified = input.dateModified || '';
        self.PaymentTypeId = input.paymentTypeId || 0;
        self.PaymentTotal = input.orderPaymentTotal || 0;
        self.PaymentType = input.paymentType || '';
        
        self.Name = input.name || '';
        self.Notes = input.notes || '';

        self.PaymentOpen = input.paymentOpen || false;
        self.OrderCancelled = input.orderCancelled || false;
        self.OrderReturned = input.orderReturned || false;
        self.OrderDelivered = input.orderDelivered || false;
        self.OrderBeingDelivered = input.orderBeingDelivered || false;
        self.OrderRequiresDelivery = input.orderRequiresDelivery || false;
        self.OrderHasServices = input.orderHasServices || false;

        if(input.orderItems != null) {
            input.orderItems.forEach((item: any) => {
                self.OrderItems.push(new OrderItemModel().deserialize(item));
            });
        }

        if(input.orderPayments != null) {
            input.orderPayments.forEach((orderPayment: any) => {
                self.Payments.push(new PaymentModel().deserialize(orderPayment));
            });
        }

        if(input.deliveryAddress != null) self.DeliveryAddress = new CustomerAddressModel().deserialize(input.deliveryAddress);
        // if(input.customerBusinessDetail != null) self.BusinessDetails = new CustomerBusinessDetailModel().deserialize(input.customerBusinessDetail);
        if(input.customerContactDetail != null) self.ContactDetails = new CustomerContactDetailModel().deserialize(input.customerContactDetail);

        return self;
    }
}