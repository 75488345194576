import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssignUserPage } from 'src/app/components/assign-user/assign-user.page';
import { ScheduleEditPage } from 'src/app/components/schedule-edit/schedule-edit.page';
import { ScheduleModel } from 'src/app/models/schedule/schedule.model';
import { VendorScheduleProfileLinkModel } from 'src/app/models/schedule/schedule.profile.link.model';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ScheduleService } from 'src/app/services/schedule.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.page.html',
  styleUrls: ['./schedule.page.scss'],
})
export class SchedulePage implements OnInit {
  loading: boolean = false;
  displayView: string = 'details';
  inputScheduleId: string = '';
  inputLocationId: string = '';
  showLinkRemove: boolean = false;
  showAddMe: boolean = false;
  selectedLink: any;

  constructor(public modalController: ModalController, public scheduleService: ScheduleService, public locationService: LocationService,
    public notificationService: NotificationService, public authService: AuthService) { }

  ngOnInit() {
    var self = this;

    if(self.inputScheduleId != '') {
      self.loading = true;
      self.scheduleService.get(self.inputScheduleId).subscribe((resp: any) => {
        if(resp != null && resp.outcomeData != null) {
          self.scheduleService.selectedSchedule = new ScheduleModel().deserialize(resp.outcomeData);
        }

        self.loading = false;
      });
    }
  }

  showRemoveLink(link: any) {
    var self = this;

    self.selectedLink = link;

    self.hideShowLinkRemove(true);
  }

  hideShowLinkRemove(showLinkRemove: boolean = false) {
    var self = this;

    self.showLinkRemove = !showLinkRemove;
    self.showLinkRemove = showLinkRemove;
  }

  removeLink() {
    var self = this;

    var model = {
      profileScheduleLinkId: self.selectedLink.ProfileScheduleLinkId,
      profileId: self.selectedLink.ProfileId,
      delete: true,
      vendorScheduleId: self.scheduleService.selectedSchedule.VendorScheduleId
    };

    self.scheduleService.assignEmployee(model).subscribe((resp: any) => {
      if(resp != null && resp.outcome == true) {
        self.notificationService.showToastrError('Done', 'User removed.');
        var index = -1;

        for(var count = 0; count < self.scheduleService.selectedSchedule.ScheduleProflieLinks.length; count++) {
          if(self.scheduleService.selectedSchedule.ScheduleProflieLinks[count].ProfileId == self.selectedLink.ProfileId) {
            index = count;
            count = self.scheduleService.selectedSchedule.ScheduleProflieLinks.length;
          }
        }

        if(index > -1) {
          self.scheduleService.selectedSchedule.ScheduleProflieLinks.splice(index, 1);
        }
        self.hideShowLinkRemove();
      }
      else {
        self.notificationService.showToastrError('Oops', resp.outcomeMessage);
      }
    });
  }

  async showEditSchedulePop() {
    var self = this;

    const modal = await self.modalController.create({
      component: ScheduleEditPage,
      componentProps: {
        inputLocationId: self.inputLocationId,
        inputSchedule: self.scheduleService.selectedSchedule
      }
    });
    return await modal.present();
  }

  async showAddInstructorPop() {
    var self = this;

    const modal = await self.modalController.create({
      component: AssignUserPage,
      componentProps: {
        inputLocationId: self.inputLocationId,
        inputSchedule: self.scheduleService.selectedSchedule
      }
    });
    return await modal.present();
  }

  hideShowAddMe(showAddMe: boolean = false) {
    var self = this;

    self.showAddMe = !showAddMe;
    self.showAddMe = showAddMe;
  }

  addMe() {
    var self = this;

    var model = {
      profileId: self.authService.profile.Id,
      vendorScheduleId: self.scheduleService.selectedSchedule.VendorScheduleId
    };

    self.scheduleService.addMe(model).subscribe((resp: any) => {
      if(resp != null && resp.outcomeData != null) {
        self.notificationService.showToastrError('Done', 'You were added to this schedule.');
        self.scheduleService.selectedSchedule.ScheduleProflieLinks.push(new VendorScheduleProfileLinkModel().deserialize(resp.outcomeData));
        self.hideShowAddMe();
      }
      else {
        self.notificationService.showToastrError('Oops', resp.outcomeMessage);
      }
    });
  }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
