import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TokenInterceptor } from './helpers/http.interceptor';
import { CoreService } from './services/core.service';
import { CustomerService } from './services/customer.service';
import { LocationService } from './services/location.service';
import { MediaService } from './services/media.service';
import { NotificationService } from './services/notification.service';
import { OrderService } from './services/order.service';
import { PaymentService } from './services/payment.service';
import { ScheduleService } from './services/schedule.service';
import { SessionService } from './services/session.service';
import { UserService } from './services/user.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, CoreModule, SharedModule],
  exports: [],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, CoreService, NotificationService, SessionService, LocationService,
    OrderService, PaymentService, CustomerService, UserService, ScheduleService, MediaService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },],
  bootstrap: [AppComponent],
})
export class AppModule {}
