import { Deserializable } from '../deserialize.model';
import { LocationMenuItemModel } from '../locations/location.menu.item.model';
import { LocationMenuItemPhotoModel } from '../locations/location.menu.item.photo.model';

export class DirectoryModel implements Deserializable  {
    DirectoryId: string = '';
    LocationId: string = '';
    LocationMenuItemId: string = '';
    Name: string = '';
    LocationName: string = '';
    LocationMenuItemName: string = '';
    Description: string = '';
    Icon: string = 'map-marker-alt';
    CoverPicture: string = '';

    LocationDirectoryTypeId: number = 0;
    Longitude: number = 0;
    Latitude: number = 0;

    Tags: any[] = [];

    Hidden: boolean = false;
    Favorite: boolean = false;
    Loading: boolean = false;
    HasAccess: boolean = false;

    Discount: number = 0;
    DiscountAmount: number = 0;
    FinalPrice: number = 0;

    UnitPrice: number = 0;
    Price: number = 0;
    Priority: number = 0;
    Pictures: any[] = [];
    Quantity: number = 0;
    SpecialType: number = 0;
    SpecialAmount: number = 0;

    Photos: LocationMenuItemPhotoModel[] = [];
  
    deserialize(input: any) {
        var self = this;

        if(input == null) return self;

        self.DirectoryId = input.directoryId || '';
        self.LocationId = input.locationId;
        self.LocationMenuItemId = input.locationMenuItemId || '';
        self.Name = input.name;
        self.LocationName = input.locationName;
        self.LocationMenuItemName = input.locationMenuItemName;
        self.Description = input.description;
        // self.Description = 'Hawaiian pizza, also known as pineapple pizza, is a pizza topped with pineapple, ham, tomato sauce and cheese.';
        self.Longitude = input.longitude;
        self.Latitude = input.latitude;
        self.CoverPicture = input.coverPicture;
        self.HasAccess = input.hasAccess;
        self.Favorite = input.favorite;
        self.LocationDirectoryTypeId = input.locationDirectoryTypeId;
        self.UnitPrice = input.unitPrice;
        self.Price = input.price;
        self.Priority = input.priority;
        self.Quantity = input.quantity || 0;
        self.Hidden = input.hidden || false;
        self.SpecialAmount = input.specialAmount || 0;
        self.SpecialType = input.specialType || 1;
        
        if(input.icon != null && input.icon != '') self.Icon = input.icon;
        if(input.tags != null && input.tags != '') self.Tags = input.tags.split(' ');

        if(input.menuItemPhotos != null && input.menuItemPhotos.length > 0) {
            input.menuItemPhotos.forEach((photo:any) => {
                self.Photos.push(new LocationMenuItemPhotoModel().deserialize(photo));
            });
        }

        // if(input.jsonData != null && input.jsonData != '') {
        //     var extra = JSON.parse(input.jsonData);

        //     if(extra != null) {
        //         self.Discount = extra.discount || 0;
        //         self.DiscountAmount = extra.discountAmount || 0;
        //         self.FinalPrice = extra.finalPrice || 0;

        //         if(self.Discount > 0) {
        //             self.Price = self.UnitPrice * ((100 - self.Discount) / 100);
        //             self.DirectoryType = 1;
        //         }
        //         else if(self.DiscountAmount > 0) {
        //             self.Price = self.UnitPrice - self.DiscountAmount;
        //             self.DirectoryType = 2;
        //         }
        //         else if(self.FinalPrice > 0) {
        //             self.Price = self.FinalPrice;
        //             self.DirectoryType = 3;
        //         }
        //     }
        // }
        
        return self;
    }

    deserializeMenuItem(input: any) {
        var self = this;

        self.DirectoryId = input.directoryId;
        self.LocationId = input.locationId;
        self.LocationMenuItemId = input.locationMenuItemId || '';
        self.Name = input.name;
        self.LocationName = input.locationName;
        self.LocationMenuItemName = input.locationMenuItemName;
        self.Description = input.description;
        // self.Description = 'Hawaiian pizza, also known as pineapple pizza, is a pizza topped with pineapple, ham, tomato sauce and cheese.';
        self.Longitude = input.longitude;
        self.Latitude = input.latitude;
        self.CoverPicture = input.coverPicture;
        self.HasAccess = input.hasAccess;
        self.Favorite = input.favorite;
        self.LocationDirectoryTypeId = input.locationDirectoryTypeId;
        self.UnitPrice = input.unitPrice;
        self.Price = input.price;
        self.Priority = input.priority;
        self.Quantity = input.quantity || 0;
        self.Hidden = input.hidden || false;
        self.SpecialAmount = input.specialAmount || 0;
        self.SpecialType = input.specialType || 1;
        
        if(input.icon != null && input.icon != '') self.Icon = input.icon;
        if(input.tags != null && input.tags != '') self.Tags = input.tags.split(' ');

        if(input.menuItemPhotos != null && input.menuItemPhotos.length > 0) {
            input.menuItemPhotos.forEach((photo:any) => {
                self.Photos.push(new LocationMenuItemPhotoModel().deserialize(photo));
            });
        }
        
        return self;
    }

    deserializeItem(input: LocationMenuItemModel) {
        var self = this;

        self.DirectoryId = input.LocationDirectoryId;
        // self.LocationId = input.locationId;
        self.LocationMenuItemId = input.LocationMenuItemId || '';
        self.Name = input.Name;
        // self.LocationName = input.locationName;
        self.LocationMenuItemName = input.Name;
        self.Description = input.Description;
        self.Longitude = input.Longitude;
        self.Latitude = input.Latitude;
        self.CoverPicture = input.CoverPicture;
        // self.HasAccess = input.hasAccess;
        // self.Favorite = input.favorite;
        self.LocationDirectoryTypeId = input.LocationDirectoryTypeId;
        self.UnitPrice = input.UnitPrice;
        self.Price = input.Price;
        self.Priority = input.Priority;
        self.Quantity = input.Quantity || 0;
        self.Hidden = input.Hidden || false;
        self.SpecialAmount = input.SpecialAmount || 0;
        self.SpecialType = input.SpecialType || 1;
        
        self.Tags = input.Tags;

        return self;
    }

    update(input: any) {
        var self = this;

        self.Name = input.name;
        self.Description = input.description;
        self.Longitude = input.longitude;
        self.Latitude = input.latitude;
        self.Priority = input.priority;
        self.Price = input.price;
        
        if(input.icon != null && input.icon != '') self.Icon = input.icon;
        if(input.tags != null && input.tags != '') self.Tags = input.tags.split(' ');

        self.SpecialAmount = input.specialAmount;
        self.SpecialType = input.specialType;

        // if(input.jsonData != null && input.jsonData != '') {
        //     var extra = JSON.parse(input.jsonData);

        //     if(extra != null) {
        //         self.Discount = extra.discount || 0;
        //         self.Price = extra.price || 0;
        //     }
        // }
        
        return self;
    }
}