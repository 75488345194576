import { LocationAddressModel } from './location.address.model';
import { LocationContactDetailModel } from './location.contact.detail.model';
import { Deserializable } from '../deserialize.model';
import { LocationWorkingHourModel } from './location.working.hour.model';
import { LocationMenuModel } from './location.menu.model';
import { LocationPhotoModel } from './location.photo.model';
import { DirectoryModel } from '../directory/directory.model';
import { LocationMenuItemModel } from './location.menu.item.model';
import { LocationScheduleModel } from './location.schedule.model';

export class LocationModel implements Deserializable  {
    LocationId: string = '';
    Name: string = '';
    Description: string = '';
    Longitude: number = 0;
    Latitude: number = 0;
    Icon: string = 'map-marker-alt';
    CoverPicture: string = '';
    TagString: string = '';

    ContactDetails: any[] = [];
    WorkingHours: any[] = [];
    Addresses: any[] = [];
    Tags: any[] = [];
    Gallery: any[] = [];
    LocationMenu: any[] = [];
    LocationDirectory: any[] = [];
    FeaturedItems: any[] = [];
    ScheduleItems: any[] = [];

    Hidden: boolean = false;
    Favorite: boolean = false;
    Loading: boolean = false;
    Delete: boolean = false;
    HasAccess: boolean = false;
    CreateDirectory: boolean = false;
    Verified: boolean = false;
    Published: boolean = false;
    Approved: boolean = false;
  
    deserialize(input: any) {
        var self = this;

        self.LocationId = input.locationId;
        self.Name = input.name;
        self.Description = input.description;
        self.Longitude = input.longitude;
        self.Latitude = input.latitude;
        self.CoverPicture = input.coverPicture;
        self.HasAccess = input.hasAccess;
        self.Favorite = input.favorite;
        self.CreateDirectory = input.createDirectory;
        self.Verified = input.verified;
        self.Published = input.published;
        self.Approved = input.approved;
        self.TagString = input.tags || '';
        
        if(input.icon != null && input.icon != '') self.Icon = input.icon;
        if(input.tags != null && input.tags != '') self.Tags = input.tags.split(' ');

        if(input.contactDetails != null && input.contactDetails != '' && input.contactDetails.length > 0) {
            input.contactDetails.forEach((detail: any) => {
                self.ContactDetails.push(new LocationContactDetailModel().deserialize(detail));
            });
        }

        if(input.workingHours != null && input.workingHours != '' && input.workingHours.length > 0) {
            input.workingHours.forEach((detail: any) => {
                self.WorkingHours.push(new LocationWorkingHourModel().deserialize(detail));
            });
        }

        if(input.addresses != null && input.addresses != '' && input.addresses.length > 0) {
            input.addresses.forEach((detail: any) => {
                self.Addresses.push(new LocationAddressModel().deserialize(detail));
            });
        }

        if(input.locationMenu != null && input.locationMenu != '' && input.locationMenu.length > 0) {
            input.locationMenu.forEach((menu: any) => {
                self.LocationMenu.push(new LocationMenuModel().deserialize(menu));
            });
        }

        if(input.gallery != null && input.gallery != '' && input.gallery.length > 0) {
            input.gallery.forEach((photo: any) => {
                self.Gallery.push(new LocationPhotoModel().deserialize(photo));
            });
        }

        if(input.locationDirectory != null && input.locationDirectory != '' && input.locationDirectory.length > 0) {
            input.locationDirectory.forEach((photo: any) => {
                self.LocationDirectory.push(new DirectoryModel().deserialize(photo));
            });
        }

        if(input.featuredProducts != null && input.featuredProducts.length > 0) {
            input.featuredProducts.forEach((item: any) => {
                self.FeaturedItems.push(new LocationMenuItemModel().deserialize(item));
            });
        }

        if(input.schedule != null && input.schedule.length > 0) {
            input.schedule.forEach((item: any) => {
                self.ScheduleItems.push(new LocationScheduleModel().deserialize(item));
            });
        }
        
        return self;
    }

    update(input: any) {
        var self = this;

        if(self.LocationId == null || self.LocationId == '') self.LocationId = input.locationId;
        self.Name = input.name;
        self.Description = input.description;
        self.Longitude = input.longitude;
        self.Latitude = input.latitude;
        self.CoverPicture = input.coverPicture;
        self.HasAccess = input.hasAccess;
        
        self.Icon = '';
        if(input.icon != null && input.icon != '') self.Icon = input.icon;

        self.Tags = [];
        if(input.tags != null && input.tags != '') self.Tags = input.tags.split(' ');
    }

    toSaveModel() {
        var self = this;
        return {
            LocationId: self.LocationId,
            Name: self.Name,
            Description: self.Description,
            Tags: self.Tags.join(' '),
            Longitude: self.Longitude,
            Latitude: self.Latitude,
            Icon: self.Icon,
            CoverPicture: self.CoverPicture,
            Verified: self.Verified,
            Published: self.Published,
            Approved: self.Approved
        };
    }

    toListModel() {
        var self = this;
        return {
            LocationId: self.LocationId,
            Name: self.Name,
            Description: self.Description,
            Tags: self.TagString,
            Longitude: self.Longitude,
            Latitude: self.Latitude,
            Icon: self.Icon,
            CoverPicture: self.CoverPicture,
            Verified: self.Verified,
            Published: self.Published,
            Approved: self.Approved
        };
    }
}